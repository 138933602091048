import * as type from '../actions/types';

const initialState = {
    loading: false,
    error: null,
    note: {}
}

export default function updateEmailOtp(state = initialState, action) {
    switch (action.type) {
        case type.POST_UPDATE_EMAIL_OTP_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.POST_UPDATE_EMAIL_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                note: action.data
            }
        case type.POST_UPDATE_EMAIL_OTP_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}