import React from 'react';
import ProPlusCheckout from "../proPlusPayment/proplusCheckout"
const ProPlusCard = (props) => {
    return (
        <div className="ebw-entry__pro-card">
            <div className="ebw-entry__pro-payment">
                <h3 className="ebw-title__secondary mb-3">Payment information</h3>
                <div className="ebw-entry__pro-form d-flex flex-wrap">
                    <ProPlusCheckout provider_id={props.provider_id} user_id={props.user_id} plan ={props?.plan} />
                </div>
            </div>

            <div className="ebw-entry__pro-info text-xs d-flex align-align-items-center justify-content-center pb-1">
                <span className="ebw-icon ebw-icon--md icon-lock"></span>
                <span className="mt-3">Card information is stored on a secure server</span>
            </div>
        </div>
    )
}
export default ProPlusCard;