import React, { useState } from 'react';
import { Form, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from "../inputs/text-input";
import { Checkbox } from "../inputs/checkbox";
import Cookies from 'js-cookie';
import Buttons from "../buttons/button-primary";
import OtpInputNumber from "../inputs/otp-input";
import InputNumber from "../inputs/number-input";
import validateMobile from "./registerEditMobileNumberValidation";
import { registerResendOtp } from "../../redux/actions/registrationResendOtp";
import { registerEditMobile } from "../../redux/actions/registrationEditMobileNumber";
import axios from 'axios';
import API from '../../API';
// registerPersonal
const LoginOTP = (props) => {
    const [isOpen, openModal] = useState(false);
    const dispatch = useDispatch();
    const personal = useSelector(state => state.registerOtp.registration);
    const personalLoading = useSelector(state => state.registerOtp.loading);
    const personalError = useSelector(state => state.registerOtp.error);
    const registerPersonal = useSelector(state => state.registerPersonal.registration);
    const toggleModal = () => openModal(!isOpen);
    const [state, setState] = useState({
        mobileNumber: Cookies.get('mobileNumber'),
        otp: "",
        otpError: "",
        mobileNumberError: "",
        changedMobileNumber: "",
        changedMobileNumberError: "",
    })
    const handleChange = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            otp: data,
        }))
    }
    const handleResendOtp = (confirmation_token) => {
        dispatch(registerResendOtp(confirmation_token))
    };
    const numberUpdate = (event) => {
        event.preventDefault()
        const errors = validateMobile(state)
        const data = {
            user_id: registerPersonal.data.user_id,
            mobile_number: state.changedMobileNumber
        }
        if(!errors.valid){
            setState((prevProps) => ({
                ...prevProps,
                changedMobileNumberError: errors.changedMobileNumberError,
            }));
        }
        errors.valid && axios.put(API.baseURL + "/providers/update_mobile_number_without_login ", data, API.options).then(response => response.data)
            .then((result) => {
                setState((prevProps) => ({
                    ...prevProps,
                    mobileNumber: state.changedMobileNumber,
                }));
            }).catch(error => {
                setState((prevProps) => ({
                    ...prevProps,
                    changedMobileNumberError: error.response.data.data.errors,
                }));
            });
        errors.valid && toggleModal();
    }
    const handleMobileChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            changedMobileNumber: event.target.value,
            changedMobileNumberError: "",
        }));
    }
    return (
        <>
            <Row form>
                <Col sm="12">
                    <div className="position-relative mt-3">
                        <InputText
                            disabled={true}
                            label="Mobile Number"
                            value={state.mobileNumber}
                            name="login_number"
                            id="login_number"
                        />
                        <div className="ebw-input__option-edit position-absolute" onClick={() => toggleModal()}>Edit</div>
                    </div>

                    <div className="d-flex flex-row justify-content-between align-items-center  ml-2 ml-md-4 mr-2">
                        
                        <div onClick={() => handleResendOtp(registerPersonal.data.confirmation_token)} className="ebw-entry__tab-forgot ml-auto">
                            <a className="mb-0 text-sm ebw-entry__tab-forgot--link" role="button" href="#">Resend OTP</a>
                        </div>
                    </div>
                </Col>
            </Row>
            <Form>
                <Row form>
                    <Col sm="12">
                        <OtpInputNumber
                            value={state.otp}
                            changeEvent={handleChange}
                            otpError={state.otpError}
                        />
                        {personalError ? <div className="ebw-error mt-2">{personalError}</div> : ""}
                    </Col>

                    <Col md="3" lg="5" xl="3" className="mt-5 mx-auto">
                        <Buttons clickEvent={() => props.handeleOtpVerification(state.otp, registerPersonal.data.confirmation_token)} type="button" text={props.buttonName} />
                    </Col>
                </Row>
            </Form>
            <div className="ebw-profile__modal">
                <div className="px-5 py-5">
                    <Modal isOpen={isOpen}>
                        <div className="px-5 py-5 ebw-modal__wrap">
                            <ModalHeader toggle={toggleModal} className="ebw-profile__modal-head mb-4" style={{ border: "none" }}>
                                <div className="ebw-modal__title mb-1">Edit mobile number </div>
                                <div className="ebw-modal__para">We will send you an access code via mobile for verification.</div>
                            </ModalHeader>

                            <ModalBody className="ebw-modal__body">
                                <form className="ebw-modal__form">
                                    <div className="ebw-modal__input-wrap mb-2">
                                        <InputNumber
                                            changeEvent={(e) => handleMobileChange(e)}
                                            value={state.changedMobileNumber}
                                            className="ebw-modal__input" type="number"
                                            placeholder="Enter new mobile number"
                                            error={state.changedMobileNumberError}
                                            required
                                        />
                                    </div>
                                    <div className="text-right">
                                        <button
                                            onClick={(e) => numberUpdate(e)}
                                            className="ebw-modal__submit ebw-button__primary"
                                            type="submit">Verify</button>
                                    </div>
                                </form>
                            </ModalBody>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default LoginOTP;