const validate = (inputs) => {
    let errors = {
        valid: true,
    };
    if (!inputs.company_name) {
        errors.companyNameError = 'Enter Company Name'
        errors.valid = false;
    }
    // company type error message
    if (inputs.companyType == "0") {
        errors.companyTypeError = 'Select Company Type'
        errors.valid = false;
    }
    //street address
    if (inputs.street_address === "") {
        errors.street_addressError = 'Enter Business Address'
        errors.valid = false;
    }
    // zipCode erro
    if (inputs.zipcode === "") {
        errors.zipcodeError = 'Enter Zip Code'
        errors.valid = false;
    }
    //business address erro
    if (inputs.businessAddress === "") {
        errors.businessAddressError = 'Enter Business Address'
        errors.valid = false;
    }
    //company Served cities mile radius errors
    // if (!inputs.cityRadius || inputs.cityRadius === []) {
    //     errors.cityRadiusError = 'Enter Mile Radius'
    //     errors.valid = false;
    // }
    // if (zipcodess.length===0)
    //     { this.setState({radiusError:'Please add atleast one zipcode served'})}
    //company Served cities mile radius errors
    if (inputs.years_of_business === "") {
        errors.years_of_businessError = 'Enter Years of Business'
        errors.valid = false;
    }
    if (inputs.project_sub_type_ids.length === 0) {
        errors.serviceError = "Select services"
        errors.valid = false;
    }

    return errors;

}
export default validate;