import React from 'react';
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
const searchSelect = (props) => {
    return (
        <FormGroup className="position-relative">
            <Input
                type="text"
                name={props.name}
                id={props.id}
                value={props.value}
                placeholder={props.label}
                className="ebw-form__input ebw-form__input-select position-relative"
                onChange={(e) => props.changeEvent(e.target.value)}
                defaultValue={props.value}
                style={{height:53}}
            />
        </FormGroup>
    );
}
export default searchSelect




//- Search Input for Filter Dropdown
export const CategorySearch = (props) => {
    return (
        <InputGroup className="ebw-tab__filter-group mb-0">
            <Input
                type="search"
                name={props.name}
                id={props.id}
                value={props.value}
                placeholder={props.label}
                className="ebw-form__input ebw-form__input-select ebw-form__input-select--category"
                defaultValue={props.value}
                onChange={props.changeHandler}
            />
            <InputGroupAddon addonType="append" className="ebw-tab__filter-groupaddon">
                <InputGroupText className="ebw-tab__filter-grouptext p-0 bg-transparent">
                    <Button color="link" type="button" className="bg-transparent ebw-tab__filter-search">
                        <span className="ebw-icon ebw-icon--lg icon-search ebw-tab__filter-icon"></span>
                    </Button>
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    )
}
