import React from 'react';
import { Link } from 'react-router-dom';

const ProjectNotFoundPage = () => {
    return (
        <div className="ebw-page__error ebw-page__error-404 d-flex flex-column align-items-center justify-content-center">
            <div className="ebw-page__error-wrap ">
                <div className="mb-2 position-relative">
                    <img src="/images/page-error/404-project.svg" alt="Project not found" />
                </div>
                <p className="ebw-page__error-title text-center position-relative">Project you are looking for is temporarily <br /> unavailable</p>
                <p>
                    <Link className="ebw-button ebw-button__primary position-relative" to="/projects/all">View available Projects</Link>
                </p>
            </div>
        </div>
    );
}

export default ProjectNotFoundPage;