import React, { useState, useEffect } from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Player } from 'video-react';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';
import SwiperCore, { Navigation, Thumbs } from 'swiper/core';
import options from '../../values/imageViewerOptions';
import 'swiper/swiper.scss';
import LighrBoxCmn from "../common/lightBox";

// Swiper's Thumbs component
SwiperCore.use([Navigation, Thumbs]);


const ProjectsSlider = (props) => {
    // const { files, videos } = props;
    let files = [...props.videos]
    let allImages = [];
    props.files && props.files.forEach(file => {
        files.push(file);
        allImages.push(file.url);
    })


    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const videoFormats = ["mp4", "wmv", "avi", "flv", "mkv", "mov",]
    const [mainImg, setMainImg] = useState(files && files.length != 0 ? videoFormats.includes(files[0].url.split('.').pop()) ? files[0].url : files[0].thumb_url : "/images/projects/project-details.png");
    const [selectedImage, setSelectedImage] = useState(0);
    const selectImg = function (e, i) {
        setMainImg(e.url);
        // setSelectedImage(i - props.videos.length);
    }
    const closeViewer = () => {
        setIsopen(false);
    }
    const [videoPlayerOn, setVideoPlayerOn] = useState(false);
    const [currentVideo, setCurrentVideo] = useState("");
    const [isOpen, setIsopen] = useState(false)
    const getImage = (data) => {
        return data
        // const extension = data.split('.').pop();
        // if (videoFormats.includes(extension)) {
        //     return "/images/video-thumbnail.png"
        // } else {
        //     return data
        // }
        // return;
    }
    const definePlayer = (data) => {
        // const extension = data.split('.').pop();
        // if (videoFormats.includes(extension)) {
        //     setVideoPlayerOn(true)
        //     setCurrentVideo(data);
        // } else {
        //     setIsopen(true);
        // }
        // return;
    }
    const toggleModal = () => {
        setVideoPlayerOn(!videoPlayerOn);
    }
    useEffect(() => {
        setTimeout(() => {
            setMainImg(files && files.length != 0 ? videoFormats.includes(files[0].url.split('.').pop()) ? files[0].url : files[0].thumb_url : "/images/projects/project-details.png");
        }, 2000);
    }, []);
    return (
        <>
            {/* <SimpleReactLightbox>
                <SRLWrapper options={options}> */}
            < div className=" ebw-project__main-slider--img" >
                {/* {definePlayer(mainImg)} */}
                < img onClick={() => definePlayer(mainImg)} src={files.length != 0 ? getImage(mainImg) : "/images/projects/project-details.png"} alt="" className="img-fluid d-block mx-auto" />
            </div >
            {/* </SRLWrapper>
            </SimpleReactLightbox> */}

            < Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={20}
                slidesPerView='auto'
                freeMode={true}
                watchSlidesVisibility
                watchSlidesProgress
                className="ebw-project__thumb-swiper"
            >

                {files && files.length != 0 && files.map((thumb, index) => (
                    <SwiperSlide key={index} className={`ebw-project__thumb-slider ${mainImg == thumb.url ? "img-active" : ""}`}>
                        <div className="ebw-project__thumb-slider--img">
                            {/* {showTheImage(thumb)} */}
                            {/* Video */}
                            <img onClick={() => selectImg(thumb, index)} src={files.length != 0 ? thumb.thumb_url : "/images/projects/project-details.png"} alt="IMG" className="img-fluid d-block" />
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper >
            {/* <Modal className="ebw-modal__videoplayer-wrap d-flex align-items-center" isOpen={videoPlayerOn} toggle={toggleModal}>
                <div className="ebw-modal__videoplayer-modal">
                    <div className="p-0">
                        <ModalHeader toggle={toggleModal} className="ebw-profile__modal-head pb-0" style={{ border: "none", background: "none", color: "var(${--color-white})" }}></ModalHeader>
                        <ModalBody className="ebw-modal__body p-0">
                            <div className="ebw-modal__videoplayer">
                                <Player>
                                    <source src={currentVideo} />
                                </Player>
                            </div>
                        </ModalBody>
                    </div>
                </div>
            </Modal> */}
            {
                isOpen && allImages.length != 0 &&
                <LighrBoxCmn closeViewer={() => closeViewer()} photoIndex={selectedImage} images={allImages} />
            }
        </>
    );
}
export default ProjectsSlider;