import React from 'react';

const ProjectBudget = ({ budget }) => {
    return (
        <div className="d-flex justify-content-between pt-6">
            <div className="ebw-myproject__completed-amount">PRoject budget<span className="ebw-myproject__completed-cost pl-2"></span>
            </div>
            <div className="ebw-myproject__completed-cost">
                <span className="ebw-myproject__completed-hr pl-1">${budget}</span>
            </div>
        </div>
    );
}

export default ProjectBudget;