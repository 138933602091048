import React, { useState, useEffect } from 'react';
import { Modal, Input, ModalHeader, ModalBody } from 'reactstrap';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from "../common/uploadFile";
import { updateProfileImage } from "../../redux/actions/updateProfileimage";

const UploadProfilePic = (props) => {
    const dispatch = useDispatch();
    const [isOpen, openModal] = useState(false);
    const toggleModal = () => {
        openModal(!isOpen);
    }
    const [error, setError] = useState("");

    const [files, setFile] = useState([]);
    const handleChangeFile = (data) => {
        setFile([...data])
    };
    const removeFileFromList = (i) => {
        let newState = []
        files.length > 0 && files.forEach((item, index) => {
            index !== i && newState.push(item);
        })
        setFile([...newState])
    }
    const uploadProfilePicture = (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append('document', files[0].blob);
        dispatch(updateProfileImage(formData));
        setFile([]);
        openModal(false);
    }

    return (
        <>
            <div onClick={() => openModal(true)} className="ebw-profile__img-change">
                <span className="ebw-icon ebw-icon--lg icon-edit ebw-profile__img-change--icon"></span>
                <span className="ebw-profile__img-change--label ml-1">Change Picture</span>
            </div>

            <Modal isOpen={isOpen} toggle={toggleModal}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                        <div className="ebw-modal__title text-capitalize mb-1">Upload profile picture</div>
                    </ModalHeader>

                    <ModalBody className="ebw-modal__body">
                        <form className="ebw-modal__form">
                            <div className="ebw-modal__input-wrap mb-4">
                                <FileUpload
                                    type="image"
                                    multiple={false}
                                    files={files}
                                    handleChangeFile={handleChangeFile}
                                    removeFileFromList={removeFileFromList}
                                />
                                {/* <label for="uploadDP" className="ebw-my__uploads-file m-0 w-100">
                                    <div className="ebw-my__uploads-inwrap text-center">
                                        <div className="ebw-form__input-file--custom d-flex justify-content-between align-items-center btn btn-secondary">
                                            <span className="ebw-form__input-file--text">Choose File</span>
                                            <span className="ebw-icon ebw-icon--xl icon-arrow-down"></span>
                                        </div>
                                        <p className="ebw-my__uploads-text pt-3">Or drop files here. Max file size 10MB</p> */}
                                {/* {this.state.file ?
                                            <div>
                                                <div style={{ backgroundImage: `url(${this.state.file})` }} className="ebw-my__uploads-img"></div>
                                                <div>{this.state.fileName.name}</div>
                                            </div>
                                            :
                                            ""
                                        } */}
                                {/* </div>
                                </label> */}
                            </div>
                            <div className="text-right">
                                <button className="ebw-modal__submit ebw-button__primary" type="submit" onClick={(e) => uploadProfilePicture(e)}  >Update</button>
                            </div>
                        </form>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default UploadProfilePic;