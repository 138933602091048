import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

const apiUrl = API.baseURL + `/web_resend_otp`;
async function postApi(data) {
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* login(action) {
    try {
        const auth = yield call(postApi, action.data);
        // if (1 === 1) {
        toastr.success('Success', 'OTP sent to your email and mobile successfully');
        // } else {
        //     toastr.error('Sorry', 'We are unable to send OTP 1');
        // }
        // history.push('/');
        yield put({ type: 'POST_OTP_LOGIN_RESEND_OTP_SUCCESS', auth: auth });
    } catch (e) {
        // toastr.error('Sorry', 'We are unable to resend OTP 2');
        yield put({ type: 'POST_OTP_LOGIN_RESEND_OTP_FAILED', message: e.response.data.data.errors[0] });
    }
}

function* loginOtpResendSaga() {
    yield takeEvery('POST_OTP_LOGIN_RESEND_OTP_REQUESTED', login);
}

export default loginOtpResendSaga;