import React, { useState } from 'react';
import { Form, Row, Col } from "reactstrap";
import axios from 'axios';
import InputPassword from "../inputs/password-input";
import { Checkbox } from "../inputs/checkbox";
import Buttons from "../buttons/button-primary";
import API from '../../API';

//- Reset Password/New Password
const ResetPassword = (props) => {
    const validate = (inputs) => {
        let errors = {
            valid: true,
        };
        //Password Errors
        if (inputs.password.length < 6) {
            errors.passwordError = 'Check Password'
            errors.valid = false;
        }
        if (!inputs.password) {
            errors.passwordError = 'Enter Password'
            errors.valid = false;
        }
        //Confirm Password Errors
        if (inputs.passwordRe !== inputs.password) {
            errors.passwordReError = 'Enter Same Password'
            errors.valid = false;
        }
        if (inputs.passwordRe.length < 6) {
            errors.passwordReError = 'Check Password'
            errors.valid = false;
        }
        if (!inputs.passwordRe) {
            errors.passwordReError = 'Enter Password'
            errors.valid = false;
        }
        return errors;
    }
    const [state, setState] = useState({
        password: "",
        passwordRe: "",

        successNote: "",

        passwordError: "",
        passwordReError: ""
    });
    const handlePasswordChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            password: event.target.value,
            passwordError: "",
            passwordReError: ""
        }));
    }
    const handleConfirmPasswordChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            passwordRe: event.target.value,
            passwordError: "",
            passwordReError: ""
        }));
    }
    const handleConfirmPasswordSubmit = (event) => {
        event.preventDefault();
        const error = validate(state)
        setState((prevProps) => ({
            ...prevProps,
            ...error
        }));
        let data = JSON.stringify({
            password: state.password,
            email: props.email,
        })

        error.valid && axios.post(API.baseURL + `/update_user_password`, data, API.options)
            .then((response) => {
                const result = response.data;
                props.completedForgotPassword(3, props.email)
                window.location.href = '/login';
            }).catch(error => {
                // this.setState({overAllErrors: error.response.data.errors.full_messages,loadingShow: false});

            });
    }

    return (
        <Form onSubmit={(e) => handleConfirmPasswordSubmit(e)} className="ebw-entry__tab-form text-center">
            <div className="ebw-entry__forgot mb-4">
                <h5 className="ebw-entry__forgot-title mb-0">Forgot your Password?</h5>
                <p className="ebw-entry__forgot-tag">Recover it now</p>
            </div>

            <Row form>
                <Col sm="12">
                    <InputPassword
                        label="New Password"
                        name="new_pass"
                        id="new_pass"
                        value={state.password}
                        changeEvent={handlePasswordChange}
                        error={state.passwordError}
                    />
                </Col>

                <Col sm="12">
                    <InputPassword
                        label="Confirm Password"
                        name="confirm_pass"
                        id="confirm_pass"
                        value={state.passwordRe}
                        changeEvent={handleConfirmPasswordChange}
                        error={state.passwordReError}
                    />
                </Col>

                <Col sm="12">
                    <div className="d-flex flex-row justify-content-between align-items-center ml-3 mr-2 mt-2">
                        <div className="ebw-entry__tab-checkbox">
                            {/* <Checkbox text="Remember me" /> */}
                        </div>
                        <div className="ebw-entry__tab-forgot ml-auto">
                            <a className="mb-0 text-sm ebw-entry__tab-forgot--link" role="button" href="#">Forgot Password?</a>
                        </div>
                    </div>
                </Col>

                <Col md="4" lg="5" xl="4" className="mt-6 mt-md-10 mx-auto">
                    <Buttons text="Submit" />
                </Col>
            </Row>
        </Form>
    );
}
export default ResetPassword;


