import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Container
} from 'reactstrap';
import Main from '../../layout/main';
import { Helmet } from "react-helmet";
import CardSecondary from '../../components/card/card-secondary';
import "react-datepicker/dist/react-datepicker.css";
import ProjectsLoader from "../../components/projects/projectsSkeleton";
const Buyprojects = (props) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
    const { buyPtojectsData, buyPtojectsLoading } = props;
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content=" Easy Bids Pro is the easiest way to buy the projects in your area. Whether it's plumbing, carpentry, landscaping or anything else you need done, find an easy project near you and get started today!" />
                <title>Purchased Lead - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="buy-projects">
                <section className="ebw-section ebw-section__pages">
                    <Container>
                            <div className="d-md-flex justify-content-between">
                                <div className="ebw-title__primary">Purchased Leads</div>
                            </div>
                            {buyPtojectsLoading ?
                                <div className="ebw-data__loaderr mt-5">
                                    <ProjectsLoader loading={buyPtojectsLoading} />
                                </div>
                                : 
                                <>
                                    {!buyPtojectsData?.projects &&
                                        <Row>
                                            <Col sm="12 mt-5" >
                                                <div className="ebw-section__empty position-relative">
                                                    <div className="ebw-section__empty-imgs w-100">
                                                        <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                    </div>
                                                    <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                        <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Purchase leads from our exclusive project roster to grow your business.</div>
                                                        <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {buyPtojectsData?.projects?.length === 0 &&
                                        <Row>
                                            <Col sm="12 mt-5" >
                                                <div className="ebw-page__error-project ebw-page__error-404 d-flex flex-column align-items-center justify-content-center">
                                                    <div className="ebw-page__error-wrap ">
                                                        <div className="mb-4 position-relative">
                                                            <img className="img-fluid" src="/images/projects/search-not-found.svg" alt="No results found" />
                                                        </div>
                                                        <p className="ebw-page__error-title text-center position-relative">No results found</p>
                                                        <p>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        {buyPtojectsData?.projects?.length != 0 && buyPtojectsData?.projects?.map((projectAvailableProjects) => {
                                            return (
                                                    <Col key={projectAvailableProjects.id} sm="4 mt-4">
                                                        <CardSecondary
                                                            projectName={projectAvailableProjects.name}
                                                            projectImage={projectAvailableProjects.project_image_url}
                                                            projectDate={projectAvailableProjects.created_at}
                                                            status={projectAvailableProjects.status}
                                                            projectCategory={projectAvailableProjects.project_type}
                                                            projectLocation={projectAvailableProjects.city}
                                                            projectBudjet={projectAvailableProjects.budget}
                                                            id={projectAvailableProjects.id}
                                                            project_status={projectAvailableProjects.status}
                                                            lead_purchased={projectAvailableProjects.lead_purchased}
                                                            projectLinks={`/project-detail/${projectAvailableProjects.id}/leads`}
                                                            bookmark={projectAvailableProjects.bookmarked_by_current_provider}
                                                            project_id={projectAvailableProjects.id}
                                                        />
                                                    </Col>
                                                )
                                            
                                        }
                                        )}
                                        {/* <div className="w-100 text-right mt-6 mt-md-10">
                                            <PaginationCmn
                                                activePage={activePageAvailable}
                                                itemsPerPage={itemsPerPage}
                                                totalItems={elaspsed_available_projects && elaspsed_available_projects.length}
                                                handlePaginationChange={(data) => setAvailableActivePage(data)}
                                            />
                                        </div> */}
                                    </Row>
                                </>
                            }
                    </Container>
                </section>
            </Main>
        </div>
    );
}
export default Buyprojects;