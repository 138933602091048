import React, { useState } from 'react'
import Main from '../../layout/main'
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, CardImg, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



function BuyProjectDetail() {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropdown = () => setDropdownOpen(prevState => !prevState);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Buy-Project-Details</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="profile">
                <Container>
                    <div className="ebw-prodet mt-10 mb-14">
                        <div className="ebw-prodet__inwrap d-md-flex justify-content-between">
                            <div className="d-md-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <i className="ebw-prodet__angle fa fa-angle-left mr-1" aria-hidden="true"></i>
                                    <div className="ebw-prodet__text">Available Projects</div>
                                </div>
                                <div className="ebw-prodet__title pl-md-6 ebw-title__primary">Roof repairing</div>
                            </div>
                            <div className="ebw-prodet__more d-flex align-items-center">
                                <div className=" d-flex align-items-center pt-2 pr-4 pb-2 pl-4">
                                    <div className="ebw-prodet__more-text d-flex align-items-center">
                                        <span className="pr-2">More</span>
                                        <span className="ebw-icon ebw-icon--lg icon-more-horizontal ebw-prodet__more-icon"></span>
                                    </div>
                                    {/*<div className="ebw-prodet__round d-flex align-items-center">
                                        <span className="ebw-prodet__round-blue"></span>
                                        <span className="ebw-prodet__round-blue"></span>
                                        <span className="ebw-prodet__round-blue"></span>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="ebw-prodet__row pb-12">
                        <Col sm={12} md={4} className="">
                            <div className="ebw-prodet__title">Customer</div>
                            <div className=" d-flex align-items-center ebw-prodet__profile pt-4">
                                <img src="./images/profile/profile.png" className="ebw-prodet__img ebw-header__profile-img"></img>
                                <div className="ebw-prodet__details pl-4">
                                    <div className="ebw-prodet__name">Jessie Prado</div>
                                    <div className="d-flex align-items-center pt-1">
                                        <span className="ebw-icon ebw-icon--lg icon-location"></span>
                                        <div className="ml-1">Lemon grove, California</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4}></Col>
                        <Col sm={12} md={4}>
                            <div className="ebw-prodet__content mt-6">
                                <div className="ebw-prodet__content-wrap">
                                    <div className="d-flex justify-content-between">
                                        <div className="ebw-prodet__name">Roof repairing</div>
                                        <div className="ebw-prodet__date ebw-card__date">01 Feb 2021</div>
                                    </div>
                                    <div className="ebw-prodet__subtitle ebw-card__subtitle">Roofing</div>
                                    {/* <div>
                                        <Dropdown className="ml-1 ebw-prodet__bid mt-3" isOpen={dropdownOpen} toggle={dropdown}>
                                            <DropdownToggle className="ebw-prodet__bid-arrow ebw-prodet__bid " caret>
                                                <img className="ebw-prodet__bid-image mr-1" src="./images/projectdetails/icon-hammer.png"></img><span className="ml-1">Bid now</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>$200-$6000</DropdownItem>
                                                <DropdownItem>$300-$7000</DropdownItem>
                                                <DropdownItem>$400-$8000</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <div className="float-right ebw-prodet__total">10 Total bids</div>
                                    </div> */}
                                </div>
                                {/* <div className="mt-6">
                                    <Dropdown className="ml-1 ebw-prodet__ignore mt-3" isOpen={dropdownOpen} toggle={dropdown}>
                                            <DropdownToggle className="ebw-prodet__ignore-arrow d-flex align-items-center"   className="ebw-prodet__ignore" caret>
                                            <div><i class="fa fa-eye-slash mr-1"></i>Ignore this project</div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>$200-$6000</DropdownItem>
                                                <DropdownItem>$300-$7000</DropdownItem>
                                                <DropdownItem>$400-$8000</DropdownItem>
                                            </DropdownMenu>
                                     </Dropdown>
                                </div>
                                <div className="mt-6">
                                    <Dropdown className="ml-1 ebw-prodet__ignore mt-3" isOpen={dropdownOpen} toggle={dropdown}>
                                            <DropdownToggle className="ebw-prodet__ignore-arrow d-flex align-items-center"   className="ebw-prodet__ask" caret>
                                            <div>Ask a Question?</div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>$200-$6000</DropdownItem>
                                                <DropdownItem>$300-$7000</DropdownItem>
                                                <DropdownItem>$400-$8000</DropdownItem>
                                            </DropdownMenu>
                                     </Dropdown>
                                </div> */}
                                <div className="d-flex justify-content-between pt-7">
                                    <div className="ebw-buyproject__budjet">Project Budget</div>
                                    <div className="ebw-buyproject__budjet-cost">$6,000</div>
                                </div>
                                <div className="d-flex justify-content-between pt-7">
                                    <div className="d-flex align-items-center">
                                        <i className="ebw-icon ebw-icon--lg icon-phone ebw-buyproject__customer-call" aria-hidden="true"></i>
                                        <div className="ebw-buyproject__customer pl-2">Call Customer</div>
                                    </div>
                                    <div className="ebw-buyproject__customer-num">+1 92837 47293</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-6">
                        <div className="ebw-prodet__title ebw-prodet__title-project">Project Description</div>
                        <div className="ebw-prodet__description pt-4">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem Sed ut perspiciatis unde omnis iste natus error sit voluptatem nde omnis iste natus error sit voluptatem Sed ut perspiciatis unde omnis iste natus error sit voluptatemtis unde omnis is tis unde omnis is
                        </div>
                    </div>
                    <Row className="mt-14">
                        <Col md={4} className="">
                            <div>
                                <div className="ebw-prodet__dimension">Dimension</div>
                                <div className="d-flex align-items-center mt-1">
                                    <div className="ebw-prodet__dimension">Width :</div>
                                    <div className="ebw-prodet__dimension-grey ml-3">2.6 feet</div>
                                    <div className="ebw-prodet__dimension-grey ml-2">1 Inch</div>
                                </div>
                                <div className="d-flex align-items-center mt-1">
                                    <div className="ebw-prodet__dimension">Height :</div>
                                    <div className="ebw-prodet__dimension-grey ml-3">5 feet</div>
                                    <div className="ebw-prodet__dimension-grey ml-2">3 Inch</div>
                                </div>
                            </div>
                            <div className="ebw-prodet__show mt-5 d-flex align-items-center">
                                <span>Show more</span>
                                <span className="ebw-icon ebw-icon--md icon-arrow-down ebw-prodet__angle ml-1"></span>
                            </div>
                        </Col>
                        <Col md={8} className="d-md-flex ebw-prodet__roof">
                            <div className="ebw-prodet__roofscope">
                                <div className="d-flex align-items-center mt-1 mb-1 ebw-prodet__roofscope-text">
                                    {/*<img className="ebw-prodet__file-icon ml-2" src="../images/icons/file-icon.png"></img>*/}
                                    <span className="ebw-icon ebw-icon--md icon-document ml-2"></span>
                                    <span className="ml-1 pt-1 pb-1">Roofscope data available now.</span>
                                </div>
                                <img className="ebw-prodet__map mt-2" src="./images/projectdetails/detail-map.png"></img>
                            </div>
                            <div className="pl-md-5 pt-3 pt-md-0 pb-8 ebw-prodet__roofscope-content">
                                <div className="ebw-prodet__dimension">Type of roof</div>
                                <div className="ebw-prodet__dimension-grey pt-1">Stone Coated Steel</div>
                                <div className="ebw-prodet__dimension pt-4">Removal & haul away</div>
                                <div className="ebw-prodet__dimension-grey pt-1">Stone Coated Steel</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Main>
        </div>
    )
}

export default BuyProjectDetail