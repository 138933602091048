import * as type from '../actions/types';

const initialState = {
  refarral_list: [],
  loading: false,
  error: null,
}

export default function getRefarralList(state = initialState, action) {
  switch (action.type) {
    case type.GET_REFARRAL_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.GET_REFARRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        refarral_list: action.refarralList
      }
    case type.GET_REFARRAL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}