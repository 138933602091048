const validateTwo = (inputs) => {
    //Email errors
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let errors = {
        valid: true,
    };

    if (!validEmailRegex.test(inputs.email)) {
        errors.emailError = 'Invalid email address';
        errors.valid = false;
    } else if (!inputs.email) {
        errors.emailError = 'Enter Email';
        errors.valid = false;
    }
    if (inputs.otp.length < 6) {
        errors.otpError = 'Enter 6 digit OTP';
        errors.valid = false;
    } else if (inputs.otp === "") {
        errors.otpError = 'Enter OTP';
        errors.valid = false;
    }

    return errors;
}
export default validateTwo;