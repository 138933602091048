import React, { useState, useEffect } from 'react';
import axios from "axios";
import OtpInput from 'react-otp-input';
import { Col, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import API from "../../API";
import InputEmail from "../inputs/email-input";
// import { updateEmail } from "../../redux/actions/updateEmail";
import OtpInputNumber from "../inputs/otp-input";
import Buttons from "../buttons/button-primary";
// import { updateEmail } from "../../redux/actions/updateEmail";
// import { updateEmailOtp } from "../../redux/actions/updateEmailOtp";



const EmailUpdate = (props) => {
    const { email } = props;
    const [isOpen, openModal] = useState(false);

    // const mailUpadate = useSelector(state => state.updateEmail.note);
    // const mailUpadateLoading = useSelector(state => state.updateEmail.loading);
    // const mailUpadateError = useSelector(state => state.updateEmail.error);

    const toggleModal = () => openModal(!isOpen);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        email: "",
        currentStep: 1,
        otp: "",

        emailError: "",
        otpError: "",
    });
    const validateOtp = (inputs) => {
        let errors = {
            valid: true,
        };
        if (inputs.otp == "" || inputs.otp.length < 6) {
            errors.otpError = 'Enter valid OTP';
            errors.valid = false;
        }
        return errors
    }

    const validateEmail = (inputs) => {
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        let errors = {
            valid: true,
        };
        //Email errors
        if (!validEmailRegex.test(inputs.email)) {
            errors.emailError = 'Invalid email address';
            errors.valid = false;
        } else if (!inputs.email) {
            errors.emailError = 'Enter Email';
            errors.valid = false;
        }
        //otp errors

        return errors
    }
    const handleEmailChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            email: event.target.value,
            emailError: "",

        }));
    }
    const sendUpdatedEmail = (event) => {
        event.preventDefault();
        const errors = validateEmail(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));

        const data = JSON.stringify({
            email: state.email
        })

        errors.valid && axios.post(API.baseURL + `/email_update_request.json`, data, API.options)
            .then(result => {
                // this.setState({ loadingShow: false });
                if (result.status === 200 || result.status === 200) {
                    setState((prevProps) => ({
                        ...prevProps,
                        currentStep: 2,
                    }));
                }
            });
        // const errors = validateEmail(state);
        // setState((prevProps) => ({
        //     ...prevProps,
        //     ...errors
        // }));
        // const data = JSON.stringify({
        //     email: state.email
        // })
        // errors.valid && dispatch(updateEmail(data));
    }
    const handleChangeOtp = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            otp: data,
            otpError: "",
        }));
    }

    const sendOtp = () => {
        event.preventDefault();
        const errors = validateOtp(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        let data = JSON.stringify({
            email: state.email,
            otp: state.otp
        });
        errors.valid && axios.post(API.baseURL + `/email_update`, data, API.options)
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem("email", state.email);
                    window.location.href = "/profile";
                }
            }).catch(error => {
                setState((prevProps) => ({
                    ...prevProps,
                    otpError: error.response.data.data.errors,
                }));
            });
    }

    return (
        <>
            <div className="ebw-profile__mail-wrap mt-1">
                <span className="ebw-profile__mail">{email}</span>
                <span onClick={() => openModal(true)} className="ebw-profile__service-link ml-3">Edit</span>
            </div>
            <div className="ebw-profile__modal">
                <Modal isOpen={isOpen} toggle={toggleModal}>

                    {state.currentStep === 1 ?

                        <div className="px-5 py-5 ebw-modal__wrap">
                            <ModalHeader toggle={toggleModal} style={{ border: 'none' }}>
                                <div className="ebw-modal__title mb-1">Edit email address</div>
                                <div className="ebw-modal__para">We will send you an access code via email for verification.</div>
                            </ModalHeader>

                            <ModalBody className="ebw-modal__body">
                                <form className="ebw-modal__form">
                                    <div className="ebw-modal__input-wrap mb-2">
                                        <InputEmail
                                            className="ebw-modal__input"
                                            changeEvent={(e) => handleEmailChange(e)}
                                            value={state.email}
                                            type="email"
                                            placeholder="Enter new email id"
                                            required
                                            error={state.emailError}
                                        />
                                    </div>
                                    <div className="text-right d-flex justify-content-end">
                                        <div className="ebw-modal__submit ebw-button__plain ebw-button__plain--grey mr-2" onClick={toggleModal}>Cancel</div>
                                        <button className="ebw-modal__submit ebw-button__primary" type="submit" onClick={(e) => sendUpdatedEmail(e)}>Verify</button>
                                    </div>
                                </form>
                            </ModalBody>
                        </div>

                        :

                        <div className="px-6 pb-6  ebw-modal__wrap pl-5 pr-5">
                            <ModalHeader toggle={toggleModal} className="pb-0 px-0" style={{ border: 'none' }}>
                                <div className="ebw-modal__title mb-1">Verify OTP</div>
                            </ModalHeader>

                            <ModalBody className="p-0">

                                <OtpInputNumber
                                    value={state.otp}
                                    changeEvent={handleChangeOtp}
                                    otpError={state.otpError}
                                />

                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="mt-3 text-right">
                                        <a className="ebw-modal__submit ebw-button__plain ebw-button__plain--grey mr-2" style={{ color: "var(--color-grey-8)" }} role="button" href="#">Resend OTP</a>
                                    </div>

                                    <Col md="6" className="mt-3 px-0">
                                        <Buttons clickEvent={sendOtp} text="Verify OTP" />
                                    </Col>
                                </div>

                            </ModalBody>
                        </div>

                    }
                </Modal>
            </div>
        </>

    );
}

export default EmailUpdate;