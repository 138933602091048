import React, { useState } from 'react'
import { Card, CardTitle, CardText,CardBody, CardSubtitle } from 'reactstrap';
import { Link } from "react-router-dom";
import API from '../../API';
import axios from 'axios';
const CardSecondary = ({
    projectName,
    projectCategory,
    projectImage,
    projectDate,
    NoImage,
    projectLinks,
    project_status,
    status,
    lead_purchased,
    noShowLeadCard,
    bookmark,
    project_id
}) => {
    const getStatusOfSTatus = (status,lead_purchased) => {
        if (status === 2 && !lead_purchased) {
            return 'Lead available'
        } 
        else if (status === 2 && lead_purchased) {
            return 'Bid Now'
        } else if (status === 3) {
            return 'Hired'
        } else if (status === 4) {
            return 'Completed'
        } else if (status === 9) {
            return 'Cancelled'
        } else if (status === 6) {
            return "Cancellation Requested"
        }
    }
    const [bookmarked, setbookmarked] = useState(bookmark)
    const bookmarkThis = (bookmarked, id) => {
        const apiUrl = API.baseURL + `/v2/providers/projects/${id}/bookmark`;
        setbookmarked(!bookmark)
        if (bookmarked) {
            axios.delete(apiUrl,{}, API.options).then((res)=>{
                console.log(res);
                
            }).catch((err)=>{
                console.log(err);
            })
        } else {
            axios.post(apiUrl, {}, API.options).then((res)=>{
                console.log(res);
                
            }).catch((err)=>{
                console.log(err);
            })
            
        }
    }
    return (
        <div className="ebw-card__wrap ebw-card__wrap--secondary">
            <Card className="ebw-card ebw-card__secondary">
                {/* <div className="es-card__batch" style={{position: 'absolute',right: 10,top:10,zIndex:99999}}>
                    <img style={{width:20}} src={bookmarked ? "/images/bookmark/bookmark-icon-filled.png" : "/images/bookmark/bookmark-icon-outline.png"} onClick={() => bookmarkThis(bookmarked, project_id)} alt="" />
                </div> */}
                <Link className="w-100" to={projectLinks}>
                <div className="ebw-card__image-wrap">
                        {projectImage && (
                            <div style={{ backgroundImage: `url(${projectImage})` }} className="ebw-card__image-inwrap">
                            </div>
                        )}
                        {!projectImage && (
                            <div className="ebw-card__image-inwrap" style={{backgroundColor: '#3a4ee8',backgroundSize:'contain', backgroundImage: `url(/images/logo-trans.svg)` }}>
                            </div>
                        )} 
                    </div>
                    <CardBody className={` ${status ? "d-flex flex-column justify-content-between ebw-card__body-status" : "ebw-card__secondary-body"}`}>
                        <div>
                            <div className="d-flex justify-content-between">
                                <div className="ebw-card__secondary-titles">
                                    <CardTitle className="ebw-card__secondary-title">{projectName}</CardTitle>
                                    <CardSubtitle className="ebw-card__subtitle mb-0">{projectCategory?.title}</CardSubtitle>
                                </div>
                                <div className="text-right">
                                    {!noShowLeadCard && (
                                        <div className="ebw-card__label">{getStatusOfSTatus(project_status,lead_purchased)}</div>
                                    )}
                                    <CardText className="ebw-card__date">{projectDate}</CardText>
                                </div>
                            </div>
                            
                        </div>
                    </CardBody>
                </Link>
            </Card>
        </div>
        
    );
}

export default CardSecondary