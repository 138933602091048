import React from "react"
import ContentLoader from "react-content-loader"

const DashNotificationLoader = (props) => (
    <ContentLoader
        speed={2}
        width={476}
        height={168}
        viewBox="0 0 476 168"
        backgroundColor="#f5f5f5"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="13" y="40" rx="0" ry="0" width="33" height="33" />
        <rect x="57" y="18" rx="0" ry="0" width="4" height="78" />
        <rect x="72" y="22" rx="0" ry="0" width="285" height="14" />
        <rect x="72" y="49" rx="0" ry="0" width="285" height="14" />
        <rect x="72" y="75" rx="0" ry="0" width="285" height="14" />
        <rect x="374" y="50" rx="0" ry="0" width="38" height="15" />
    </ContentLoader>
)

export default DashNotificationLoader