import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col, ModalFooter } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, Label, Input } from 'reactstrap';
import Main from '../../layout/main';
import EditServiceTypes from "../../components/profile/editServiceTypes";
import { connect } from 'react-redux';
import EmailUpdate from "../../components/profile/emailUpdate";
import MobileNumberUpdate from "../../components/profile/mobileNumberUpdate";
import UploadProfilePic from "../../components/profile/profileImageUpdate";
import ResetPassword from "../../components/profile/resetPassword";
import Select from "../../components/inputs/select";
import validate from "../../components/profile/profileValidation";
import { updateProfile } from "../../redux/actions/updateProfile"
import { Link } from 'react-router-dom';
import ProfileLoader from "../../components/profile/profileSkeleton";
import GetFeedback from "../../components/profile/getFeedback";
import SearchSelect from "../../components/inputs/search-select";
import InputNumber from "../../components/inputs/number-input";
import axios from "axios";
import API from '../../API';
class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: true,
            fieldsUpdated: false,
            altranatePhone: "",
            street_address: "",
            zipcode: "",
            company_name: "",
            companyType: 2,
            google_id: "",
            yelp_id: "",
            insurance_number: "",
            license_number: "",
            years_of_business: null,
            website:'',
            websiteError:'',
            about_us:'',
            about_usError:'',
            cityRadius: [],
            companyNameError: "",
            altranatePhoneError: "",
            street_addressError: "",
            zipcodeError: "",
            companyTypeError: "",
            google_idError: "",
            yelp_idError: "",
            insurance_numberError: "",
            license_numberError: "",
            years_of_businessError: "",
            project_type_ids: [],
            project_sub_type_ids: [],
            provider_locations_attributes: [],
            cityRadiusError: "",
            serviceError: "",
            showmoreService: false,
            companies: [
                { label: "Company Type", value: "0" },
                { label: "Sole Proprietor", value: "1" },
                { label: "LLC", value: "2" },
                { label: "Corp", value: "3" }
            ],
            // Zipode Fix 
            zipcodeList: [],
            zipcodeForMile: "",
            miles_added: "",
            radiusError: "",
            // Zipode Fix 
            showZipcodeInfoModal: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.editSave = this.editSave.bind(this);
    }
    // Zipode Fix 
    setNewState = (updates) => {
        this.setState((prevState) => ({
            ...prevState,
            ...updates,
        }));
    };
    addZipcodeToList = () => {
        // Validation checks
        const zipcode = this.state.zipcodeList.find(zip => zip.name === this.state.zipcodeForMile);
        if(zipcode){
            this.setNewState({ radiusError: "Zipcode already exists" });
            return;
        }
        if (!this.state.zipcodeForMile) {
            this.setNewState({ radiusError: "Zipcode is required" });
            return;
        }
        if (this.state.zipcodeForMile.length !== 5) {
            this.setNewState({ radiusError: "5-digit zip required" });
            return;
        }
        if (!this.state.miles_added) {
            this.setNewState({ radiusError: "Miles radius required" });
            return;
        }
        if (this.state.miles_added > 99) {
            this.setNewState({ radiusError: "Miles max limit 99" });
            return;
        }
        if (this.state.zipcodeList.length >= 5) {
            this.setNewState({ radiusError: "5-digit zipcode required" });
            return;
        }
        // Add new zipcode to the list
        this.setNewState({
            zipcodeList: [
                ...this.state.zipcodeList,
                { name: this.state.zipcodeForMile, miles_added: this.state.miles_added }
            ],
            zipcodeForMile: "",
            miles_added: "",
            radiusError: "",
        });
    };

    onLocationRemoveHandler = (id) => {
        this.setNewState({
            zipcodeList: this.state.zipcodeList.filter((_, index) => index !== id),
        });
    };
    // Zipode Fix 
    componentDidMount() {
        const { 
            street_address,
            phone_number2,
            zipcode,
            company_name,
            company_type,
            google_id,
            yelp_id,
            insurance_number,
            license_number,
            years_of_business,
            website,
            about_us,
            project_types,
            project_sub_types,
            provider_locations
        } = this.props.profileData;
        const proSubTypes = [];
        const proTypes = [];
        project_sub_types && project_types.forEach((types => {
            proTypes.push(types.id);
        }))
        project_sub_types && project_sub_types.forEach((stypes => {
            proSubTypes.push(stypes.id);
        }))
        this.setState({
            altranatePhone: phone_number2,
            street_address: street_address,
            zipcode: zipcode,
            company_name: company_name,
            companyType: company_type,
            google_id: google_id,
            yelp_id: yelp_id,
            insurance_number: insurance_number,
            license_number: license_number,
            years_of_business: years_of_business,
            project_type_ids: proTypes,
            project_sub_type_ids: proSubTypes,
            website:website,
            about_us:about_us,
            provider_locations_attributes: provider_locations,
            zipcodeList: provider_locations?.map((location) => ({
                name: location.zipcode,
                miles_added: location.radius,
            })),
        })
    }

    clearErrors = () => {
        this.setState({
            companyNameError: "",
            altranatePhoneError: "",
            street_addressError: "",
            zipcodeError: "",
            companyTypeError: "",
            google_idError: "",
            yelp_idError: "",
            insurance_numberError: "",
            license_numberError: "",
            years_of_businessError: "",
            websiteError:'',
            about_usError:'',
            serviceError: "",
            cityRadiusError: ""
        })
    }
    handleChange(event) {
        this.setState({ editMode: event.target.value });
        this.clearErrors();
    }

    editSave = (event) => {
        event.preventDefault();
        const errors = validate(this.state);
        this.setState({ ...errors })
        let selected_cities = []
        for (const zipcode of this.state.zipcodeList) {
            selected_cities.push({
                zipcode: zipcode.name,
                radius: zipcode.miles_added
            });
        }
        let data = JSON.stringify({
            provider: {
                phone_number2: this.state.altranatePhone,
                street_address: this.state.street_address,
                zipcode: this.state.zipcode,
                company_name: this.state.company_name,
                company_type: this.state.companyType,
                google_id: this.state.google_id,
                yelp_id: this.state.yelp_id,
                years_of_business: this.state.years_of_business,
                website:this.state.website,
                about_us:this.state.about_us,
                insurance_number: this.state.insurance_number,
                license_number: this.state.license_number,
                project_type_ids: this.state.project_type_ids,
                project_sub_type_ids: this.state.project_sub_type_ids,
                provider_locations_attributes: selected_cities
            }
        })
        errors.valid && this.setState({ editMode: true })
        const apiUrl = API.baseURL + `/providers/update.json`;
        if(errors.valid){
            axios.put(apiUrl, data, API.options).then((res)=>{
                console.log(res);
                window.location.href = "/";
                // this.props.getLatest();
                // window.location.reload(true);
                // window.location.href = window.location.href;
            }).catch((err)=>{
                console.log(err);
            })
        }
    }
    // Started Modal Box Open Function 
    state = {
        isOpen: false,
        editItem: null,
        file: null,
        fileName: null,
        editStatus: "9898",
        error: "",
        value: "",
        altranatePhone: 8129,
    };

    openModal = (id) => this.setState({ isOpen: true, editItem: id });
    toggleModal = () => this.setState({ isOpen: false });
    // Ended Modal Box Open Function 
    handleChangeFile(event) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            fileName: event.target.files[0]
        })
    }
    
    handleRegisterServices = (types, subTypes, storeBusiness) => {
        const locationAtt = storeBusiness.provider_locations_attributes;
        let selected_cities = []
        for (let index = 0; index < locationAtt && locationAtt.length; index++) {
            const location = this.state.locationAtt[index];
            selected_cities.push({
                location_name: location.cityName,
                city: location.cityName,
                state: location.state,
                state_short: location.state_short,
                country: location.country,
                radius: locationAtt[index].radius
            })
        }

    }
    handleInputChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
            fieldsUpdated: true,
            companyNameError: "",
            altranatePhoneError: "",
            street_addressError: "",
            zipcodeError: "",
            companyTypeError: "",
            google_idError: "",
            yelp_idError: "",
            insurance_numberError: "",
            license_numberError: "",
            years_of_businessError: "",
            websiteError:'',
            about_usError:'',
            cityRadiusError: "",
            serviceError: "",
        })
    }
    handleTypeSubmit = (data) => {
        this.setState({ fieldsUpdated: true });
    };
    
    setValue = () => {
        this.setState({ editMode: !this.state.editMode })
    }
    setChangedServices = (data) => {
        this.setState({ project_sub_type_ids: data })
    };
    showMoreservice = () => {
        this.setState({ showmoreService: !this.state.showmoreService })
    }
    toggleZipcodeInfoModal = () => {
        this.setState((prevState) => ({
            showZipcodeInfoModal: !prevState.showZipcodeInfoModal
        }));
    };
    render() {
        const editProfileItems = this.props.editProfile.profileEdit
        // this.props.editProfile && this.props.editProfile.profileEdit
        const profileItems = this.props.profile.profile
        // const { serviceTypesData } = this.props;
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>My Account</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="profile pb-0">
                    <section className="ebw-section__pages ebw-section__profile">
                        <Container className="ebw-profile__inwrap">
                            {this.props.ProfileDataLoading ?
                                <ProfileLoader />
                                :
                                <div className="ebw-profile__row d-flex flex-wrap">
                                    <div className="ebw-profile__col ebw-profile__col-left">
                                        <div className="ebw-profile__col-wrap">
                                            <div className="ebw-profile__title ebw-title__primary mb-3">Edit Profile</div>
                                            <div className="ebw-profile__content d-md-flex d-lg-block">
                                                <div style={{ borderRadius:'50%',backgroundImage: `url(${profileItems && profileItems.avatar && profileItems.avatar})` }} className="ebw-profile__img d-flex align-items-end justify-content-center">
                                                    <UploadProfilePic />
                                                </div>
                                                <div className="ebw-profile__content-right mt-4">
                                                    <div className="ebw-profile__name-wrap d-flex align-items-center mt-7">
                                                        <div className="ebw-profile__name mr-4">{profileItems.name}</div>
                                                        {profileItems.is_pro_provider && <div className="ebw-profile__pro pt-1 pr-2 pb-1 pl-2">PRO +</div>}
                                                    </div>
                                                    <EmailUpdate email={profileItems.email} />
                                                    <MobileNumberUpdate mobile_number={profileItems.mobile_number} />
                                                    <Link to="/review">
                                                        <div className="ebw-profile__rating d-flex align-items-center mt-4">
                                                            <div className="ebw-profile__rating-number pr-2 pl-1">{profileItems.average_ratings}<span className="ebw-icon ebw-icon--md icon-review pl-1 ebw-profile__rating-star"></span></div>
                                                            <div className="ebw-profile__rating-review ml-1">{profileItems.total_reviews} Reviews</div>
                                                        </div>
                                                    </Link>

                                                    <GetFeedback provider_id={editProfileItems.id} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ebw-profile__col ebw-profile__col-right mt-10 mt-md-0">
                                        <div className="ebw-profile__col-two">
                                            <div className="ebw-profile__editprofile mt-1 mb-3">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <ResetPassword email={profileItems.email} />
                                                    {this.state.editMode ?
                                                        <div onClick={() => this.setValue()} className="ebw-profile__editprofile-btn ml-3">
                                                            <span className="ebw-icon ebw-icon--md icon-edit"></span>
                                                            <span className="ml-1 ebw-profile__editprofile-text text-md">Edit Profile</span>
                                                        </div> :
                                                        <div onClick={() => this.setValue()} className="ebw-profile__editprofile-btn ml-3">
                                                            {/* <span className="ebw-icon ebw-icon--md icon-close ebw-bold"></span> */}
                                                            <span className="ml-1 ebw-profile__editprofile-text text-md">Cancel Edit</span>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            </div>
                                            {/* <Col sm="12" className="ebw-profile__yelp mt-4">
                                                <ResetPassword email={profileItems.email} />
                                            </Col> */}
                                            <Form className="ebw-profile__details d-md-flex flex-wrap justify-content-between">
                                                <div className="row pb-6">
                                                    <div className="col-md-6">
                                                        <div className="ebw-profile__no  mt-4">
                                                            <Label className="ebw-profile__no-phone ebw-profile__name-gray">Alternative Number</Label>
                                                            {!this.state.editMode || this.state.altranatePhone == "" ?
                                                                <Input
                                                                    type="number"
                                                                    className={`ebw-profile__no-num ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                    value={this.state.altranatePhone}
                                                                    name="altranatePhone"
                                                                    onChange={this.handleInputChange}
                                                                    disabled={this.state.editMode}
                                                                /> : " "
                                                            }
                                                            {!this.state.editMode || this.state.altranatePhone == "" || this.state.altranatePhone == null &&
                                                                <div>
                                                                    <p>Not provided yet</p>
                                                                </div>
                                                            }
                                                            <p className="ebw-input__error">{this.state.altranatePhoneError}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="ebw-profile__address mt-4">
                                                            <Label className="ebw-profile__address-name ebw-profile__name-gray">Business Address*</Label>
                                                            <Input
                                                                type="text"
                                                                className={`ebw-profile__address-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                value={this.state.street_address}
                                                                name="street_address"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.editMode}
                                                            />
                                                            <p className="ebw-input__error">{this.state.street_addressError}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="ebw-profile__zip mt-4">
                                                            <Label className="ebw-profile__zip-name ebw-profile__name-gray">ZIP*</Label>
                                                            <Input
                                                                type="number"
                                                                className={`ebw-profile__zip-number ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                value={this.state.zipcode}
                                                                name="zipcode"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.editMode}
                                                            />
                                                            <p className="ebw-input__error">{this.state.zipcodeError}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="ebw-profile__company mt-4">
                                                            <Label className="ebw-profile__company-name ebw-profile__name-gray">Company Name*</Label>
                                                            <Input
                                                                type="text"
                                                                className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                value={this.state.company_name}
                                                                name="company_name"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.editMode}
                                                            />
                                                            <p className="ebw-input__error">{this.state.companyNameError}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="ebw-profile__type mt-4">
                                                            <Label className="ebw-profile__type-text ebw-profile__name-gray">Company Type*</Label>
                                                            <Select
                                                                // type="select"
                                                                name="companyType"
                                                                additionalClass={`ebw-profile__type-input ebw-profile__name-text ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                value={this.state.companyType}
                                                                changeEvent={this.handleInputChange}
                                                                data={this.state.companies}
                                                                disabled={this.state.editMode}

                                                            />
                                                            <p className="ebw-input__error">{this.state.companyTypeError}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="ebw-profile__type mt-4">
                                                            <Label className="ebw-profile__type-text ebw-profile__name-gray">Areas Served*</Label>
                                                            <span 
                                                                onClick={this.toggleZipcodeInfoModal}
                                                                style={{ 
                                                                    cursor: 'pointer',
                                                                    marginLeft: '5px',
                                                                    display: 'inline-flex',
                                                                    verticalAlign: 'middle',
                                                                    marginBottom: '10px',
                                                                }}
                                                            >
                                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="#3478F6">
                                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                                                                </svg>
                                                            </span>
                                                            {this.state.editMode ?
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    {this.state?.zipcodeList?.map((zips) => (
                                                                        <div key={zips.id} className="ebw-profile__service-city d-flex align-items-center mb-2">
                                                                            <div className="ebw-profile__type-input mr-2">Zip Code: {zips.name} - Radius: {zips.miles_added} mi</div>
                                                                        </div>
                                                                    ))}
                                                                </div> : 
                                                                <Row>
                                                                    <Col xs="5" className='pr-0'>
                                                                        {/* <SearchSelect
                                                                            label="Zip Code"
                                                                            type="number"
                                                                            name="zipcodeForMile"
                                                                            id="register_cities"
                                                                            value={this.state.zipcodeForMile}
                                                                            error={this.state.radiusError}
                                                                            changeEvent={(e) => this.setNewState({ zipcodeForMile: e })}
                                                                        /> */}
                                                                        <Input
                                                                            label="Zip Code"
                                                                            placeholder="Zip Code"
                                                                            type="number"
                                                                            id="register_cities"
                                                                            className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input  ${this.state.editMode ? "" : "edit-enable"}`}
                                                                            name="zipcodeForMile"
                                                                            value={this.state.zipcodeForMile}
                                                                            error={this.state.radiusError}
                                                                            onChange={(e) => this.setNewState({ zipcodeForMile: e.target.value })}
                                                                            style={{height:53}}
                                                                        />
                                                                        
                                                                    </Col>
                                                        
                                                                    <Col xs="5" className='pr-0'>
                                                                        <InputNumber
                                                                            label="Miles Radius"
                                                                            name="mileRadius"
                                                                            id="register_miles"
                                                                            error={this.state.radiusError}
                                                                            value={parseInt(this.state.miles_added)}
                                                                            changeEvent={(e) => this.setNewState({ miles_added: e.target.value })}
                                                                        />
                                                                    </Col>
                                                        
                                                                    <Col xs="2" className="h-100">
                                                                        <a
                                                                            role="button"
                                                                            className="ebw-form__input-select--add my-auto d-block"
                                                                            onClick={this.addZipcodeToList}
                                                                            style={{ height: 53, fontSize: 20 }}
                                                                        >
                                                                            +
                                                                        </a>
                                                                    </Col>
                                                        
                                                                    <div className="es-measured-wrap ml-3">
                                                                        {this.state.zipcodeList.map((data, i) => (
                                                                            <div className="es-measured mr-2 mb-2" key={i} style={{background:'white',padding:10,borderRadius:10,display:'flex'}}>
                                                                                <div className="es-measured-input mr-2">
                                                                                    Zip Code: {data.name} - Radius: {data.miles_added} mi
                                                                                </div>
                                                                                <div
                                                                                    className="es-measured-close"
                                                                                    onClick={() => this.onLocationRemoveHandler(i)}
                                                                                    style={{ cursor: "pointer", color: "#FF6347" }}
                                                                                >
                                                                                    X
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                   
                                                                </Row>

                                                            }


                                                        </div>
                                                    </div>
                                                    <Col md="6" className="ebw-profile__yelp mt-4">
                                                        <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">Insurance ID</div>
                                                        <Input
                                                            type="text"
                                                            className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                            value={this.state.insurance_number}
                                                            name="insurance_number"
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.editMode}
                                                        />
                                                        <p className="ebw-input__error">{this.state.insurance_numberError}</p>
                                                    </Col>

                                                    <Col md="6" className="ebw-profile__yelp mt-4">
                                                        <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">License ID</div>
                                                        <Input
                                                            type="text"
                                                            className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                            value={this.state.license_number}
                                                            name="license_number"
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.editMode}
                                                        />
                                                        <p className="ebw-input__error">{this.state.license_numberError}</p>
                                                        {/* <div className="ebw-profile__yelp-text ebw-profile__name-text">{editProfileItems.license_number == "" ? "--" : editProfileItems.license_number}</div> */}
                                                    </Col>

                                                    <Col md="6" className="ebw-profile__yelp mt-4">
                                                        <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">Number of years in business*</div>
                                                        <Input
                                                            type="number"
                                                            className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                            value={this.state.years_of_business}
                                                            name="years_of_business"
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.editMode}
                                                        />
                                                        <p className="ebw-input__error">{this.state.years_of_businessError}</p>
                                                    </Col>
                                                    <Col md="6" className="ebw-profile__yelp mt-4">
                                                        <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">Website</div>
                                                        <Input
                                                            className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                            value={this.state.website}
                                                            name="website"
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.editMode}
                                                        />
                                                        <p className="ebw-input__error">{this.state.websiteError}</p>
                                                    </Col>
                                                    <Col md="12" className="ebw-profile__yelp mt-4">
                                                        <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">About Us</div>
                                                        <Input
                                                            className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                            value={this.state.about_us}
                                                            name="about_us"
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.editMode}
                                                        />
                                                        <p className="ebw-input__error">{this.state.about_usError}</p>
                                                    </Col>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="ebw-profile__service mt-4">
                                                            <Label className="ebw-profile__service-view ebw-profile__name-gray">Service list*</Label>
                                                            <div className="mt-1">
                                                                <div>
                                                                    {/* {showmoreService} */}
                                                                    <div>
                                                                        {editProfileItems?.project_sub_types?.slice(0, 15)?.map((serviceItem, index) =>
                                                                        (
                                                                            <>
                                                                                <span key={index} className="mb-2">{serviceItem.name}{index + 1 != 15 ? "," : ""} </span>
                                                                            </>
                                                                        )
                                                                        )}
                                                                    </div>
                                                                    {this.state.editMode &&
                                                                        <div className="ebw-profile__service-link " onClick={() => this.openModal('services')}>{this.state.showmoreService ? "show less" : "show more"}</div>
                                                                    }
                                                                </div>
                                                                {!this.state.editMode &&
                                                                    <div onClick={() => this.openModal('services')} className="ebw-profile__service-link">{this.state.editMode ? "View service list" : "Edit service list"}</div>
                                                                }
                                                            </div>
                                                            {this.state.serviceError ? <p className="ebw-input__error ebw-input__error--service-list">{this.state.serviceError}</p> : ""}
                                                        </div>
                                                    </div>
                                                    <Col sm="12" className="ebw-profile__yelp mt-4">
                                                        {!this.state.editMode &&
                                                            <a role="button" onClick={this.editSave} className="ml-1 ebw-button ebw-button__primary py-2 d-flex align-items-center justify-content-center">
                                                                <span>Save</span>
                                                            </a>}
                                                    </Col>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Container>
                    </section>

                    {/* Started Modal Box */}
                    <div className="ebw-profile__modal">
                        <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
                            {this.state.editItem == "services" ?
                                <div className="px-5 py-5 ebw-modal__wrap">
                                    <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                        <div className="ebw-modal__title mb-1">Service List</div>
                                    </ModalHeader>
                                    <ModalBody className="ebw-modal__body">
                                        {this.state.editMode ?
                                            <>
                                                {editProfileItems.project_sub_types.map((serviceItem, index) => {
                                                    return (
                                                        <div key={index} className="mb-2">{serviceItem.name}</div>
                                                    )
                                                })}
                                            </> :

                                            <EditServiceTypes
                                                projectSubTypes={editProfileItems.project_sub_types}
                                                serviceTypesData={this.props?.serviceTypesData}
                                                selectedSubTypesPrev={editProfileItems.project_sub_types}
                                                closeModal={this.toggleModal}
                                                handleRegisterServices={this.handleTypeSubmit}
                                                setChangedServices={this.setChangedServices}
                                            />
                                        }
                                    </ModalBody>
                                </div>
                                :
                                this.state.editItem == "changeDP" ?
                                    <div className="px-5 py-5 ebw-modal__wrap">
                                        <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                            <div className="ebw-modal__title mb-1">Reset your Password</div>
                                        </ModalHeader>
                                        <ModalBody className="ebw-modal__body">
                                            <form className="ebw-modal__form">
                                                <div className="ebw-modal__input-wrap mb-4">
                                                    <Input id="uploadDP" className="ebw-modal__input d-none" type="file" onChange={this.handleChange} required></Input>
                                                    <label for="uploadDP" className="ebw-my__uploads-file m-0 w-100">
                                                        <div className="ebw-my__uploads-inwrap text-center">
                                                            <div className="ebw-form__input ebw-profile__input-file--custom d-flex justify-content-between align-items-center btn btn-secondary">
                                                                <span className="ebw-form__input ebw-profile__input-file--text">Choose File</span>
                                                                {/* <span className="ebw-icon ebw-icon--xl icon-arrow-down"></span> */}
                                                            </div>
                                                            <p className="ebw-my__uploads-text pt-3">Or drop files here. Max file size 10MB</p>
                                                            {this.state.file ?
                                                                <div>
                                                                    <div style={{ backgroundImage: `url(${this.state.file})` }} className="ebw-my__uploads-img"></div>
                                                                    <div>{this.state.fileName.name}</div>
                                                                </div>
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="text-right mt-6">
                                                    <button className="ebw-modal__submit ebw-button__primary" type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </ModalBody>
                                    </div>

                                    :
                                    this.state.editItem == "changeDP" ?

                                        <div className="px-5 py-5 ebw-modal__wrap">
                                            <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                                <div className="ebw-modal__title text-capitalize mb-1">Upload Profile Picture</div>
                                            </ModalHeader>
                                            <ModalBody className="ebw-modal__body">
                                                <form className="ebw-modal__form">
                                                    <div className="ebw-modal__input-wrap mb-4">
                                                        <Input id="uploadDP" className="ebw-modal__input d-none" type="file" onChange={this.handleChange} required></Input>
                                                        <label for="uploadDP" className="ebw-my__uploads-file m-0 w-100">
                                                            <div className="ebw-my__uploads-inwrap text-center">
                                                                <div className="ebw-form__input ebw-profile__input-file--custom d-flex justify-content-between align-items-center btn btn-secondary">
                                                                    <span className="ebw-form__input ebw-profile__input-file--text">Choose File</span>
                                                                    {/* <span className="ebw-icon ebw-icon--xl icon-arrow-down"></span> */}
                                                                </div>
                                                                <p className="ebw-my__uploads-text pt-3">Or drop files here. Max file size 10MB</p>
                                                                {this.state.file ?
                                                                    <div>
                                                                        <div style={{ backgroundImage: `url(${this.state.file})` }} className="ebw-my__uploads-img"></div>
                                                                        <div>{this.state.fileName.name}</div>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="text-right">
                                                        <button className="ebw-modal__submit ebw-button__primary" type="submit">Update</button>
                                                    </div>
                                                </form>
                                            </ModalBody>
                                        </div>

                                        : ""
                            }
                        </Modal>
                    </div>
                    {/* Ended Modal Box */}

                    {/* Add Zipcode Info Modal */}
                    <Modal isOpen={this.state.showZipcodeInfoModal} toggle={this.toggleZipcodeInfoModal} className="ebw-profile__modal">
                        <div className="px-2 py-2 ebw-modal__wrap">
                            <ModalHeader toggle={this.toggleZipcodeInfoModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                <div className="ebw-modal__title mb-1">Serviced Zip Codes</div>
                            </ModalHeader>
                            <ModalBody className="ebw-modal__body" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                <div>
                                    <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                        The zipcodes you serve determine which projects will be available to you.
                                    </p>

                                    {this.props.profileData?.serviced_zipcodes && this.props.profileData.serviced_zipcodes.length > 0 ? (
                                        <div>
                                            <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                                Your current service areas include:
                                            </p>
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '8px',
                                                marginBottom: '15px'
                                            }}>
                                                {this.props.profileData.serviced_zipcodes.map((zipcode, index) => (
                                                    <div key={index} style={{
                                                        padding: '5px 10px',
                                                        backgroundColor: '#f0f7ff',
                                                        borderRadius: '4px',
                                                        border: '1px solid #d0e3ff',
                                                        fontSize: '14px',
                                                    }}>
                                                        {zipcode}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p style={{ fontSize: '14px', marginBottom: '10px', color: '#f44336' }}>
                                            You don't have any service zipcodes configured yet.
                                        </p>
                                    )}
                                    
                                    <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                        If you've just made a change, sometimes it takes several minutes for the updates to take effect. You can also remove the zip code entries and try again.
                                    </p>
                                </div>
                                
                            </ModalBody>
                            <ModalFooter>
                            <div className="text-right mt-1 mb-1">
                                    <button 
                                        onClick={this.toggleZipcodeInfoModal} 
                                        className="ebw-button ebw-button__primary"
                                    >
                                        OK
                                    </button>
                                </div>
                            </ModalFooter>
                        </div>
                    </Modal>
                </Main>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profileData,
    editProfile: state.getProfileEditData,
    serviceTypesData: state.serviceTypes,
    selectedTypes: state.selectedTypes,
    authData: state.auth,
    servedCities: state.servedCities.cities


});

export default connect(mapStateToProps, { updateProfile })(Profile);;
