import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import { BidTypeSelect } from "../inputs/select";
import validate from "./bidFieldsValidator";
import { submitBid } from "../../redux/actions/submitBid"
import Buttons from "../buttons/button-primary";

const SubmitBid = (props) => {
    // const { commissionDetails } = props;
    const dispatch = useDispatch();
    const bidTypes = [
        { label: "Hourly Bid", value: "hourly" },
        { label: "Fixed Bid", value: "fixed" },
    ]
    const [state, setState] = useState({
        bid_amount: props.bid_amount,
        bid_type: props.bid_type,
        hours: props.hours,
        bid_detail: props.bid_detail,
        amount_receive: 0,
        activeClass: "",

        hoursError: "",
        bidAmountError: "",
    });

    useEffect(() => {
        setState((prevProps) => ({
            ...prevProps,
            bid_amount: props.bid_amount.replace('$', ''),
            bid_type: props.bid_type ? props.bid_type : "fixed",
            hours: props.hours,
            bid_detail: props.bid_detail,
        }));
        if (Number(props.bid_amount.replace('$', '')) == 0) {
            setState((prevProps) => ({
                ...prevProps,
                bid_type: "hourly",

            }));
        }
    }, [])

    const handleInputPriceChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            bid_amount: event.target.value,
            hoursError: "",
            bidAmountError: "",
        }));
    }
    const handleBidTypeChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            bid_type: event.target.value,
            hoursError: "",
            bidAmountError: "",
        }));
    }
    const handleInputHoursChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            hours: event.target.value,
            hoursError: "",
            bidAmountError: "",
        }));
    }
    const handleInputDiscriptionChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            bid_detail: event.target.value
        }));
    };
    const checkHourly = (type) => {
        let bidType = true;
        if (type === "hourly") {
            bidType = true
        } else {
            bidType = false
        }
        return bidType;
    }
    const handleSubmit = () => {
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = {
            bid_amount: state.bid_amount,
            bid_breakdown: state.bid_detail,
            bid_type: checkHourly(state.bid_type),
            hours: state.hours,
            status: props.status,
            project_id: props.project_id,
            bid_id:props.bid_id
        };
        errors.valid && dispatch(submitBid(data));
    }
    return (
        <>
            <Row>

                <Col xs="12">
                    <div className="ebw-prodet__subtitle ebw-card__subtitle mb-2">Bid Amount $</div>
                    <FormGroup className="d-flex justify-content-between mb-0">
                        <Col md="6" className="pl-0 mb-4">
                            <Input
                                type='number'
                                name="bid_price"
                                id="bid_price"
                                className="ebw-form__bid-input"
                                placeholder="Enter Amount"
                                value={state.bid_amount=='0'?0:state.bid_amount}
                                error={state.bidAmountError}
                                onChange={handleInputPriceChange}
                            />
                            {state.amount_receive != 0 && <div className="ebw-myprojects__inbidding-text pt-1">You will get $ {state.amount_receive} after you complete the job</div>}
                        </Col>

                        <Col md="6" className="pr-0 mb-4">
                            <BidTypeSelect
                                name="BidType"
                                id="register_address"
                                value={state.bid_type}
                                changeEvent={handleBidTypeChange}
                                data={bidTypes}
                            />
                        </Col>
                    </FormGroup>

                    {state.bid_type == "hourly" &&
                        <FormGroup>
                            <Col className="px-0 mb-4">
                                <Input
                                    type="text"
                                    name="hours"
                                    id="hours"
                                    className="ebw-form__bid-input"
                                    placeholder="Enter number of approx. hours"
                                    value={state.hours}
                                    error={state.hoursError}
                                    onChange={handleInputHoursChange}
                                />
                            </Col>
                        </FormGroup>
                    }

                    <FormGroup>
                        <Col className="px-0 mb-4">
                            <Input
                                type="textarea"
                                rows={5}
                                cols={5}
                                name="discription"
                                id="discription"
                                className="ebw-form__bid-input"
                                placeholder="Brief your bid details like cost breakdown, estimated time, human hours."
                                value={state.bid_detail}
                                onChange={handleInputDiscriptionChange}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col xs="12">
                    {state.hoursError ?
                        <div className="ebw-error">{state.hoursError}</div> : ""}
                    {state.bidAmountError ?
                        <div className="ebw-error">{state.bidAmountError}</div> : ""}
                </Col>

                <Col xs="6" md="3" lg="6" xl="5" className="ml-auto mt-3">
                    <Buttons type="submit" text="SUBMIT BID" clickEvent={handleSubmit} />
                </Col>
            </Row>

        </>
    );
}

export default SubmitBid;