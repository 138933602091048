import Cookies from 'js-cookie';
const API = {
	wsURL: `wss://staging-api.easybids.com/cable`,
	stripeId: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
	baseURL: process.env.REACT_APP_BASE_URL,
	options: {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'access-token': Cookies.get('access_token'),
			'uid': Cookies.get('uid'),
			'client': Cookies.get('client'),
			'is_provider': 'true',
		}
	},
	fileOptions: {
		headers: {
			'Content-Type': 'multipart/form-data',
			'access-token': Cookies.get('access_token'),
			'uid': Cookies.get('uid'),
			'client': Cookies.get('client'),
			'is_provider': 'true',
		}
	}
}
export default API;