import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';



async function postApi(data) {
    const apiUrl = API.baseURL + `/providers/delete_bid/${data.project_id}`;
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.delete(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* deleteBid(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // setAuth(response.data.data, response.headers);
            yield put({ type: 'DELETE_BID_SUCCESS', registerPersonal: response.data });
            // toastr.success('Deleted', 'You are successfully deleted the bid');
        } else {
            // response.errors.length != 0 && toastr.error('Deletion Failed', response.errors[0]);
        }
        // history.push('/projects');
        window.location.href = "/my-projects"

    } catch (e) {
        yield put({ type: 'DELETE_BID_FAILED', message: e.message });
    }
}

function* deleteBidSaga() {
    yield takeEvery('DELETE_BID_REQUESTED', deleteBid);
}

export default deleteBidSaga;