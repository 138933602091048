import React from 'react';
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
import { Input, InputGroup, InputGroupAddon, InputGroupText, Button } from "reactstrap";

const InputNumber = (props) => {
    // an onchange validation can be done here 
    return (
        <div>
            <FloatingLabel
                id={props.name}
                name={props.name}
                placeholder={props.label}
                className="custom-class ebw-form__input"
                type="number"
                value={props.value}
                onChange={props.changeEvent}
                style={{height:53}}
            />
            {props.error && <p className="ebw-input__error mt-0 mb-2 text-left">{props.error}</p>}
        </div>
    );
}
export default InputNumber;


//- Number Input for Payment Cards
export const InputPaymentCard = (props) => {
    return (
        <>
            <InputGroup className="ebw-tab__filter-group mb-3">
                <Input
                    type="number"
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    placeholder={props.label}
                    className="ebw-form__input ebw-form__input-payment ebw-form__input-select--category text-sm"
                />

                <InputGroupAddon addonType="append" className="ebw-tab__filter-groupaddon">
                    <InputGroupText className="ebw-tab__filter-grouptext p-0 bg-transparent">
                        <div className="bg-transparent ebw-tab__filter-search--paymentcard mr-3">
                            <span className="ebw-icon ebw-icon--lg icon-credit-card ebw-tab__filter-icon"></span>
                        </div>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </>
    )
}