import React, { useState } from 'react';
import LeadCheckout from "./leadCheckout";

const LeadPaymentCard = (props) => {
    const [serviceAmount, setServiceAmount] = useState(0)
    const { project_id, additionalClass } = props;
    return (
        <div className={`ebw-entry__pro-card ${additionalClass}`}>
            <div className="ebw-paymentn__row d-flex justify-content-between align-items-center mb-3">
                <div className="ebw-paymentn__title ebw-paymentn__title--M">Service amount</div>
                <div className="ebw-paymentn__amount ebw-paymentn__amount--M">$ {serviceAmount}</div>
            </div>
            <div className="ebw-paymentn__row mb-4">
                <p className="ebw-paymentn__para ebw-paymentn__para--M">We charge this fee for the provided services. Kindly collect the project closure fee from the customer.</p>
            </div>
            <div className="ebw-entry__pro-payment">
                <h3 className="ebw-title__secondary mb-2">Payment information</h3>
                <div className="ebw-entry__pro-form d-flex flex-wrap">
                    <LeadCheckout setServiceAmount={setServiceAmount} project_id={project_id} />
                </div>
            </div>
        </div>
    );
}

export default LeadPaymentCard;