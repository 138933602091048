import React, { useState } from 'react';
import { Row, Col, Navbar, Nav, NavbarToggler, Collapse, NavItem, Badge, NavbarBrand, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ButtonYellow from "../buttons/button-yellow";
import { NotificationDropdown } from '../dropdown/dropdown';
import { logoutCookie } from "../../utils"
import { logout } from "../../redux/actions/logout";
import NewService from "../../layout/newServices";

export const MobileHeader = (props) => {

    const { notificationData, notificationLoading, notificationCount, profileData, authData, video_url } = props;
    const dispatch = useDispatch();
    //- Navbar Toggler 
    const [isOpen, setIsOpen] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [addNewService, setAddNewService] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const logoutFormApp = () => {
        logoutCookie();
        dispatch(logout());
    }

    const navs = [
        { id: 1, icon: 'review', title: 'Reviews', url: '/review' },
        { id: 2, icon: 'edit', title: 'My Account', url: '/profile' },
        { id: 3, icon: 'works', title: 'My Works', url: '/my-works' },
        { id: 9, icon: 'buy', title: 'Wallet', url: '/wallet-history' },
        { id: 4, icon: 'faqs', title: "FAQs", url: '/faqs' },
        { id: 5, icon: 'document', title: 'Terms & Conditions', url: '/terms-conditions' },
        { id: 6, icon: 'privacy', title: 'Privacy Policy', url: '/privacy-policy' },
        { id: 7, icon: 'support', title: 'Contact Support', url: '/support' },
        // { id: 9, icon: 'support', title: 'New Service', url: '', onClick: () => setAddNewService(true) },
        // { id: 10, icon: 'support', title: 'Tips', url: '', onClick: () => setPlayVideo(true) },
        { id: 8, icon: 'logout', title: 'Logout', url: '', onClick: () => logoutFormApp() }
    ]

    return (
        <>
            <Navbar className="ebw-header__inner ebw-header__inner--postlogin d-flex d-lg-none align-items-center py-0">
                <NavbarBrand className="ebw-header__brandwrap" href="/dashboard">
                    <img className="ebw-header__brand img-fluid mx-auto d-block" src="/images/logo.svg" alt="Logo" />
                </NavbarBrand >

                <Nav className="d-flex align-items-center ml-auto">
                    <NavItem className="ebw-header__menu-item mr-3">
                        <NavLink exact to="/profile" className='ebw-header__menu-link mt-1'>
                            <div className="ebw-header__profile-img ebw-header__profile-img--mobile">
                                <img src={`${profileData && profileData.avatar_thumb && profileData.avatar_thumb}`} className="img-fluid d-block" alt="Profile" />
                            </div>
                        </NavLink>
                    </NavItem>

                    <NavItem className="ebw-header__menu-item">
                        <div className="ebw-header__menu-link">
                            <NotificationDropdown
                                notificationData={notificationData}
                                notificationLoading={notificationLoading}
                                notificationCount={notificationCount}

                            />
                        </div>
                    </NavItem>
                </Nav>

                <NavbarToggler tag="a" role="button" onClick={toggle} className="ebw-header__menu-toggler px-2">
                    {/* <img src="./images/icons/icon-hamburger.svg"  className="img-fluid mx-auto d-block" /> */}
                    <span className="ebw-icon ebw-icon--4xl icon-hamburger"></span>
                </NavbarToggler>

                <Collapse isOpen={isOpen} navbar className="ebw-header__menu-collapse">
                    <Nav navbar className="ebw-header__menu-nav ml-auto">
                        <div className="d-flex ml-auto mb-4 mt-3">
                            <a className="ebw-header__menu-close" role="button" onClick={toggle}>
                                {/*<img src="./images/icons/icon-cancel.svg" className="img-fluid mx-auto d-block"/>*/}
                                <span className="ebw-icon ebw-icon--2xl icon-close"></span>
                            </a>
                        </div>
                        <NavItem className="ebw-header__menu-item">
                            <NavLink exact to="/profile">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="ebw-header__profile-img">
                                        <img src={`${profileData && profileData.avatar_thumb && profileData.avatar_thumb}`} className="img-fluid d-block" alt="Profile" />
                                    </div>

                                    <div className="ebw-header__profile-info px-2">
                                        <div className="ebw-header__profile-name">{profileData && profileData.name && profileData.name}</div>
                                        {/* <div className="ebw-header__profile-text">Provider</div> */}
                                        <div className="mt-1">
                                            {/* Provider */}
                                            <div className="ebw-header__profile-pro ebw-profile__pro">PRO +</div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </NavItem>
                        {!profileData.is_pro_provider &&
                            <NavItem className="ebw-header__menu-item">
                                <NavLink exact to="/pro-plus" className="text-center mb-2 w-100">
                                    <ButtonYellow text="Upgrade to Pro +" />
                                    <span className="text-xxs" style={{ color: "var(--color-grey-8)" }}>Our PRO+ Members gets <strong style={{ color: "#252525" }}>30%</strong> more jobs</span>
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem className="ebw-header__menu-item">
                            <div exact to="" onClick={() => setAddNewService(true)} className='ebw-header__menu-link' >
                                <div className="d-flex align-items-center">
                                    <span className="ebw-icon ebw-icon--lg icon-">  <img className="ebw-header__menu-icon--video" src="/images/icons/services-notification.svg" /></span>
                                    <span className="pl-3">New Service</span>
                                </div>
                            </div>
                        </NavItem>
                        <NavItem className="ebw-header__menu-item" >
                            <div exact to="" onClick={() => setPlayVideo(true)} className='ebw-header__menu-link' >
                                <div className="d-flex align-items-center">
                                    <span className="ebw-icon ebw-icon--lg">
                                        <img className="ebw-header__menu-icon--video" src="/images/icons/bulp.svg" />
                                    </span>
                                    <span className="pl-3">Tips</span>
                                </div>
                            </div>
                        </NavItem>

                        {navs.map((nav, index) => (
                            <NavItem key={index} className="ebw-header__menu-item">
                                <NavLink
                                    exact to={nav.url}
                                    onClick={nav.onClick && nav.onClick}
                                    className='ebw-header__menu-link' >
                                    <div className="d-flex align-items-center">
                                        <span className={"ebw-icon ebw-icon--lg icon-" + nav.icon}></span>
                                        <span className="pl-3">{nav.title}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        ))}

                    </Nav>
                </Collapse>
            </Navbar>
            <Modal isOpen={playVideo} size="lg" toggle={() => setPlayVideo(false)}>
                <div className="">
                    {/* <ModalHeader toggle={() => redirectToReview()}></ModalHeader> */}
                    <ModalBody style={{ lineHeight: 0 }} className="p-0">
                        <iframe width="100%" height="300" src={video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={addNewService} toggle={() => setAddNewService(false)}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={() => setAddNewService(false)}></ModalHeader>
                    <ModalBody className="pt-0">
                        <h3 className="text-4xl 4">New Services</h3>
                        <div>
                            <NewService />
                        </div>
                    </ModalBody>
                </div>
            </Modal>

        </>
    );
}

const mnavs = [
    { id: 1, icon: 'home', title: 'Dashboard', url: '/dashboard' },
    { id: 2, icon: 'projects', title: 'Projects', url: '/projects/all/1' },
    { id: 3, icon: 'my-projects', title: 'My Projects', url: '/my-projects' },
    { id: 4, icon: 'buy', title: 'Buy Projects', url: 'leads' }
]
export const MobileNavTabs = () => {
    return (
        <>
            <Navbar className="d-block d-lg-none py-0">
                <Nav className="ebw-header__menu-btm justify-content-between position-fixed">
                    {mnavs.map((mnav, index) => (
                        <NavItem key={index} className="ebw-header__menu-tabs">
                            <NavLink exact to={mnav.url} className="ebw-header__menu-tab">
                                <div className="ebw-header__menu-tab--img d-flex flex-column">
                                    {/*<img src={mnav.icon} alt={mnav.title} className="img-fluid d-block mx-auto" /> */}
                                    <span className={"mx-auto ebw-icon ebw-icon--xl icon-" + mnav.icon}></span>
                                    <div className="ebw-header__menu-tab--title text-sm pt-1">{mnav.title}</div>
                                </div>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </Navbar>
        </>
    );
}