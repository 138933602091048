import { call, put, takeEvery } from 'redux-saga/effects'
import storeBusiness from '../reducers/storeBusiness';




function* storeData(action) {
    if (action.data) {
        yield put({ type: 'SET_AVAILABLE_PROJECTS_DATA_SUCCESS', data: action.data });
    } else {
        yield put({ type: 'SET_AVAILABLE_PROJECTS_DATA_FAILED', message: "no data" });
    }
}

function* storeAvailableProjectsSaga() {
    yield takeEvery('SET_AVAILABLE_PROJECTS_DATA_REQUESTED', storeData);
}

export default storeAvailableProjectsSaga;