import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import Main from '../../layout/main';
import PaginationCmn from "../../components/common/pagination";
import Loader from "../../common/loader";
const Notification = ({ notificationData, notificationLoading }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
    const getLink = (taskName, data) => {
        let link = "";
        if (taskName == "A Provider is interested in your project") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "New Project Available for Bidding") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "You have a New Lead for Purchase") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "Project Updates") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "You are Hired!") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "ProjectAwarded") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "Project Reviewed") {
            link = "/review"
        } else if (taskName == "Pro+ Upgrade") {
            link = "/profile"
        }
        return link;
    }
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 9;
    const applyPaginationFilter = (currentData, activePage,) => {
        const last = activePage * itemsPerPage;
        const first = (activePage - 1) * itemsPerPage;
        return currentData && currentData.length != 0 && currentData.slice(first, last);
    }
    const notificationDataPage = notificationData ? applyPaginationFilter(notificationData.activities, activePage) : [];

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Notification</title>
            </Helmet>

            <Main additionalClass_main="profile pt-12 mt-12 mb-12">
                {notificationLoading ?
                    <div className="ebw-data__loader">
                        <Loader loading={notificationLoading} />
                    </div>

                    :

                    <section className="ebw-notification">
                        <Container className="ebw-notification__inwrap">
                            <Row className="ebw-notification__row mt-md-7"  sm="12" lg="9">
                                <Col className="ebw-notification__row-wrap mt-3 mb-3"  ><div className="ebw-title__primary pl-3">Notifications</div></Col>
                            </Row>
                            {!notificationDataPage &&
                                <div className="ebw-notification__empty text-center">
                                    <div><img src="/images/notification/no-notification.svg" /></div>
                                    <div className="ebw-notification__empty-content mt-3">
                                        <div className="ebw-notification__empty-title">No Notification right now!</div>
                                    </div>
                                </div>}
                            {notificationDataPage && notificationDataPage.length == 0 ?
                                <div className="ebw-notification__empty text-center">
                                    <div><img src="/images/notification/no-notification.svg" /></div>
                                    <div className="ebw-notification__empty-content mt-3">
                                        <div className="ebw-notification__empty-title">No Notification right now!</div>
                                    </div>
                                </div> :

                                <Row className="ebw-notification__row mt-md-7" sm="12" lg="9">
                                    <Col>
                                        <div className="ebw-notification__row-wrap">
                                            {notificationDataPage && notificationDataPage.length != 0 && notificationDataPage.map((notifications, index) => {
                                                return (

                                                    <div key={index} className="ebw-notification__new ebw-card mb-3 card ebw-card ebw-card__quaternary cursor-pointer p-0">
                                                        <Link className="ebw-button__plain--blue" to={getLink(notifications.task_name, notifications)} >
                                                            <div className="ebw-notification__project d-flex align-items-center">
                                                                <div className="card-body ebw-notification__project-content w-100 d-flex flex-wrap justify-content-between pl-3 pr-3">
                                                                    <div className="ebw-notification__project-left">
                                                                        <div className="ebw-notification__project-heading">{notifications.task_name}</div>
                                                                        <div className="ebw-notification__project-title mb-1">{notifications.notification}</div>
                                                                    </div>
                                                                    <div className="ebw-notification__project-right text-right pl-2">
                                                                        <div className="ebw-notification__new-date">{notifications.created_at}</div>
                                                                        <div className="ebw-notification__project-time mt-1">{notifications.time}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                )
                                            })}
                                            <div className="text-right">
                                                <PaginationCmn
                                                    activePage={activePage}
                                                    itemsPerPage={itemsPerPage}
                                                    totalItems={notificationData && notificationData.activities && notificationData.activities.length}
                                                    handlePaginationChange={(data) => setActivePage(data)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    </section>
                }
            </Main>
        </div>
    );
}

export default Notification;