import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
// import {toastr} from 'react-redux-toastr'
import axios from 'axios';


async function getApi(data) {
    const apiUrl = API.baseURL + `/messages/get_provider_chat?provider_id=${data.provider_id}&project_id=${data.project_id}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* getChat(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        yield put({ type: 'GET_CHAT_DATA_SUCCESS', data: response.data.data.provider_chat });
    } catch (e) {
        yield put({ type: 'GET_CHAT_DATA_FAILED', message: e.message });
    }
}

function* getChatSaga() {
    yield takeEvery('GET_CHAT_DATA_REQUESTED', getChat);
}

export default getChatSaga;