import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import API from '../../API';
import './BidDetailsAsideThree.css'
import LoaderModal from '../loaders/LoaderModal';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactedBidDetails = (props) => {
    const {
        customer_email,
        project_id,
        consumer_mobile,
        consumer_name,
        full_address,
        consumer_avatar_url,
        can_provider_contact_customer,
        refund_status,
        discounted_price

    } = props;


    const [modal, setModal] = useState(false);
    const [paid, setPaid] = useState(true);
    const ProfileData = useSelector(state => state.getProfileEditData.profileEdit);
    console.log("ProfileDataProfileDataProfileData", refund_status);

    //NEW CODE
    const [purchaseLead, setpurchaseLead] = useState(false);
    const [purchaseLeadSuccess, setpurchaseLeadSuccess] = useState(false);
    const [walletDetails, setwalletDetails] = useState({})
    const [walletHistoryDetails, setwalletHistoryDetails] = useState({})
    const [refundPopup, setrefundPopup] = useState(false);
    const [reason, setreason] = useState("");
    const [refundSuccess, setrefundSuccess] = useState(false);
    const [loadingShow, setloadingShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastText, settoastText] = useState('');

    useEffect(() => {
        axios.get(API.baseURL + `/v2/providers/wallets`,API.options).then(response => response.data).then(res => {
            console.log('resresres',res);
            setwalletDetails(res)
        }).catch((err)=>{
            console.log(err)
        })
        axios.get(API.baseURL + `/v2/providers/wallet_histories/${project_id}`,API.options).then(response => response.data).then(res => {
            console.log('resresresHistory',res);
            setwalletHistoryDetails(res)
        }).catch((err)=>{
            console.log(err)
        })
    }, [project_id])

    //New Code

    const toggle = () => setModal(!modal);
    const checkFreePaid = (event) => {
        event.preventDefault();
        axios
            .get(API.baseURL + `/projects/get_paid_free_projects?project_id=${project_id}&provider_project_id=${id}`, API.options)
            .then(response => response.data.data)
            .then(res => {
                // this.setState({ advertisements: res });
                if (res && res.paid_free == "paid") {
                    window.location.href = `/lead-payment/${id}/${project_id}`
                } else {
                    setPaid(false);
                }
            }).catch((error) => {
                // if (error.response.status === 401)
                // window.location.href = `/logout`
            });
    }
    const refreshThePage = (event) => {
        window.location.reload();
    }
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


    const showToastMessage = () => {
        setShowToast(true);
        toast.info(toastText, {
            position: "bottom", // Same as IonToast position
            autoClose: 2000, // Same duration
            onClose: () => setShowToast(false), // Mimics onDidDismiss
            closeButton: ({ closeToast }) => (
                <button onClick={() => setShowToast(false)}>Close</button>
            ),
        });
    };

    return (
        <>

            {/* New Code */}
            {!can_provider_contact_customer && (
                <div>
                    {/* <div className="es-card__title es-card__title-medium es-project__summary-title mb-4" >CUSTOMER DETAILS</div> */}
                    <div className="lead-card">
                        <div className="lead-header" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', borderBottom: '1px solid #eee'}}>
                            <div className="profile">
                                <img className="profile-img" style={{ heigh: '40px', width: '40px', borderRadius: '50%', marginRight: '8px'}} src={(consumer_avatar_url && consumer_avatar_url.length > 0) ? consumer_avatar_url : "./assets/images/card/default-card-img-a.png"} alt="Profile" />
                                <span className="profile-name">{consumer_name}</span>
                            </div>
                            <div className="lead-price" style={{textAlign: 'right',}}>
                                <span className="price">${discounted_price ?? '0'}</span>
                                <span className="price-label" style={{display: 'block', color: '#666', fontSize: '12px'}}>Lead Price</span>
                            </div>
                        </div>
                        <div className="lead-body">
                            <p style={{marginTop: '10px'}}>
                                Easy Bids guarantees accurate phone numbers with a money-back promise, and limits the service professionals contacting customers.
                            </p>
                        </div>
                        <div className="lead-footer" style={{
                            textAlign: 'center',
                            padding: '16px',
                        }}>
                            <button className="purchase-button" style={{
                                backgroundColor: '#3D84FB',
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                width: '100%',
                                alignItems: 'center',
                                'justify-content': 'center',
                            }} 
                            onClick={() => {
                                if (refund_status !== 'refunded') {
                                    setpurchaseLead(true)
                                }
                                else {
                                    alert('Unable to process lead as you already refund this lead')
                                }
                            }}>
                                <span className="button-icon">$</span>
                                PURCHASE LEAD
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {refund_status !== 'refund_applied' && can_provider_contact_customer && (
                <div style={{}}>
                    <div className="card p-3">
                        <div className="es-card__title es-card__title-medium es-project__summary-title mb-4 font-weight-bold">CUSTOMER DETAILS</div>
                        <div className="card-header" style={{ borderBottom: '1px solid lightGrey', paddingBottom: 15 }}>
                            <div className="profile-pic">
                                <img src={(consumer_avatar_url && consumer_avatar_url.length > 0) ? consumer_avatar_url : "./assets/images/card/default-card-img-a.png"} alt="Profile Picture" />
                            </div>
                            <div className="profile-info">
                                <h2 style={{ marginLeft: -5 }}>{consumer_name}</h2>
                                {/* <a style={{ marginTop: 5, marginLeft: -5 }} href="#">View Profile</a> */}
                            </div>
                            <div>
                                <div className="card-price">${discounted_price ?? '0'}</div>
                                <div className="card-status" style={{ marginTop: 8 }}>Purchased</div>
                            </div>
                        </div>
                        <div className="card-details">
                            <p>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.58685 5.90223C6.05085 6.86865 6.68337 7.77441 7.48443 8.57546C8.28548 9.37651 9.19124 10.009 10.1577 10.473C10.2408 10.5129 10.2823 10.5329 10.3349 10.5482C10.5218 10.6027 10.7513 10.5636 10.9096 10.4502C10.9542 10.4183 10.9923 10.3802 11.0685 10.304C11.3016 10.071 11.4181 9.95443 11.5353 9.87824C11.9772 9.59091 12.5469 9.59091 12.9889 9.87824C13.106 9.95443 13.2226 10.071 13.4556 10.304L13.5856 10.4339C13.9398 10.7882 14.117 10.9654 14.2132 11.1556C14.4046 11.534 14.4046 11.9809 14.2132 12.3592C14.117 12.5495 13.9399 12.7266 13.5856 13.0809L13.4805 13.186C13.1274 13.5391 12.9508 13.7156 12.7108 13.8505C12.4445 14.0001 12.0308 14.1077 11.7253 14.1068C11.45 14.1059 11.2619 14.0525 10.8856 13.9457C8.86333 13.3718 6.95509 12.2888 5.36311 10.6968C3.77112 9.10479 2.68814 7.19655 2.11416 5.17429C2.00735 4.79799 1.95395 4.60984 1.95313 4.33455C1.95222 4.02906 2.0598 3.6154 2.20941 3.34907C2.34424 3.10904 2.52078 2.9325 2.87386 2.57942L2.97895 2.47433C3.33325 2.12004 3.5104 1.94289 3.70065 1.84666C4.07903 1.65528 4.52587 1.65528 4.90424 1.84666C5.0945 1.94289 5.27164 2.12004 5.62594 2.47433L5.75585 2.60424C5.98892 2.83732 6.10546 2.95385 6.18165 3.07104C6.46898 3.51296 6.46898 4.08268 6.18165 4.52461C6.10546 4.6418 5.98892 4.75833 5.75585 4.9914C5.67964 5.06761 5.64154 5.10571 5.60965 5.15026C5.4963 5.30854 5.45717 5.53805 5.51165 5.72495C5.52698 5.77754 5.54694 5.81911 5.58685 5.90223Z" stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {consumer_mobile}
                            </p>
                            <p>
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 8.66659C8.10457 8.66659 9 7.77115 9 6.66659C9 5.56202 8.10457 4.66659 7 4.66659C5.89543 4.66659 5 5.56202 5 6.66659C5 7.77115 5.89543 8.66659 7 8.66659Z" stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7 14.6666C9.66666 11.9999 12.3333 9.6121 12.3333 6.66659C12.3333 3.72107 9.94552 1.33325 7 1.33325C4.05448 1.33325 1.66666 3.72107 1.66666 6.66659C1.66666 9.6121 4.33333 11.9999 7 14.6666Z" stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {full_address}
                            </p>
                        </div>
                        {/* <a href={`tel:${consumer_mobile}`} className="call-button" style={{ marginTop: 10 }}>CALL CUSTOMER</a> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                            <a
                                href={`tel:${consumer_mobile}`}
                                className="call-button"
                                style={{
                                width: '50%',
                                backgroundColor: '#2196f3',
                                color: '#fff',
                                textAlign: 'center',
                                padding: '10px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                marginRight: '5px', // Space between buttons
                                }}
                            >
                                CALL NOW
                            </a>
                            {/* <a
                                href={`sms:${consumer_mobile}`}
                                className="call-button"
                                style={{
                                width: customer_email?'25%':'50%',
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                textAlign: 'center',
                                padding: '10px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                marginRight: '5px', // Space between buttons
                                }}
                            >
                                SMS
                            </a> */}
                            {customer_email && (
                                <a
                                    href={`mailto:${customer_email}`}
                                    className="call-button"
                                    style={{
                                    width: '50%',
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    textAlign: 'center',
                                    padding: '10px',
                                    textDecoration: 'none',
                                    borderRadius: '5px',
                                    }}
                                >
                                    EMAIL
                                </a>
                            )}
                        </div>
                        <div className="refund-link">
                            Issues with the lead? <a href="javascript:void(0)" onClick={() => {
                                setrefundPopup(true)
                            }}>Request a Refund</a>
                        </div>
                    </div>
                </div>
            )}
            {refund_status === 'refund_applied' && (
                <div style={{}}>
                    <div className="card-refund">
                        <div className="es-card__title es-card__title-medium es-project__summary-title mb-4 font-weight-bold">CUSTOMER DETAILS</div>
                        <div className="card-header-refund">
                            <div className="profile-info-refund">
                                <div className="profile-pic-refund">
                                    <img src={(consumer_avatar_url && consumer_avatar_url.length > 0) ? consumer_avatar_url : "./assets/images/card/default-card-img-a.png"} alt="John Doe" />
                                </div>
                                <div className="profile-text-refund">
                                    <h2>{consumer_name}</h2>
                                    {/* <a href="javascript:void(0)">View Profile</a> */}
                                </div>
                            </div>
                            <div className="status-badge-refund">Refund Requested</div>
                        </div>
                        <div className="card-description-refund">
                            Easy Bids guarantees accurate phone numbers with a money-back promise and limits the service professionals contacting customers.
                        </div>
                        {/* <a href={`tel:${consumer_mobile}`} className="call-button-refund">
                            CALL CUSTOMER
                        </a> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                            <a
                                href={`tel:${consumer_mobile}`}
                                className="call-button"
                                style={{
                                width: '50%',
                                backgroundColor: '#2196f3',
                                color: '#fff',
                                textAlign: 'center',
                                padding: '10px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                marginRight: '5px', // Space between buttons
                                }}
                            >
                                CALL NOW
                            </a>
                            {/* <a
                                href={`sms:${consumer_mobile}`}
                                className="call-button"
                                style={{
                                width: customer_email?'25%':'50%',
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                textAlign: 'center',
                                padding: '10px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                marginRight: '5px', // Space between buttons
                                }}
                            >
                                SMS
                            </a> */}
                            {customer_email && (
                                <a
                                    href={`mailto:${customer_email}`}
                                    className="call-button"
                                    style={{
                                    width: '50%',
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    textAlign: 'center',
                                    padding: '10px',
                                    textDecoration: 'none',
                                    borderRadius: '5px',
                                    }}
                                >
                                    EMAIL
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <Modal isOpen={purchaseLead} toggle={()=>setpurchaseLead(false)}>
                <div className="purchase-card">
                    <div className="purchase-title">Want to purchase this lead?</div>
                    <div className="purchase-description">This will give you direct access to the customer's contact details.</div>
                    <div className="price-row">
                        <span className="price-label">Lead Price</span>
                        <span className="price-value">${discounted_price ?? '0'}</span>
                    </div>
                    <div className="price-row">
                        <span className="price-label">Available Funds</span>
                        <span className="price-value" style={{ color: (walletDetails?.amount < discounted_price) ? '#B65738' : '#333' }}>${walletDetails?.amount ?? '0'}</span>
                        <div style={{ width: '100%' }}>
                            {walletDetails?.amount < discounted_price && (
                                <span style={{ color: '#B65738', fontSize: 12 }}>Insufficient funds</span>
                            )}
                        </div>
                    </div>
                    <button className="confirm-button" onClick={async () => {
                        if (walletDetails?.amount < discounted_price) {
                            window.location.href = (`/wallet-history`)
                        }
                        else {
                            setloadingShow(true)
                            const data = JSON.stringify({
                                payment_type: "debit",
                                category: "Lead Contact Purchase",
                                project_id: project_id,
                                user_id: ProfileData?.user_id
                            });
                            axios.post(API.baseURL + "/v2/providers/wallets/update", data, API.options).then(async (ress) => {
                                const data = JSON.stringify({
                                    payment_type: "debit",
                                    category: "Lead Contact Purchase",
                                    project_id: project_id,
                                    wallet_history_id: ress?.data?.history?.id,
                                    user_id: ProfileData?.user_id
                                });
                                axios.post(API.baseURL + `/v2/providers/projects/${project_id}/lead_purchased`, data, API.options).then(() => {
                                    setpurchaseLead(false)
                                    setTimeout(() => {
                                        setpurchaseLeadSuccess(true);
                                    }, 500);
                                    setloadingShow(false)
                                }).catch(() => {
                                    setShowToast(true)
                                    settoastText('Lead Purchased failed')
                                    setloadingShow(false)
                                })
                            })
                        }
                    }}>
                        {(walletDetails?.amount < discounted_price) ? 'ADD FUNDS TO WALLET' : 'CONFIRM PURCHASE'}
                    </button>
                </div>
            </Modal>
            
            <Modal isOpen={purchaseLeadSuccess} toggle={()=>setpurchaseLeadSuccess(false)}>
                <div className="card-popup">
                    <div className="icon-container-popup">
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="52" height="52" rx="26" fill="#21A145" fill-opacity="0.1" />
                            <path d="M35.3334 19L22.5 31.8333L16.6667 26" stroke="#21A145" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className="card-title-popup">Lead Purchased Successfully</div>
                    <div className="card-description-popup">
                        Congratulations on successfully obtaining the contact details
                    </div>
                    <a href="/projects/all/1" className="button-popup">DONE</a>
                </div>
            </Modal>

            <Modal isOpen={refundPopup} toggle={()=>setrefundPopup(false)}>
            <div className="card-refund">
                    <div className="title-refund">REQUEST REFUND</div>
                    <div className="textarea-container">
                        <label for="description" className="textarea-label">Reason</label>
                        <textarea id="description" className="styled-textarea" placeholder="Please specify the reason for requesting a refund." 
                        onChange={(e) => {setreason(e.target.value)}}></textarea>
                    </div>
                    <button className="button-refund" onClick={async()=>{
                            setloadingShow(true)
                            let data = JSON.stringify({
                                    user_id: ProfileData?.user_id,
                                    wallet_history_id: walletHistoryDetails.id,
                                    reason: reason,
                                    amount: discounted_price
                            }) 
                            axios.post(API.baseURL + `/v2/providers/refunds`, data, API.options).then((result) => {
                                if (result.status === 200){
                                    setrefundSuccess(true)
                                }
                                else{
                                    setShowToast(true)
                                    settoastText('Refund Request Failed')
                                }
                                setloadingShow(false)
                            }).catch((err)=>{
                                setloadingShow(false)
                            });
                    }}>SUBMIT</button>
                </div>
            </Modal>

            <Modal isOpen={refundSuccess} toggle={()=>setrefundSuccess(false)}>
            <div className="card-popup">
                    <div className="icon-container-popup">
                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="52" height="52" rx="26" fill="#21A145" fill-opacity="0.1"/>
                        <path d="M35.3334 19L22.5 31.8333L16.6667 26" stroke="#21A145" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    <div className="card-title-popup">Request Submitted</div>
                    <div className="card-description-popup">
                    Our team will review the details and get back to you shortly.
                    </div>
                    <a href="/projects/all/1" className="button-popup">DONE</a>
                </div>
            </Modal>

            { loadingShow &&
                <LoaderModal loadingShow = {loadingShow} message= {'Processing...'} />
            }

            {/* <button onClick={showToastMessage}>Show Toast</button> */}
            <ToastContainer />
            {/* New Code */}

        </>
    );
}

export default ContactedBidDetails;