import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ElementsConsumer,Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-modal";
// // SVGs
import plusIcon from "../../assets/svgs/plus.svg";
import creditCardIcon from "../../assets/svgs/credit-card-edit.svg";
import arrowDownIcon from "../../assets/svgs/arrow-narrow-down.svg";
import arrowUpIcon from "../../assets/svgs/arrow-narrow-down (1).svg";
import API from "../../API";
import ManageCardsModal from "./manage-cards";
import AddBalanceForm from "./add-balance";
import AddCards from "./payment-form";
import { Container, Row, Col } from 'reactstrap';
const stripePromise = loadStripe(API.stripeId);
Modal.setAppElement("#root");
const WalletHistory = () => {
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [walletAmount, setWalletAmount] = useState(null);
  const [showAddBalance, setShowAddBalance] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showManageCards, setShowManageCards] = useState(false);
  const [availableCards, setAvailableCards] = useState([]);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [cardDeletionId, setCardDeletionId] = useState(null);
  const [loadingShow, setLoadingShow] = useState(false);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);

  const layoutContent = useRef();
  const handleOpenModal = () => setShowAddBalance(true);
  const handleCloseModal = () => setShowAddBalance(false);

  useEffect(() => {
    // fetchData();
    fetchWalletAmount();
    fetchAvailableCards();
  }, []);

  const fetchAvailableCards = async () => {
    try {
      const response = await axios.get(
        `${API.baseURL}/providers/wallet_get_provider_card_details`,
        API.options
      );
      if (response.data.code === 200) {
        setAvailableCards(response.data.data.stripe_card_details || []);
      }
    } catch (error) {
      console.error("Failed to fetch available cards:", error);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${API.baseURL}/v2/providers/wallet_histories`, API.options);
  //     setData(response.data);
  //     setPageData(response.data.slice(0, 10));
  //     setPageCount(Math.ceil(response.data.length / 10));
  //   } catch (error) {
  //     console.error("Failed to fetch wallet history:", error);
  //   }
  // };

  const fetchWalletAmount = async () => {
    try {
      setIsTransactionsLoading(true);
      const response = await axios.get(
        `${API.baseURL}/v2/providers/wallets?page=${currentPage}&per_page=${perPage}`, 
        API.options
      );
      console.log("response", response);
      const walletData = response.data;
      setData(walletData?.transactions);
      setWalletAmount(response.data.amount);
      setTotalPages(response.data.total_pages); // Assuming the API returns total_pages
    } catch (error) {
      console.error("Failed to fetch wallet amount:", error);
    } finally {
      setIsTransactionsLoading(false);
    }
  };

  // Add useEffect to refetch when page changes
  useEffect(() => {
    fetchWalletAmount();
  }, [currentPage]);

  // Add pagination controls handler
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * 10;
    setPageData(data.slice(offset, offset + 10));
    setCurrentPage(selectedPage);
    if (layoutContent.current) {
      layoutContent.current.scrollTo(0, 0);
    }
  };

  const renderAddBalanceForm = () => (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Modal
            isOpen={showAddBalance}
            onRequestClose={handleCloseModal}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "400px",
                maxHeight: "90vh",
                overflow: "auto",
                borderRadius: "10px",
                padding: "20px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <AddBalanceForm
              stripe={stripe}
              elements={elements}
              onSuccess={() => {
                fetchWalletAmount()
                handleCloseModal();
              }}
            />
          </Modal>
        )}
      </ElementsConsumer>
    </Elements>
  );

  const deleteCardHandler = async () => {
    setLoadingShow(true)
    try {
      await axios.delete(`${API.baseURL}/providers/destroy_provider_card`, {
        ...API.options,
        data: { payment_method_id: cardDeletionId },
      });
      setDeleteConfirmationPopup(false);
      setLoadingShow(false)
      fetchAvailableCards();
    } catch (error) {
      setLoadingShow(false)
      console.error("Failed to delete card:", error);
    }
  };

  const formatDate = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffTime = Math.abs(now - date); // Difference in milliseconds
  
    const diffSeconds = Math.floor(diffTime / 1000); // Difference in seconds
    const diffMinutes = Math.floor(diffTime / (1000 * 60)); // Difference in minutes
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60)); // Difference in hours
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Difference in days
  
    if (diffSeconds < 60) {
      return diffSeconds === 1 ? "1 second ago" : `${diffSeconds} seconds ago`;
    } else if (diffMinutes < 60) {
      return diffMinutes === 1 ? "1 minute ago" : `${diffMinutes} minutes ago`;
    } else if (diffHours < 24) {
      return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
    } else if (diffDays < 1) {
      return diffDays === 1 ? "1 day ago" : `${diffDays} days ago`;
    } else {
      // Format for dates more than 1 day ago
      const options = { hour: "numeric", minute: "numeric", hour12: true }; // Time format
      const timeString = date.toLocaleTimeString("en-US", options);
      
      // Get the date with ordinal suffix
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "short" });
      const year = date.getFullYear();
      const ordinalSuffix = (n) =>
        ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";
      
      return `${timeString} - ${day}${ordinalSuffix(day)} ${month} ${year}`;
    }
  };
  const renderAddCardForm = () => {
    return (
      <AddCards onSuccess={fetchAvailableCards} setShowAddCard= {setShowAddCard} />
    );
  }
  return (
    <Container>
      <Row className="ebw-notification__row d-flex justify-content-center align-items-center"
        style={{ marginTop: '100px' }}>
        <Col sm="6"> 
          <div>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center',justifyContent:'space-between'}}>
              {/* Available Amount Card */}
              <div style={{ marginTop: '2%', width: '100%' }}>
                <div
                  style={{
                    position: 'relative',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '12px',
                    padding: '16px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <div>
                    <h2
                      style={{
                        fontWeight: 'bold',
                        color: '#171717',
                        fontSize: '32px',
                        marginLeft: '10px',
                        margin: 0,
                      }}
                    >
                      ${walletAmount}
                    </h2>
                    <p
                      style={{
                        color: '#B2B2B2',
                        marginLeft: '10px',
                        fontSize: '12px',
                        margin: 0,
                      }}
                    >
                      Current Balance
                    </p>
                  </div>
                </div>
              </div>
              {/* Buttons Container */}
            </div>
          <div
            style={{
              marginTop: '2%',
              display: 'flex',
              flexDirection: availableCards.length !== 0 ? 'row' : 'column', // Row if both buttons, column if one
              justifyContent: 'space-between',
              width: '100%',
              gap: availableCards.length !== 0 ? '8px' : '0px', // Add gap when in row layout
            }}
          >
            {/* Add Funds Button */}
            <button
              style={{
                backgroundColor: '#3D84FB',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                width: availableCards.length !== 0 ? '50%' : '100%', // 50% width if both buttons exist, else full width
              }}
              onClick={() => {
                if (availableCards.length === 0) {
                  setShowAddCard(true);
                } else {
                  setShowAddBalance(true);
                }
              }}
            >
              <img src={plusIcon} alt="My Icon" width="16" height="16" />
              <span
                style={{
                  marginLeft: '5px',
                  fontSize: '12px',
                  lineHeight: '1',
                  textTransform: 'none',
                }}
              >
                {availableCards.length === 0 ? 'Add Card' : 'Add Funds'}
              </span>
            </button>

            {/* Manage Cards Button */}
            {availableCards.length !== 0 && (
              <button
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  cursor: 'pointer',
                  width: '50%', // 50% width when both buttons exist
                }}
                onClick={() => setShowManageCards(true)}
              >
                <img
                  src={creditCardIcon}
                  alt="Credit Card"
                  width="16"
                  height="16"
                  style={{ marginRight: '5px' }}
                />
                <span
                  style={{
                    fontSize: '12px',
                    lineHeight: '1',
                    textTransform: 'none',
                  }}
                >
                  Manage Cards
                </span>
              </button>
            )}
          </div>

            <p
              style={{
                marginTop: '10px',
                // marginLeft: '20px',
                fontWeight: 'bold',
                color: '#1E1E1E',
                fontSize: '16px',
              }}
            >
              Transaction History
            </p>
          <div style={{ background: '#fff', borderRadius: 5, margin: '10px 0px' }}>
            {isTransactionsLoading ? (
              <div style={{ padding: '20px', textAlign: 'center' }}>
                Loading transactions...
              </div>
            ) : data.length === 0 ? (
              <div style={{ padding: '20px', textAlign: 'center', color: '#666' }}>
                No transactions to show
              </div>
            ) : (
              data.slice().reverse().map((eachTx) => {
                return (
                  console.log("eachTxeachTxeachTxeachTx", eachTx),
                  <div style={{ paddingTop: '15px', paddingBottom: '15px', borderBottom: '1px solid lightgrey', marginLeft: '20px', marginRight: '20px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center',position: 'relative',  }}>

                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                        <div style={{ width: '40px', height: '40px', backgroundColor: eachTx?.payment_type == 'debit' ? 'rgba(182, 87, 56, 0.1)' : 'rgba(33, 161, 69, 0.1)', borderRadius: '50%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <img src={eachTx?.payment_type == 'debit' ? arrowUpIcon : arrowDownIcon} alt="Credit Card" width="16" height="16"
                            style={{
                              display: 'flex',
                            }}
                          />
                        </div>
                      </div>


                      <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', justifyContent: 'center', marginLeft: '15px', }}>
                        <span style={{ fontSize: '14px', fontWeight: '400', color: '#171717', marginBottom: '2px', width: '90%', }}>{eachTx?.label_history ?? eachTx?.card}</span>
                        <span style={{ fontSize: '12px', color: '#666', marginTop: '5px', }}>{formatDate(eachTx?.updated_at)}</span>
                      </div>


                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'absolute', right: 20, paddingLeft: 20 }}>
                        <span style={{ fontSize: '14px', fontWeight: '600', color: '#000', textAlign: 'left' }}>${parseFloat(eachTx?.amount ?? 0)?.toFixed(2)}</span>
                        <span style={{ fontSize: '12px', color: eachTx?.payment_type == 'debit' ? '#B65738' : '#21A145', marginTop: '5px', textAlign: 'right' }}>{eachTx?.payment_type == 'debit' ? 'Used' : 'Added'}</span>
                      </div>
                    </div>
                  </div>
                )
              })
            )}
            
            {/* Add pagination controls */}
            {/* {!isTransactionsLoading && data.length > 0 && (
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                padding: '20px',
                gap: '10px'
              }}>
                <button 
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
                  }}
                >
                  Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button 
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    cursor: currentPage === totalPages ? 'not-allowed' : 'pointer'
                  }}
                >
                  Next
                </button>
              </div>
            )} */}
          </div>
            {showAddBalance && (
                <div style={{ width: 500, height: 500 }}>
                  {showAddBalance && renderAddBalanceForm()}
                </div>
              )}

              {showAddCard && (
                <div style={{ width: 500, height: 500 }}>
                  {showAddCard && renderAddCardForm()}
                </div>
              )}

              {showManageCards && (
                <div style={{ width: 500, height: 500 }}>
                  {showManageCards && <ManageCardsModal
                      showManageCards={showManageCards}
                      setShowManageCards={setShowManageCards}
                      availableCards={availableCards}
                      setCardDeletionId={setCardDeletionId}
                      setDeleteConfirmationPopup={setDeleteConfirmationPopup}
                      deleteCardHandler={deleteCardHandler}
                      fetchAvailableCards={fetchAvailableCards}
                    />}
                </div>
              )}
          </div>
       </Col>
      </Row>
    </Container>
  );
};



export default WalletHistory;

