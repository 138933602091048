import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import API from "../../API";
import { useSelector } from 'react-redux';
const stripePromise = loadStripe(API.stripeId);
const ProCheckoutForm = (props) => {
    const ProfileData = useSelector(state => state.getProfileEditData.profileEdit);
    const stripe = useStripe();
    const elements = useElements();
    const [state, setState] = useState({
        serviceAmount: null,
        availableCards: [],
        addNewCard: false,
        provider_project_id: null,
        selectedCardId: null,
        client_payment_intent_key: null,
        preLoading: false,
        error: "",
        provider_name: "",
        loadingShow: false,
        provider_id: null,
        paymentSuccess: false,
    });
  const [walletAmount, setWalletAmount] = useState(null);

    const geStripecardDetails = (data) => {
        if (data) {
            return [...data]
        } else {
            return []
        }
    }

    const initiateProPayment = (res) => {
        setState((prevProps) => ({
            ...prevProps,
            serviceAmount: res.data.service_amount,
            // provider_id: res.data.provider_id,
            availableCards: geStripecardDetails(res.data.stripe_card_details),
            provider_name: res.data.provider_name,
            addNewCard: res.data.stripe_card_details.length == 0,
            // provider_project_id: res.data.provider_project_id,
            selectedCardId: res.data.stripe_card_details && res.data.stripe_card_details.length != 0 ? res.data.stripe_card_details[0].id : "",
        }));
        const data = JSON.stringify({
            provider_id: props.provider_id,
        });
    }

    const getCardDetails = () => {
        axios
            .get(
                API.baseURL +
                `/providers/wallet_get_provider_card_details`,
                API.options
            ).then(response => response.data)
            .then(res => {
                if (res.data.paid == true) {
                    window.location = "/dashboard";
                } 
                initiateProPayment(res);
            })
            .catch(err => {});
    }

    useEffect(() => {
        getCardDetails();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) { return; }
        const cardElement = elements.getElement(CardElement);
        if (state.addNewCard) {
            if (elements.getElement(CardElement)._empty) {
                setState((prevProps) => ({
                    ...prevProps,
                    error: "Enter the card details",
                }));
                return
            };

            setState((prevProps) => ({
                ...prevProps,
                loadingShow: true, stripe: false
            }));
            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: localStorage.getItem("username"),
                },
            });
            if (paymentMethod.error) {
                setState((prevProps) => ({
                    ...prevProps,
                    error: paymentMethod.error.message,
                    loadingShow: false,
                }));
                return;
            }
            confirmPayment(paymentMethod.paymentMethod.id)
        } else {
            setState((prevProps) => ({
                ...prevProps,
                loadingShow: true, stripe: false
            }));
            confirmPayment(state.selectedCardId )
        }

    }

    const confirmPayment = async (id) => {
        setState((prevProps) => ({
            ...prevProps,
            loadingShow: true
        }));
        const data = {
            payment_method: id,
            user_id: ProfileData?.id,
            plan:props.plan
        };
        try {
            const response = await axios.post(API.baseURL + `/v2/providers/upgrade_to_pro`, data, API.options);
            if (response.status === 200) {
                setState((prevProps) => ({
                    ...prevProps,
                    paymentSuccess: true,
                    loadingShow: false
                }));
            }
            else{
                setState((prevProps) => ({
                    ...prevProps,
                    error: response.data.error || "Payment confirmation failed",
                    loadingShow: false,
                }));
            }
        } catch (error) { 
            setState((prevProps) => ({
                ...prevProps,
                error: error.response.data.error || "Payment confirmation failed",
                loadingShow: false,
            }));
        }
      };

    const checkRadio = (event) => {
        // event.preventDefault()
        setState((prevProps) => ({
            ...prevProps,
            selectedCardId: event.target.value,
            addNewCard: false,
            error: "",
        }));
    };
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };
    const redirectToReview = () => {
        window.location = "/dashboard";
    }


    const fetchWalletAmount = async () => {
        try {
            const response = await axios.get(API.baseURL + `/v2/providers/wallets`, API.options);
            const walletData = response.data;
            console.log("walletData.amount", walletData.amount);
            setWalletAmount(parseFloat(walletData.amount));
            
        } catch (err) {
            console.error("Failed to fetch wallet amount", err);
        }
      };
    
      useEffect(() => {
        fetchWalletAmount();
      }, []);
    return (
        <>
            <form className="ebw-paymentn__form w-100" onSubmit={handleSubmit}>
            {(walletAmount< (props?.plan ==='yearly'? 999:99) && state.availableCards.length===0) && (
                <>
                    <div className="ebw-paymentn__card-new mb-3"><CardElement options={CARD_ELEMENT_OPTIONS} /></div>
                    <button className="ebw-button ebw-button__primary btn btn-secondary w-100 border-radius" type="submit" disabled={!stripe}>
                    {'JOIN PRO+'}
                    </button>
                </>
            )}
            {(walletAmount<(props?.plan ==='yearly'? 999:99) && state.availableCards.length>0) && (
                <>
                    <div className="ebw-paymentn__row">
                        <div className="ebw-paymentn__label mb-2">Saved Payment Methods</div>
                        <div className="">
                            {state.availableCards && state.availableCards.length != 0 && state.availableCards.map((cards, index) => {
                                return (
                                    <div key={index} className="ebw-paymentn__card mb-2">
                                        <input
                                            onChange={(e) => checkRadio(e)}
                                            value={cards.id}
                                            id={cards.id}
                                            className="ebw-paymentn__card-input d-none"
                                            type="radio"
                                            name="card"
                                            checked={state.selectedCardId == cards.id}
                                        />
                                        <label for={cards.id} className="ebw-paymentn__card-inwrap d-flex justify-content-between align-items-center mb-0">
                                            <div className="ebw-paymentn__card-left d-flex">
                                                <div className="ebw-paymentn__checkbox">
                                                </div>
                                                <div className="ebw-paymentn__card-type d-flex align-items-center">
                                                    <div className="ebw-paymentn__card-type--img mr-2">
                                                        <img src={cards.card_icon} alt=""
                                                        />
                                                    </div>
                                                    <div className="ebw-paymentn__card-type--num d-flex m-l">XX<div>{cards.last4_digit.substring(2)}</div></div>
                                                </div>
                                            </div>
                                            <div className="ebw-paymentn__card-right">
                                                <div className="ebw-paymentn__card-mmyy">{`${cards.exp_month}/${cards.exp_year}`}</div>
                                            </div>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <button className="ebw-button ebw-button__primary btn btn-secondary w-100 border-radius" type="submit" disabled={!stripe}>
                    {'JOIN PRO+'}
                    </button>
                </>
            )}
            {state.error ? <div className="ebw-error mb-3 mt-3">{state.error}</div> : ""}
            {(walletAmount>=(props?.plan ==='yearly'? 999:99)) && (
                <button className="ebw-button ebw-button__primary btn btn-secondary w-100 border-radius" type="submit" disabled={!stripe}>
                    {'JOIN PRO+'}
                </button>
            )}
            </form>
            <Modal isOpen={state.paymentSuccess} toggle={() => redirectToReview()}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={() => redirectToReview()}></ModalHeader>
                    <ModalBody className="pt-0">
                        <h3 className="text-4xl mb-4">Payment Success</h3>
                        <div className="ebw-modal__para ebw-modal__para--bold">Thank you for opting PRO verification process. Happy Bidding!.</div>
                        <div className="text-right d-flex justify-content-end pt-3">
                            <Button
                                onClick={() => redirectToReview()}
                                className="ebw-button__primary border-none pt-2 pb-2 pl-4 pr-4">
                                <span>Summary</span>
                            </Button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

// export default ProCheckoutForm;

const ProCheckoutPage = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <ProCheckoutForm plan={props.plan} />
        </Elements>
    );
};
export default ProCheckoutPage;