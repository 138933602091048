import React from 'react';
import { Input } from 'reactstrap';
import { useDropzone } from 'react-dropzone';

import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

SwiperCore.use([Navigation, Autoplay]);



const FileUpload = (props) => {

    const { files, handleChangeFile, removeFileFromList, type, multiple } = props;
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop: items => {
            const fileList = items;
            let file = []
            for (var i = 0; i < fileList.length; i++) {
                if (fileList[i].type.split('/')[0] == "video") {
                    const objectURL = URL.createObjectURL(fileList[i])
                    const upItem = {
                        blob: fileList[i],
                        url: objectURL,
                        type: fileList[i].type,
                        where: "local",
                    };
                    file.push(upItem);
                } else if (fileList[i].type.split('/')[0] == "image") {
                    const objectURL = URL.createObjectURL(fileList[i])
                    const upItem = {
                        blob: fileList[i],
                        url: objectURL,
                        type: fileList[i].type,
                        where: "local",
                    };
                    file.push(upItem);
                } else {
                }
            }
            handleChangeFile(file);
        }
    });
    const checkAcceptable = (type) => {
        let accept = "";
        if (type == "image") {
            accept = "image/*"
        } else if (type == "video") {
            accept = "video/*"
        } else {
            accept = "application"
        }
        return accept;
    }
    return (
        <>
            <div className="ebw-modal__input-wrap mb-4" {...getRootProps({ className: 'dropzone disabled' })}>
                <Input
                    type="file"
                    inputProps={{ accept: "images/*" }}
                    // accept='image/*'
                    multiple={multiple}
                    // {checkAcceptable(type)}
                    {...getInputProps()}
                    id="uploadDP"
                    className="ebw-modal__input d-none"
                />
                <label for="uploadDP" className="ebw-my__uploads-file m-0 w-100">
                    <div className="ebw-my__uploads-inwrap py-6 text-center">
                        <div className="ebw-form__input-file--custom d-flex justify-content-center align-items-center btn btn-secondary">
                            <span className="ebw-form__input-file--text">Choose File</span>
                            {/* <span className="ebw-icon ebw-icon--xl icon-arrow-down"></span> */}
                        </div>
                        <p className="ebw-my__uploads-text pt-3 mb-0">Or drop files here. Max file size 10MB</p>
                    </div>
                </label>
            </div>
            <div className="ebw-my__uploads-items pt-3 px-2">
                <Swiper
                    spaceBetween={15}
                    slidesPerView="auto"
                    className="ebw-swiper__container ebw-swiper__container--full-width pt-2"
                >

                    {files.length !== 0 && files.map((data, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="ebw-my__uploads-item">
                                    <span onClick={() => removeFileFromList(index)} className="ebw-icon ebw-icon--sm icon-close ebw-my__uploads-close"></span>
                                    <div style={{ backgroundImage: `url(${data.type.split('/')[0] == "image" ? data.url : "./images/icons/video-thumb-a.png"})` }} className="ebw-my__uploads-img">
                                        {data.type.split('/')[0] == "image" ? null : <div className="position-relative"><span className="ebw-my__uploads-video"></span></div>}
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                    }
                </Swiper>
            </div>

        </>
    );
}

export default FileUpload;