import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import Main from '../../layout/main';
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { InputText, InputTextNoBorder } from "../../components/inputs/text-input";
import WorksFolder from '../../components/myWorks/worksFolder'
import Buttons from "../../components/buttons/button-primary";
import Loader from "../../common/loader"
import { myWorksNewFolder } from "../../redux/actions/myWorksNewFolder"



const MyWorks = (props) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    const dispatch = useDispatch();
    const { myWorksData, myWorksLoading, myWorksError } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [newFolderName, setnewFolderName] = useState("");

    const createNewFile = (event) => {
        event.preventDefault()
        let data = JSON.stringify({
            album: {
                name: newFolderName,
            }
        })
        if (newFolderName != "") {
            dispatch(myWorksNewFolder(data));
            setModal(false);
            setTimeout(() => {
                setnewFolderName("");
            }, 2000);
        }
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Works</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="my-works">
                {myWorksLoading ?
                    <div className="ebw-data__loader">
                        <Loader loading={myWorksLoading} />
                    </div>
                    :
                    <section className="ebw-section__pages ebw-section--my-works">
                        <div className="ebw-my__work-inner">
                            <Container>
                                <Row>
                                    <Col sm="12">
                                        <div className="ebw-my__work d-flex justify-content-between align-items-center mb-5">
                                            <h1 className="ebw-title__primary mb-0">My Works</h1>
                                            <a role="button" onClick={() => setModal(true)} className="ebw-my__work-album d-flex align-items-center">
                                                <span>New Album</span>
                                                <span className="ebw-icon ebw-icon--lg icon-add ml-3"></span>
                                                {/* <img src="./images/icons/icon-plus.svg" alt="Plus" className="img-fluid d-block ml-4 ml-md-8" /> */}
                                            </a>
                                            <>
                                                <Modal isOpen={modal} toggle={toggle} className="ebw-global__modal">
                                                    <div className="px-5 ebw-modal__wrap">
                                                        <ModalHeader toggle={toggle} className="ebw-global__modal-header modal-title text-capitalize">Create new work folder</ModalHeader>
                                                        <ModalBody className="ebw-modal__body">
                                                            <InputText
                                                                label="Folder name"
                                                                id="add_folder"
                                                                name="add_folder"
                                                                value={newFolderName}
                                                                changeEvent={(e) => setnewFolderName(e.target.value)}

                                                            />
                                                            <Col xs="4" className="ml-auto mt-6 px-0">
                                                                <Buttons clickEvent={(e) => createNewFile(e)} text="Create" type="submit" />
                                                            </Col>

                                                        </ModalBody>
                                                    </div>
                                                </Modal>
                                            </>
                                        </div>
                                    </Col>
                                </Row>
                                {!myWorksData.length ? 
                                    <div className="ebw-notification__empty text-center">
                                        <div><img src="/images/myworks/no-work.svg" /></div>
                                        <div className="ebw-notification__empty-content mt-3">
                                            <div className="ebw-notification__empty-title mb-4">Showcase your work</div>
                                            <div className="ebw-notification__empty-subtitle mt-1">Showcase your expertise through photos and videos of completed projects to stand out from the crowd!</div>
                                        </div>
                                    </div>
                                :
                                <Row className="ebw-my__albums">
                                    {myWorksData && myWorksData.length != 0 && myWorksData.map((data, index) => {
                                        return (<WorksFolder key={index} created_at={data.created_at} id={data.id} name={data.name} provider_id={data.provider_id} updated_at={data.updated_at} />)
                                    })}
                                </Row>
                                }

                            </Container>
                        </div>
                    </section>
                }
            </Main>
        </div>
    );
}
export default MyWorks;