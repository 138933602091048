import React from 'react';
import Pagination from "react-js-pagination";

const PaginationCmn = (props) => {
    return (
        <Pagination
            activePage={props.activePage}
            itemsCountPerPage={props.itemsPerPage}
            totalItemsCount={props.totalItems}
            innerClass="pagination ebw-pagination mb-3"
            itemClass="ebw-pagination__item"
            linkClass="d-flex align-items-center justify-content-center"
            activeClass="ebw-pagination__item-active"
            onChange={props.handlePaginationChange.bind(this)}
        />
    );
}

export default PaginationCmn;