import * as type from '../actions/types';

const initialState = {
  completedRegsteps: false,
  registration: [],
  loading: false,
  error: null,
}

export default function registerPersonal(state = initialState, action) {
  switch (action.type) {
    case type.POST_REGISTER_PERSONAL_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.POST_REGISTER_PERSONAL_SUCCESS:
      return {
        ...state,
        loading: false,
        registration: action.registerPersonal,
      }
    case type.POST_REGISTER_PERSONAL_FAILED:
      return {
        ...state,
        completedRegsteps: 0,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}