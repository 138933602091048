import * as type from '../actions/types';

const initialState = {
  activeRegistrationStep: 1,
  loading: false,
  error: null,
}

export default function setRegStep(state = initialState, action) {
  switch (action.type) {
    case type.STORE_ACTIVE_REG_STEP_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.STORE_ACTIVE_REG_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
        activeRegistrationStep: action.activeStep,
      }
    case type.STORE_ACTIVE_REG_STEP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}