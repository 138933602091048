import * as type from '../actions/types';

const initialState = {
  notification_list: [],
  loading: false,
  error: null,
}

export default function getNotificationList(state = initialState, action) {
  switch (action.type) {
    case type.GET_NOTIFICATION_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notification_list: action.notificationList
      }
    case type.GET_NOTIFICATION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}