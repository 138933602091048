import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import { toastr } from 'react-redux-toastr'

async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/dashboard.json?project_type_id=${data.project_type_id}&project_sub_type_id=${data.project_sub_type_id}`;
    const requestOptions = {
        method: 'GET',
        headers: API.options.headers,
    };
    try {
        const response = await fetch(apiUrl, requestOptions);
        return await response.json();
    } catch (error) {
        throw error;
    }
}

function* getDashboardData(action) {
    console.log("actionaction", action);
    try {
        const response = yield call(getApi, action.data);
        if (response.code == 200 || response.code == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'GET_DASHBOARD_DATA_SUCCESS', data: { ...response.data } });
    } catch (e) {
        yield put({ type: 'GET_DASHBOARD_DATA_FAILED', message: e.message });
    }
}

function* dashboardDataSaga() {
    yield takeEvery('GET_DASHBOARD_DATA_REQUESTED', getDashboardData);
}

export default dashboardDataSaga;