import { call, put, takeEvery } from 'redux-saga/effects'

function* storeData(action) {
    if (action.data) {
        yield put({ type: 'STORE_AVAILABLE_CATEGORIES_SUCCESS', data: action.data });
    } else {
        yield put({ type: 'STORE_AVAILABLE_CATEGORIES_FAILED', message: "no data" });
    }
}

function* setAvailableCategoriesSaga() {
    yield takeEvery('STORE_AVAILABLE_CATEGORIES_REQUESTED', storeData);
}

export default setAvailableCategoriesSaga;