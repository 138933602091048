import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import axios from 'axios';
async function getApi(data) {
    const apiUrl = API.baseURL + `/projects/${data}/get_project_videos`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* projectsVideoData(action) {
    try {
        const response = yield call(getApi, action.data);
        yield put({ type: 'GET_PROJECT_VIDEOS_DATA_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_PROJECT_VIDEOS_DATA_FAILED', message: e.message });
    }
}
function* projectVideosSaga() {
    yield takeEvery('GET_PROJECT_VIDEOS_DATA_REQUESTED', projectsVideoData);
}
export default projectVideosSaga;