const validate = (inputs) => {
    let errors = {
        valid: true,
    };
    //googleid name errors
    // if (!inputs.googleid) {
    //     errors.googleidError = 'Enter Google ID'
    //     errors.valid = false;
    // }
    //yelpid type errors
    // if (!inputs.yelpid || inputs.yelpid === "") {
    //     errors.yelpidError = 'Select Yelp ID'
    //     errors.valid = false;
    // }
    // referral_id errors
    if (inputs.referral_id.length == 0) {
        errors.referral_id_error = 'Please select an option';
        errors.valid = false;
    }
    return errors;
}
export default validate;