export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const POST_LOGIN_REQUESTED = 'POST_LOGIN_REQUESTED';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILED = 'POST_LOGIN_FAILED';

export const POST_LOGOUT_REQUESTED = 'POST_LOGOUT_REQUESTED';
export const POST_LOGOUT_SUCCESS = 'POST_LOGOUT_SUCCESS';
export const POST_LOGOUT_FAILED = 'POST_LOGOUT_FAILED';

export const POST_REGISTER_PERSONAL_REQUESTED = "POST_REGISTER_PERSONAL_REQUESTED";
export const POST_REGISTER_PERSONAL_SUCCESS = "POST_REGISTER_PERSONAL_SUCCESS";
export const POST_REGISTER_PERSONAL_FAILED = "POST_REGISTER_PERSONAL_FAILED";

export const POST_REGISTER_OTP_REQUESTED = "POST_REGISTER_OTP_REQUESTED";
export const POST_REGISTER_OTP_SUCCESS = "POST_REGISTER_OTP_SUCCESS";
export const POST_REGISTER_OTP_FAILED = "POST_REGISTER_OTP_FAILED";

export const REGISTER_STORE_BUSINESS_REQUESTED = "REGISTER_STORE_BUSINESS_REQUESTED";
export const REGISTER_STORE_BUSINESS_SUCCESS = "REGISTER_STORE_BUSINESS_SUCCESS";
export const REGISTER_STORE_BUSINESS_FAILED = "REGISTER_STORE_BUSINESS_FAILED";

export const GET_CITIES_REQUESTED = "GET_CITIES_REQUESTED";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILED = "GET_CITIES_FAILED";

export const GET_SERVICE_TYPES_REQUESTED = "GET_SERVICE_TYPES_REQUESTED";
export const GET_SERVICE_TYPES_SUCCESS = "GET_SERVICE_TYPES_SUCCESS";
export const GET_SERVICE_TYPES_FAILED = "GET_SERVICE_TYPES_FAILED";

export const POST_REGISTER_BUSINESS_REQUESTED = "POST_REGISTER_BUSINESS_REQUESTED";
export const POST_REGISTER_BUSINESS_SUCCESS = "POST_REGISTER_BUSINESS_SUCCESS";
export const POST_REGISTER_BUSINESS_FAILED = "POST_REGISTER_BUSINESS_FAILED";

export const POST_REGISTER_ADDITIONAL_REQUESTED = "POST_REGISTER_ADDITIONAL_REQUESTED";
export const POST_REGISTER_ADDITIONAL_SUCCESS = "POST_REGISTER_ADDITIONAL_SUCCESS";
export const POST_REGISTER_ADDITIONAL_FAILED = "POST_REGISTER_ADDITIONAL_FAILED";

export const GET_REFARRAL_LIST_REQUESTED = "GET_REFARRAL_LIST_REQUESTED";
export const GET_REFARRAL_LIST_SUCCESS = "GET_REFARRAL_LIST_SUCCESS";
export const GET_REFARRAL_LIST_FAILED = "GET_REFARRAL_LIST_FAILED";

export const STORE_SET_AUTH_REQUESTED = "STORE_SET_AUTH_REQUESTED";
export const STORE_SET_AUTH_SUCCESS = "STORE_SET_AUTH_SUCCESS";
export const STORE_SET_AUTH_FAILED = "STORE_SET_AUTH_FAILED";

export const GET_DASHBOARD_DATA_REQUESTED = "GET_DASHBOARD_DATA_REQUESTED";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";

export const GET_PROFILE_DATA_REQUESTED = "GET_PROFILE_DATA_REQUESTED";
export const GET_PROFILE_DATA_SUCCESS = "GET_PROFILE_DATA_SUCCESS";
export const GET_PROFILE_DATA_FAILED = "GET_PROFILE_DATA_FAILED";

export const GET_NOTIFICATION_LIST_REQUESTED = "GET_NOTIFICATION_LIST_REQUESTED";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_FAILED = "GET_NOTIFICATION_LIST_FAILED";

export const GET_ALL_PROJECTS_DATA_REQUESTED = "GET_ALL_PROJECTS_DATA_REQUESTED";
export const GET_ALL_PROJECTS_DATA_SUCCESS = "GET_ALL_PROJECTS_DATA_SUCCESS";
export const GET_ALL_PROJECTS_DATA_FAILED = "GET_ALL_PROJECTS_DATA_FAILED";


export const GET_ALL_IN_BIDDING_PROJECTS_REQUESTED = "GET_ALL_IN_BIDDING_PROJECTS_REQUESTED";
export const GET_ALL_IN_BIDDING_PROJECTS_SUCCESS = "GET_ALL_IN_BIDDING_PROJECTS_SUCCESS";
export const GET_ALL_IN_BIDDING_PROJECTS_FAILED  = "GET_ALL_IN_BIDDING_PROJECTS_FAILED ";


export const GET_ALL_HIRED_REQUESTED = "GET_ALL_HIRED_REQUESTED";
export const GET_ALL_HIRED_SUCCESS = "GET_ALL_HIRED_SUCCESS";
export const GET_ALL_HIRED_FAILED  = "GET_ALL_HIRED_FAILED ";

export const GET_PROJECTS_AVAILABLE_DATA_REQUESTED = "GET_PROJECTS_AVAILABLE_DATA_REQUESTED";
export const GET_PROJECTS_AVAILABLE_DATA_SUCCESS = "GET_PROJECTS_AVAILABLE_DATA_SUCCESS";
export const GET_PROJECTS_AVAILABLE_DATA_FAILED = "GET_PROJECTS_AVAILABLE_DATA_FAILED";

export const GET_IN_CONVERSATION_DATA_REQUESTED = "GET_IN_CONVERSATION_DATA_REQUESTED";
export const GET_IN_CONVERSATION_DATA_SUCCESS = "GET_IN_CONVERSATION_DATA_SUCCESS";
export const GET_IN_CONVERSATION_DATA_FAILED = "GET_IN_CONVERSATION_DATA_FAILED";

export const STORE_ACTIVE_REG_STEP_REQUESTED = "STORE_ACTIVE_REG_STEP_REQUESTED";
export const STORE_ACTIVE_REG_STEP_SUCCESS = "STORE_ACTIVE_REG_STEP_SUCCESS";
export const STORE_ACTIVE_REG_STEP_FAILED = "STORE_ACTIVE_REG_STEP_FAILED";

export const GET_PROFILE_EDIT_DATA_REQUESTED = "GET_PROFILE_EDIT_DATA_REQUESTED";
export const GET_PROFILE_EDIT_DATA_SUCCESS = "GET_PROFILE_EDIT_DATA_SUCCESS";
export const GET_PROFILE_EDIT_DATA_FAILED = "GET_PROFILE_EDIT_DATA_FAILED";

export const GET_CURRENT_PROJECT_DATA_REQUESTED = "GET_CURRENT_PROJECT_DATA_REQUESTED";
export const GET_CURRENT_PROJECT_DATA_SUCCESS = "GET_CURRENT_PROJECT_DATA_SUCCESS";
export const GET_CURRENT_PROJECT_DATA_FAILED = "GET_CURRENT_PROJECT_DATA_FAILED";

export const GET_COMMISSION_DETAILS_REQUESTED = "GET_COMMISSION_DETAILS_REQUESTED";
export const GET_COMMISSION_DETAILS_SUCCESS = "GET_COMMISSION_DETAILS_SUCCESS";
export const GET_COMMISSION_DETAILS_FAILED = "GET_COMMISSION_DETAILS_FAILED";

export const POST_SUBMIT_BID_REQUESTED = "POST_SUBMIT_BID_REQUESTED";
export const POST_SUBMIT_BID_SUCCESS = "POST_SUBMIT_BID_SUCCESS";
export const POST_SUBMIT_BID_FAILED = "POST_SUBMIT_BID_FAILED";

export const GET_MY_WORKS_REQUESTED = "GET_MY_WORKS_REQUESTED";
export const GET_MY_WORKS_SUCCESS = "GET_MY_WORKS_SUCCESS";
export const GET_MY_WORKS_FAILED = "GET_MY_WORKS_FAILED";

export const GET_MY_WORKS_FILES_REQUESTED = "GET_MY_WORKS_FILES_REQUESTED";
export const GET_MY_WORKS_FILES_SUCCESS = "GET_MY_WORKS_FILES_SUCCESS";
export const GET_MY_WORKS_FILES_FAILED = "GET_MY_WORKS_FILES_FAILED";

export const CREATE_MY_WORKS_FOLDER_REQUESTED = "CREATE_MY_WORKS_FOLDER_REQUESTED";
export const CREATE_MY_WORKS_FOLDER_SUCCESS = "CREATE_MY_WORKS_FOLDER_SUCCESS";
export const CREATE_MY_WORKS_FOLDER_FAILED = "CREATE_MY_WORKS_FOLDER_FAILED";

export const GET_ALL_BUY_PROJECTS_DATA_REQUESTED = "GET_ALL_BUY_PROJECTS_DATA_REQUESTED";
export const GET_ALL_BUY_PROJECTS_DATA_SUCCESS = "GET_ALL_BUY_PROJECTS_DATA_SUCCESS";
export const GET_ALL_BUY_PROJECTS_DATA_FAILED = "GET_ALL_BUY_PROJECTS_DATA_FAILED";

export const ACTIVATE_CHATBOX_REQUESTED = "ACTIVATE_CHATBOX_REQUESTED";
export const ACTIVATE_CHATBOX_SUCCESS = "ACTIVATE_CHATBOX_SUCCESS";
export const ACTIVATE_CHATBOX_FAILED = "ACTIVATE_CHATBOX_FAILED";

export const POST_PROJECT_COMPLETED_REQUESTED = "POST_PROJECT_COMPLETED_REQUESTED";
export const POST_PROJECT_COMPLETED_SUCCESS = "POST_PROJECT_COMPLETED_SUCCESS";
export const POST_PROJECT_COMPLETED_FAILED = "POST_PROJECT_COMPLETED_FAILED";

export const POST_PROJECT_CANCEL_REQUESTED = "POST_PROJECT_CANCEL_REQUESTED";
export const POST_PROJECT_CANCEL_SUCCESS = "POST_PROJECT_CANCEL_SUCCESS";
export const POST_PROJECT_CANCEL_FAILED = "POST_PROJECT_CANCEL_FAILED";

export const GET_CHAT_DATA_REQUESTED = "GET_CHAT_DATA_REQUESTED";
export const GET_CHAT_DATA_SUCCESS = "GET_CHAT_DATA_SUCCESS";
export const GET_CHAT_DATA_FAILED = "GET_CHAT_DATA_FAILED";

export const POST_DELETE_MESSAGE_REQUESTED = "POST_DELETE_MESSAGE_REQUESTED";
export const POST_DELETE_MESSAGE_SUCCESS = "POST_DELETE_MESSAGE_SUCCESS";
export const POST_DELETE_MESSAGE_FAILED = "POST_DELETE_MESSAGE_FAILED";

export const POST_SEND_MESSAGE_REQUESTED = "POST_SEND_MESSAGE_REQUESTED";
export const POST_SEND_MESSAGE_SUCCESS = "POST_SEND_MESSAGE_SUCCESS";
export const POST_SEND_MESSAGE_FAILED = "POST_SEND_MESSAGE_FAILED";

export const POST_UPDATE_EMAIL_REQUESTED = "POST_UPDATE_EMAIL_REQUESTED";
export const POST_UPDATE_EMAIL_SUCCESS = "POST_UPDATE_EMAIL_SUCCESS";
export const POST_UPDATE_EMAIL_FAILED = "POST_UPDATE_EMAIL_FAILED";

export const RENAME_MY_WORKS_ALBUM_REQUESTED = "RENAME_MY_WORKS_ALBUM_REQUESTED";
export const RENAME_MY_WORKS_ALBUM_SUCCESS = "RENAME_MY_WORKS_ALBUM_SUCCESS";
export const RENAME_MY_WORKS_ALBUM_FAILED = "RENAME_MY_WORKS_ALBUM_FAILED";

export const DELETE_MY_WORKS_ALBUM_REQUESTED = "DELETE_MY_WORKS_ALBUM_REQUESTED";
export const DELETE_MY_WORKS_ALBUM_SUCCESS = "DELETE_MY_WORKS_ALBUM_SUCCESS";
export const DELETE_MY_WORKS_ALBUM_FAILED = "DELETE_MY_WORKS_ALBUM_FAILED";

export const POST_ALBUM_FILE_REQUESTED = "POST_ALBUM_FILE_REQUESTED";
export const POST_ALBUM_FILE_SUCCESS = "POST_ALBUM_FILE_SUCCESS";
export const POST_ALBUM_FILE_FAILED = "POST_ALBUM_FILE_FAILED";

export const REMOVE_ALBUM_FILE_REQUESTED = "REMOVE_ALBUM_FILE_REQUESTED";
export const REMOVE_ALBUM_FILE_SUCCESS = "REMOVE_ALBUM_FILE_SUCCESS";
export const REMOVE_ALBUM_FILE_FAILED = "REMOVE_ALBUM_FILE_FAILED";

export const POST_UPDATE_EMAIL_OTP_REQUESTED = "POST_UPDATE_EMAIL_OTP_REQUESTED";
export const POST_UPDATE_EMAIL_OTP_SUCCESS = "POST_UPDATE_EMAIL_OTP_SUCCESS";
export const POST_UPDATE_EMAIL_OTP_FAILED = "POST_UPDATE_EMAIL_OTP_FAILED";

export const POST_UPDATE_PROFILE_IMAGE_REQUESTED = "POST_UPDATE_PROFILE_IMAGE_REQUESTED";
export const POST_UPDATE_PROFILE_IMAGE_SUCCESS = "POST_UPDATE_PROFILE_IMAGE_SUCCESS";
export const POST_UPDATE_PROFILE_IMAGE_FAILED = "POST_UPDATE_PROFILE_IMAGE_FAILED";

export const GET_NOTIFICATION_COUNT_REQUESTED = "GET_NOTIFICATION_COUNT_REQUESTED";
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_FAILED = "GET_NOTIFICATION_COUNT_FAILED";

export const STORE_SELECTED_CATEGORY_REQUESTED = "STORE_SELECTED_CATEGORY_REQUESTED";
export const STORE_SELECTED_CATEGORY_SUCCESS = "STORE_SELECTED_CATEGORY_SUCCESS";
export const STORE_SELECTED_CATEGORY_FAILED = "STORE_SELECTED_CATEGORY_FAILED";

export const STORE_SELECTED_TYPES_REQUESTED = "STORE_SELECTED_TYPES_REQUESTED";
export const STORE_SELECTED_TYPES_SUCCESS = "STORE_SELECTED_TYPES_SUCCESS";
export const STORE_SELECTED_TYPES_FAILED = "STORE_SELECTED_TYPES_FAILED";

export const POST_SOCIAL_SIGNUP_REQUESTED = "POST_SOCIAL_SIGNUP_REQUESTED";
export const POST_SOCIAL_SIGNUP_SUCCESS = "POST_SOCIAL_SIGNUP_SUCCESS";
export const POST_SOCIAL_SIGNUP_FAILED = "POST_SOCIAL_SIGNUP_FAILED";

export const POST_UPDATE_PROFILE_REQUESTED = "POST_UPDATE_PROFILE_REQUESTED";
export const POST_UPDATE_PROFILE_SUCCESS = "POST_UPDATE_PROFILE_SUCCESS";
export const POST_UPDATE_PROFILE_FAILED = "POST_UPDATE_PROFILE_FAILED";

export const GET_CUSTOMER_REVIEWS_REQUESTED = "GET_CUSTOMER_REVIEWS_REQUESTED";
export const GET_CUSTOMER_REVIEWS_SUCCESS = "GET_CUSTOMER_REVIEWS_SUCCESS";
export const GET_CUSTOMER_REVIEWS_FAILED = "GET_CUSTOMER_REVIEWS_FAILED";

export const GET_REGISTER_RESEND_OTP_REQUESTED = "GET_REGISTER_RESEND_OTP_REQUESTED";
export const GET_REGISTER_RESEND_OTP_SUCCESS = "GET_REGISTER_RESEND_OTP_SUCCESS";
export const GET_REGISTER_RESEND_OTP_FAILED = "GET_REGISTER_RESEND_OTP_FAILED";

export const DELETE_IGNORE_THIS_PROJECT_REQUESTED = "DELETE_IGNORE_THIS_PROJECT_REQUESTED";
export const DELETE_IGNORE_THIS_PROJECT_SUCCESS = "DELETE_IGNORE_THIS_PROJECT_SUCCESS";
export const DELETE_IGNORE_THIS_PROJECT_FAILED = "DELETE_IGNORE_THIS_PROJECT_FAILED";

export const DELETE_BID_REQUESTED = "DELETE_BID_REQUESTED";
export const DELETE_BID_SUCCESS = "DELETE_BID_SUCCESS";
export const DELETE_BID_FAILED = "DELETE_BID_FAILED";

export const GET_OTP_LOGIN_REQUESTED = "GET_OTP_LOGIN_REQUESTED";
export const GET_OTP_LOGIN_SUCCESS = "GET_OTP_LOGIN_SUCCESS";
export const GET_OTP_LOGIN_FAILED = "GET_OTP_LOGIN_FAILED";

export const POST_OTP_LOGIN_CONFIRM_REQUESTED = "POST_OTP_LOGIN_CONFIRM_REQUESTED";
export const POST_OTP_LOGIN_CONFIRM_SUCCESS = "POST_OTP_LOGIN_CONFIRM_SUCCESS";
export const POST_OTP_LOGIN_CONFIRM_FAILED = "POST_OTP_LOGIN_CONFIRM_FAILED";

export const POST_OTP_LOGIN_RESEND_OTP_REQUESTED = "POST_OTP_LOGIN_RESEND_OTP_REQUESTED";
export const POST_OTP_LOGIN_RESEND_OTP_SUCCESS = "POST_OTP_LOGIN_RESEND_OTP_SUCCESS";
export const POST_OTP_LOGIN_RESEND_OTP_FAILED = "POST_OTP_LOGIN_RESEND_OTP_FAILED";

export const PUT_REGISTRATION_EDIT_MOBILE_NUMBER_REQUESTED = "PUT_REGISTRATION_EDIT_MOBILE_NUMBER_REQUESTED";
export const PUT_REGISTRATION_EDIT_MOBILE_NUMBER_SUCCESS = "PUT_REGISTRATION_EDIT_MOBILE_NUMBER_SUCCESS";
export const PUT_REGISTRATION_EDIT_MOBILE_NUMBER_FAILED = "PUT_REGISTRATION_EDIT_MOBILE_NUMBER_FAILED";

export const GET_PROJECT_VIDEOS_DATA_REQUESTED = "GET_PROJECT_VIDEOS_DATA_REQUESTED";
export const GET_PROJECT_VIDEOS_DATA_SUCCESS = "GET_PROJECT_VIDEOS_DATA_SUCCESS";
export const GET_PROJECT_VIDEOS_DATA_FAILED = "GET_PROJECT_VIDEOS_DATA_FAILED";

export const SET_AVAILABLE_PROJECTS_DATA_REQUESTED = "SET_AVAILABLE_PROJECTS_DATA_REQUESTED";
export const SET_AVAILABLE_PROJECTS_DATA_SUCCESS = "SET_AVAILABLE_PROJECTS_DATA_SUCCESS";
export const SET_AVAILABLE_PROJECTS_DATA_FAILED = "SET_AVAILABLE_PROJECTS_DATA_FAILED";

export const GET_FIRST_FIVE_NOTIFICATIONS_REQUESTED = "GET_FIRST_FIVE_NOTIFICATIONS_REQUESTED";
export const GET_FIRST_FIVE_NOTIFICATIONS_SUCCESS = "GET_FIRST_FIVE_NOTIFICATIONS_SUCCESS";
export const GET_FIRST_FIVE_NOTIFICATIONS_FAILED = "GET_FIRST_FIVE_NOTIFICATIONS_FAILED";

export const STORE_AVAILABLE_CATEGORIES_REQUESTED = "STORE_AVAILABLE_CATEGORIES_REQUESTED";
export const STORE_AVAILABLE_CATEGORIES_SUCCESS = "STORE_AVAILABLE_CATEGORIES_SUCCESS";
export const STORE_AVAILABLE_CATEGORIES_FAILED = "STORE_AVAILABLE_CATEGORIES_FAILED";

export const STORE_SELECTED_TYPES_ALL_REQUESTED = "STORE_SELECTED_TYPES_ALL_REQUESTED";
export const STORE_SELECTED_TYPES_ALL_SUCCESS = "STORE_SELECTED_TYPES_ALL_SUCCESS";
export const STORE_SELECTED_TYPES_ALL_FAILED = "STORE_SELECTED_TYPES_ALL_FAILED";

export const SET_SOCIAL_INCOMPLETE_REG_REQUESTED = "SET_SOCIAL_INCOMPLETE_REG_REQUESTED";
export const SET_SOCIAL_INCOMPLETE_REG_SUCCESS = "SET_SOCIAL_INCOMPLETE_REG_SUCCESS";
export const SET_SOCIAL_INCOMPLETE_REG_FAILED = "SET_SOCIAL_INCOMPLETE_REG_FAILED";

export const SET_OTP_LOGIN_STEP_REQUESTED = "SET_OTP_LOGIN_STEP_REQUESTED";
export const SET_OTP_LOGIN_STEP_SUCCESS = "SET_OTP_LOGIN_STEP_SUCCESS";
export const SET_OTP_LOGIN_STEP_FAILED = "SET_OTP_LOGIN_STEP_FAILED";

export const GET_RELATED_PROJECTS_REQUESTED = "GET_RELATED_PROJECTS_REQUESTED";
export const GET_RELATED_PROJECTS_SUCCESS = "GET_RELATED_PROJECTS_SUCCESS";
export const GET_RELATED_PROJECTS_FAILED = "GET_RELATED_PROJECTS_FAILED";

export const GET_NEW_SERVICES_REQUESTED = "GET_NEW_SERVICES_REQUESTED";
export const GET_NEW_SERVICES_SUCCESS = "GET_NEW_SERVICES_SUCCESS";
export const GET_NEW_SERVICES_FAILED = "GET_NEW_SERVICES_FAILED";