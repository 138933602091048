import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import DashNotificationLoader from "../Skeletons/notificationDash"
const DasboardNotification = (props) => {
    const { firstTwoNotifications, notificationLoading } = props;
    const getLink = (taskName, data) => {
        let link = "";
        if (taskName == "A Provider is interested in your project") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "New Project Available for Bidding") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "You have a New Lead for Purchase") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "Project Updates") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "You are Hired!") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "ProjectAwarded") {
            link = `/project-detail/${data.project_id}/lead_projects`
        } else if (taskName == "Project Reviewed") {
            link = "/review"
        }
        return link;
    }
    const getIconSpesific = (taskName) => {
        let render = "";
        if (taskName === "RemainderToBid") {
            render = (
                <img className="w-100" src="/images/notification/1@2x.png" alt="" />
            )
        } else if (taskName === "A Provider is interested in your project") {
            render = (
                <img className="w-100" src="/images/notification/5@2x.png" alt="" />
            )
        } else if (taskName === "ProviderProjectAvailable") {
            render = (
                <img className="w-100" src="/images/notification/2@2x.png" alt="" />
            )
        } else if (taskName == "New Project Available for Bidding") {
            render = (
                <img className="w-100" src="/images/notification/1@2x.png" alt="" />
            )
        } else if (taskName === "UpgradeToPro") {
            render = (
                <img className="w-100" src="/images/notification/4@2x.png" alt="" />
            )
        } else if (taskName === "ProjectReviewed") {
            render = (
                <img className="w-100" src="/images/notification/10@2x.png" alt="" />
            )
        } else if (taskName === "ProjectContacted") {
            render = (
                <img className="w-100" src="/images/notification/3@2x.png" alt="" />
            )
        } else if (taskName === "Pro+") {
            render = (
                <img className="w-100" src="/images/notification/4@2x.png" alt="" />
            )
        } else if (taskName === "LeadProjectAvailable") {
            render = (
                <img className="w-100" src="/images/notification/2@2x.png" alt="" />
            )
        } else if (taskName === "You have a New Lead for Purchase") {
            render = (
                <img className="w-100" src="/images/notification/2@2x.png" alt="" />
            )
        }
        else if (taskName === "You are Hired!") {
            render = (
                <img className="w-100" src="/images/notification/5@2x.png" alt="" />
            )
        } else if (taskName == "Project Reviewed") {

            render = (
                <img className="w-100" src="/images/notification/10@2x.png" alt="" />
            )
        } else if (taskName == "Pro+ Upgrade") {
            render = (
                <img className="w-100" src="/images/notification/4@2x.png" alt="" />
            )
        } else {
            render = "";
        }
        // You are Hired!
        return render;

    }
    return (
        <>
            {notificationLoading ? <DashNotificationLoader /> : <>
                {firstTwoNotifications && firstTwoNotifications.length != 0 && firstTwoNotifications.map((notification, index) => {
                    return (
                        <div key={index} className="ebw-header__notification-item d-flex flex-row align-items-start">
                            <div className="ebw-header__notification-item--img px-3">
                                {/* <img src={notification.icon} alt={notification.title} className="img-fluid d-block mx-auto" /> */}
                                <span className={"ebw-icon ebw-icon--xl icon-buy" + notification.icon}>

                                    {/* <img className="w-100" src="./images/notification/hammer.png" alt="" /> */}
                                    {getIconSpesific(notification.task_name)}
                                </span>
                            </div>
                            <div className="ebw-header__notification-item--info">
                                <div className="ebw-header__notification-item--title mb-2">{notification.notification}</div>
                                {/* <div className="ebw-header__notification-item--link">
                          <Link to="">{notification.tag}</Link>
                        </div> */}
                                <div className="ebw-header__notification-item--timestamp">{notification.created_at}</div>
                            </div>
                            {notification.task_name != "Pro+ Upgrade" &&
                                <div>

                                    <Link className="ebw-button__plain--blue" to={getLink(notification.task_name, notification)}>View</Link>
                                </div>}
                        </div>
                    )
                })}
            </>
            }
        </>
    );
}

export default DasboardNotification;