import React from 'react';
import { Label, Input, Row, Col } from 'reactstrap';


//- Checkbox for Remember in Login/Register Page
export const Checkbox = (props) => {
    return (
        <div className="ebw-form__checkboxes position-relative d-flex align-items-center">
            <Label check className="ebw-form__checkbox-label">
                <Input
                    type="checkbox"
                    name={props.name}
                    id={props.name}
                    value={props.value}
                    className="ebw-form__checkbox ebw-form__checkboxes-check"
                    onChange={props.onChange}
                    checked={props.checked}
                />
                <span className="ebw-form__checkboxes-mark"></span>
                <span className="ebw-form__checkbox-text pl-4">{props.text}</span>
            </Label>
        </div>
    );
}


//- Checkbox with Icon in Register Page

export const CheckboxIcons = (props) => {

    return (
        <>
            <Row>
                <Col sm="12" className="mb-8">
                    <div className="pl-6">
                        <Checkbox
                            text="Select all"
                            type="checkbox"
                            name={"all"}
                            id={"all"}
                            className="ebw-form__checkboxes-all"
                            value={"selectall"}
                            onChange={props.selectAllEvent}
                            checked={props.checkedAll}
                        />
                    </div>
                </Col>

                {props.icons.map((icon, index) =>
                    <Col key={index} xs="4" sm="3" className="mb-12 px-2">
                        <div className="ebw-form__checkboxes position-relative">
                            <Label check>
                                <div className="ebw-form__checkboxes-info position-relative">
                                    <img src={icon.img} alt={icon.text} className="ebw-form__checkboxes-img img-fluid d-block mx-auto" />
                                    <p className="ebw-form__checkboxes-text pt-2 mb-2">{icon.text}</p>
                                </div>

                                <div className="position-relative">
                                    <Input
                                        type="checkbox"
                                        name={props.name}
                                        id={props.name}
                                        value={icon.value}
                                        checked={props.checked.includes(icon.value)}
                                        className="ebw-form__checkboxes-check mx-auto"
                                        onChange={props.changeEvent}
                                    />
                                    <span className="ebw-form__checkboxes-mark"></span>
                                </div>
                            </Label>
                        </div>
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    {props.error && <p className="ebw-input__error">{props.error}</p>}
                </Col>
            </Row>
        </>
    );
}
