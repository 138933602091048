import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Payment from "./payment";

const PaymentPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <Payment />
    );
}

export default PaymentPage;