import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";

import Main from '../../layout/main'
import { LoginRegsiterLeft, LoginMobileSlider } from "../../components/login/login-left";
import ProPlusCard from "../../components/card/card-proplus";
import ProPlusLoader from "../../components/proPlusPayment/proPlusSkeleton";


const ProPlus = (props) => {
    const { ProfileData, ProfileDataLoading } = props;
    const [plan, setplan] = useState('yearly');

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pro+ Payment</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main=" ebw-pro__main">
                <section className="ebw__section--pro">
                    <Container className="ebw-pro__container d-flex align-items-start">
                        {ProfileDataLoading ?
                            <ProPlusLoader />
                            :

                            <Row className="ebw-pro__row align-items-center">
                                {/* <Col sm="12" md="5" lg="4" className="ebw-entry__col-lft order-2 order-md-1">
                                        <LoginRegsiterLeft
                                            title="Why you should upgrade to Pro+ ?"
                                            text="Our Pro+ members get 30% more chance of getting jobs. Reach out to more customers easily"
                                            imgUrl="./images/login/login@2x.png"
                                        />

                                        <LoginMobileSlider />
                                    </Col> */}

                                <Col sm="12" md="6">
                                    <div>
                                        <h1 className="ebw-pro__title d-flex align-items-center">
                                            <span className="ebw-pro__title-text mr-3">Easy Bids</span>
                                            <span className="ebw-pro__batch d-flex align-items-center">PRO <span className="icon-add ebw-pro__batch-icon ml-2"></span></span>
                                        </h1>
                                        <p className="ebw-pro__subtitle mt-2">Become a PRO+ Member Now!</p>
                                        {/* <h1 className="ebw-entry__pro-title text-center">Become a <span className="ebw-button__pro">PRO +</span> Now</h1> */}

                                        <div style={{ display: 'flex' }}>
                                            <input
                                                type="radio"
                                                name="proPlan"
                                                value="monthly"
                                                checked={plan === "monthly"}
                                                onChange={(e) => setplan(e.target.value)}
                                                style={{ marginRight: "10px" }}
                                            />
                                            <div style={{ backgroundImage: "url(/images/pro/pro-plan-back@2x.png)" }} className="ebw-entry__pro-plan d-flex align-items-center justify-content-between">
                                                <h2 className="ebw-entry__pro-plan--title mb-0">PRO Plan</h2>
                                                <div className="ebw-entry__pro-plan--inner d-flex align-items-center">
                                                    <div>
                                                        <span className="ebw-entry__pro-plan--oldprice">$199</span>
                                                        <span className="ebw-entry__pro-plan--annual">/month</span>
                                                    </div>
                                                    <div>
                                                        <strong className="ebw-entry__pro-plan--actualprice pl-6">$99</strong>
                                                        <span className="ebw-entry__pro-plan--annual">/month</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ display: 'flex' }}>

                                            <input
                                                type="radio"
                                                name="proPlan"
                                                value="yearly"
                                                checked={plan === "yearly"}
                                                onChange={(e) => setplan(e.target.value)}
                                                style={{ marginRight: "10px" }}
                                            />
                                            <div style={{ backgroundImage: "url(/images/pro/pro-plan-back@2x.png)" }} className="ebw-entry__pro-plan d-flex align-items-center justify-content-between">
                                                <h2 className="ebw-entry__pro-plan--title mb-0">PRO Plan</h2>
                                                <div className="ebw-entry__pro-plan--inner d-flex align-items-center">
                                                    <div>
                                                        <span className="ebw-entry__pro-plan--oldprice">$1599</span>
                                                        <span className="ebw-entry__pro-plan--annual">/year</span>
                                                    </div>
                                                    <div>
                                                        <strong className="ebw-entry__pro-plan--actualprice pl-6">$999</strong>
                                                        <span className="ebw-entry__pro-plan--annual">/year</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                                <Col sm="12" md="6" className="ebw-pro__col-rgt h-100 mx-auto order-1 order-md-2">
                                    <div className="ebw-pro__content-rgt d-flex justify-content-center">
                                        <ProPlusCard provider_id={ProfileData.id} user_id={ProfileData.user_id} plan = {plan} />
                                    </div>
                                </Col>
                            </Row>
                            
                        }
                        
                    </Container>
                </section>
            </Main>
        </div>
    )
}
export default ProPlus;