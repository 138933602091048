import React, { Component } from 'react';
import Main from '../../layout/main';
import {
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import Stats from "../../components/dashboard/stats";
import SuggestedProjects from "../../components/dashboard/suggestedProjects";
import AvailableProjectsLoader from "../../components/dashboard/availableProjectsSkeleton";
import { activateChatBox } from "../../redux/actions/chatboxActive";
SwiperCore.use([Navigation, Autoplay]);
class DashBoard extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    const { provider_stats, stats } = this.props.dashData;
    const { dashData } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Check out all your Data about EasyBids at a single place.The projects you bidded,avaible projects around locality  and the bids approved.Check everything at a single place" />
          <title>Dashboard - Easy Bids Pro</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        </Helmet>
        <Main additionalClass_main="dashboard pb-0">
          <Stats provider_stats={provider_stats} stats={stats} loading={dashData.loading} />
          {<SuggestedProjects data={dashData?.activities} title={'Your Activities'} btnId={'ya'} loading={dashData?.loading} show={true}/>}
          {<SuggestedProjects data={dashData?.my_projects} title={'Your Projects'} btnId={'yp'}loading={dashData?.loading}/> }
          {<SuggestedProjects data={dashData?.projectAvailable} title={'Available Projects'} btnId={'ap'} loading={dashData?.loading}/>}
        </Main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dashData: state.dashoardData,
  notifications: state.notificationList.notification_list,
  projectsData: state.allProjectsData,
  projectAvailable: state.projectAvailable,
  conversation: state.conversations,
});

export default connect(mapStateToProps, { activateChatBox })(DashBoard);;