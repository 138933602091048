import { call, put, takeEvery } from 'redux-saga/effects'
import storeBusiness from '../reducers/storeBusiness';




function* storeData(action) {
    yield put({ type: 'POST_LOGIN_SUCCESS', auth: [] });
    yield put({ type: 'REGISTER_STORE_BUSINESS_SUCCESS', business: {} });
    yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 1 });
    yield put({ type: 'POST_REGISTER_OTP_SUCCESS', registerPersonal: [] });
    yield put({ type: 'POST_REGISTER_PERSONAL_SUCCESS', registration: [] });
    yield put({ type: 'POST_REGISTER_BUSINESS_SUCCESS', registerBusiness: [] });
    yield put({ type: 'POST_REGISTER_ADDITIONAL_SUCCESS', registerAdditional: [] });
    yield put({ type: 'GET_PROFILE_EDIT_DATA_SUCCESS', data: [] });
    yield put({ type: 'STORE_SET_AUTH_SUCCESS', auth: {} });
}

function* logoutSaga() {
    yield takeEvery('POST_LOGOUT_REQUESTED', storeData);
}

export default logoutSaga;