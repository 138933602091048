import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const LighrBoxCmn = (props) => {
    const { images, photoIndex } = props;
    const [state, setState] = useState({
        photoIndex: props.photoIndex,
    })
    return (
        <Lightbox
            mainSrc={images[state.photoIndex]}
            nextSrc={images[(state.photoIndex + 1) % images.length]}
            prevSrc={images[(state.photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => props.closeViewer()}
            onMovePrevRequest={() =>
                setState({
                    photoIndex: (state.photoIndex + images.length - 1) % images.length,
                })
            }
            onMoveNextRequest={() =>
                setState({
                    photoIndex: (state.photoIndex + 1) % images.length,
                })
            }
        />
    );
}

export default LighrBoxCmn;