import React from 'react';
import { Link } from 'react-router-dom';
const NotFoundPage = () => {
    return (
        <div className="ebw-page__error ebw-page__error-404 d-flex flex-column align-items-center justify-content-center">
            <div className="ebw-page__error-wrap ">
                <div className="mb-2 position-relative">
                    <img className="img-fluid" src="/images/page-error/404-global.svg" alt="Project not found" />
                </div>
                <p className="ebw-page__error-title text-center position-relative">Page you are looking for is <br /> not available</p>
                <p>
                    <Link className="ebw-button ebw-button__primary position-relative" to="/">Go to Dashboard</Link>
                </p>
            </div>
        </div>
    );
}

export default NotFoundPage;