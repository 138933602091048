import React from 'react';
import './App.scss';
import AppRouter from "./routes/router";
function App() {
  return (
    <div className="ebw">
      <AppRouter />
    </div>
  );
}
export default App;
