import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import LoginEmail from "../login/login-email";
import LoginOtp from "../login/login-otp";

import GoogleLogin from "../../components/social-login/googlelogin";
import FacebookLogin from "../../components/social-login/facebookLogin";
import AppleSignup from "../../components/social-login/appleLogin";

const NavTabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    return (
        <div className="ebw-entry__rgt-tabs h-100">
            <Nav tabs className="ebw-entry__nav d-flex flex-row justify-content-start">
                <NavItem className="ebw-entry__nav-item">
                    <NavLink
                        className={activeTab == '1' ? 'active' : ''}
                        onClick={() => setActiveTab('1')}
                    >
                        Login using email
                    </NavLink>
                </NavItem>

                <NavItem className="ebw-entry__nav-item">
                    <NavLink
                        className={activeTab == '2' ? 'active' : ''}
                        onClick={() => setActiveTab('2')}
                    >
                        Login using OTP
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className="ebw-entry__tab-content">
                <TabPane tabId="1" className="ebw-entry__tab-pane">
                    <div className="ebw-entry__tab-desc">
                        <LoginEmail />
                    </div>
                </TabPane>

                <TabPane tabId="2" className="ebw-entry__tab-pane">
                    <div className="ebw-entry__tab-desc">
                        <LoginOtp />
                    </div>
                </TabPane>
                {/* <div className="ebw-entry__other-options">
                    <p className="ebw-entry__other-text">
                    Login with
                    </p>
                    <div className="ebw-entry__social-btns d-flex">
                        <span>
                            <FacebookLogin />
                        </span>
                        <span className="ml-4">
                            <GoogleLogin />
                        </span>
                        <span className="ml-4">
                            <AppleSignup />
                        </span>
                    </div>
                </div> */}

            </TabContent>
        </div>
    );
}
export default NavTabs;