import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Input, CustomInput, UncontrolledCollapse } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Buttons from "../buttons/button-primary";

import { setSeletedTypes } from "../../redux/actions/selectedTypes";
import { setSeletedAllTypes } from "../../redux/actions/selectAllServiceTypes";

const EditServiceTypes = (props) => {
    const subIds = [];
    const { serviceTypesData, projectSubTypes, selectedSubTypesPrev } = props;
    const [selectAll, setSelectAll] = useState(false);
    projectSubTypes.filter((subCate) => {
        subIds.push(subCate.id);
    });
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const selectedTypes = useSelector(state => state.selectedTypes.selectedTypes);
    const selectedSubTypes = useSelector(state => state.selectedTypes.selectedSubTypes);
    const setSelectedsubTypes = () => {
        let selectedSubTypesPrevId = [];
        selectedSubTypesPrev.length != 0 && selectedSubTypesPrev.forEach(sub => {
            selectedSubTypesPrevId.push(sub.id)
        })
        dispatch(setSeletedAllTypes(selectedSubTypesPrevId));
    }
    useEffect(() => {
        setSelectedsubTypes();
    }, []);
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectAll(true)
            var all_sub_type = []
            serviceTypesData.services.forEach(category => {
                if (category.project_sub_types.length != 0)
                    category.project_sub_types.forEach(subType => {
                        all_sub_type.push(subType.id);
                    })
            })
            dispatch(setSeletedAllTypes(all_sub_type));
            setError("")
            props.setChangedServices(all_sub_type)
        } else {
            setSelectAll(false)
            dispatch(setSeletedAllTypes([]));
            props.setChangedServices([])
        }
    }
    const handleItemCheckbox = (event) => {
        dispatch(setSeletedTypes(parseInt(event.target.value)));
        setError("");
        setSelectAll(false)
        props.setChangedServices(selectedSubTypes)
    };
    const submitService = (e) => {
        e.preventDefault();
        props.closeModal()
        props.setChangedServices(selectedSubTypes);
    }

    return (
        <Form className="ebw-entry__register-form text-center" onSubmit={e => submitService(e)}>
            <Row form>
                <Col sm="12">
                    <div className="ebw-entry__services">
                        <div className="ebw-entry__services-row d-flex align-items-center justify-content-between mb-2">
                            <label for="selectAll" className="ebw-entry__label-services">Services</label>
                            <div className="ebw-form__checkboxes ebw-form__checkboxes-inline d-flex align-items-center ">
                                <label for="selectAll" className="ebw-form__checkbox-text pl-4 mb-0 mr-2">Select All</label>
                                <CustomInput
                                    type="checkbox"
                                    id="selectAll"
                                    onChange={(e) => handleSelectAll(e)}
                                    checked={selectAll ? true : false}
                                />
                            </div>
                        </div>
                        <div className="ebw-entry__services">

                            {serviceTypesData && serviceTypesData.services.map((service, index) => {
                                return (
                                    <div key={index} className="ebw-entry__service">
                                        <div id={`toggler${index}`} className={`ebw-entry__service-main d-flex align-items-center justify-content-between mt-2 mb-3`}>
                                            <div className="ebw-entry__label-main" style={{fontSize:16}}>{service.title}</div>
                                            <div className={`ebw-entry__icon--add`} >
                                                <span className="ebw-icon ebw-icon--md icon-close"></span>
                                            </div>
                                        </div>
                                        <UncontrolledCollapse className="ebw-entry__service-subcategories mb-3" toggler={`#toggler${index}`}>
                                            <div className="px-3 py-2">
                                                {service.project_sub_types.length != 0 && service.project_sub_types.map((subCategory, subindex) => {
                                                    return (
                                                        <div key={subindex} className="ebw-entry__service-subcategory d-flex align-items-center justify-content-between">
                                                            <label for={`service_sub_item_${subCategory.id}_${service.id}`} className="ebw-entry__label-subcategory">{subCategory.name}</label>
                                                            <div className="ebw-form__checkboxes ebw-form__checkboxes-inline">
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    name={"all"}
                                                                    id={`service_sub_item_${subCategory.id}_${service.id}`}
                                                                    value={subCategory.id}
                                                                    onChange={e => handleItemCheckbox(e)}
                                                                    checked={selectedSubTypes.includes(subCategory.id) ? 'checked' : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </UncontrolledCollapse>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    {error ?
                        <div className="ebw-entry__services-error mt-4">{error}</div> : ""}
                </Col>
                <Col sm="12" md="4" xl="3" className="mx-auto text-center mt-4">
                    <Buttons type="submit" text="Submit" />
                </Col>
            </Row>
        </Form>
    );
}

export default EditServiceTypes;