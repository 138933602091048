import React from 'react';
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
import { Input } from 'reactstrap';

//- Default Input text element
export const InputText = (props) => {
    // an onchange validation can be done here 
    return (
        <div className={`${props.parentClass}`}>
            <FloatingLabel
                id={props.name}
                name={props.name}
                placeholder={props.label}
                className={`custom-class ebw-form__input ${props.additionalClass}`}
                type="text"
                value={props.value}
                onChange={props.changeEvent}
                disabled={props.disabled}
            />
            {props.error && <p className="ebw-input__error mt-0 mb-2 text-left">{props.error}</p>}
        </div>
    );
}



// Input text element for MObile OTP
export const InputTextOtp = (props) => {
    // an onchange validation can be done here 
    return (
        <div>
            <FloatingLabel
                id={props.name}
                name={props.name}
                className="ebw-form__input-otp"
                type="text"
                value={props.value}
                onChange={props.changeEvent}
            />
            {props.Error && <p className="ebw-input__error">{props.errorMessage}</p>}
        </div>
    );
}


// Input Text for My Works Page & Profile Page
export const InputTextNoBorder = (props) => {
    // an onchange validation can be done here 
    return (
        <>
            <Input
                id={props.name}
                name={props.name}
                className="ebw-form__input-noborder"
                type="text"
                placeholder={props.label}
                value={props.value}
                onChange={props.changeHandler}
                onKeyDown={props.onKeyDown}
                disabled={props.disabled}
            />
            {props.Error && <p className="ebw-input__error">{props.errorMessage}</p>}
        </>
    );
}