import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavbarBrand, NavItem, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { HeaderDropdown, NotificationDropdown } from '../components/dropdown/dropdown';
import { isLogin } from "../utils/index";
import history from "../common/history";
import { MobileHeader, MobileNavTabs } from '../components/mobile-header/mobile-header';
import { firstFiveNotifications } from "../redux/actions/firstFiveNotifications";
import { getNotificationCount } from "../redux/actions/notificationCount";
import { getProfileData } from "../redux/actions/profileData";
import { logout } from "../redux/actions/logout";
import NewService from "./newServices";
import ProjectInConversation from '../components/projects/project-incoversation';
const Header = () => {
    const [playVideo, setPlayVideo] = useState(false);
    const [addNewService, setAddNewService] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isLogin() === true) {
            dispatch(firstFiveNotifications());
            dispatch(getNotificationCount());
            dispatch(getProfileData());
        }
    }, []);
    // notificationCount
    const notificationCount = useSelector(state => state.notificationCount.notification_count);
    const firstFiveNotificationsData = useSelector(state => state.firstFiveNotifications.data);
    const firstFiveNotificationsLoading = useSelector(state => state.firstFiveNotifications.loading);
    const profile = useSelector(state => state.profileData.profile);
    const video_url = useSelector(state => state.dashoardData.video_url);
    const auth = useSelector(state => state.auth.auth);
    const regStep = useSelector(state => state.registrationStep.activeRegistrationStep);
    const [regModal, setRegModal] = useState(false);
    const toggle = () => {
        setRegModal(!regModal);
    }
    const registeration = (event) => {
        event.preventDefault();
        if (history.location.pathname === "/register" && regStep != 1) {
            setRegModal(true);
        } else {
            history.push('/register');
            window.location.reload();
        }
    }
    const confirmNewReg = () => {
        dispatch(logout());
        setRegModal(false);
    }
    if (isLogin() == true) {
        return (
            <>
                <header className="ebw-header">
                    <Navbar className="ebw-header__inner ebw-header__inner--postlogin d-none d-lg-flex align-items-center py-0" style={{ flexWrap: 'nowrap' }}>
                        <NavbarBrand className="ebw-header__brandwrap" href="/dashboard">
                            <img className="ebw-header__brand img-fluid mx-auto d-block" src="/images/logo.svg" alt="Logo" />
                        </NavbarBrand >
                        <Nav className="ebw-header__lft d-none d-lg-flex align-items-center mr-auto h-100">
                            <NavItem className="ebw-header__menu-item">
                                <NavLink  to="/dashboard" exact activeClassName="active" className="ebw-header__menu-link d-flex align-items-center" >
                                    <span className="icon-home ebw-font__size-xl ebw-header__menu-icon"></span>
                                    <div className="py-2">Dashboard</div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/projects/all/1" className="ebw-header__menu-link d-flex align-items-center" >
                                    <span className="icon-projects ebw-font__size-xl ebw-header__menu-icon"></span>
                                    <div className="py-2">Projects</div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-header__menu-item ">
                                <NavLink to="/leads" className="ebw-header__menu-link d-flex align-items-center"  >
                                    <span className="icon-buy ebw-font__size-xl ebw-header__menu-icon"></span>
                                    <div className="py-2">Purchased Leads</div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/my-projects" className="ebw-header__menu-link d-flex align-items-center" >
                                    <span className="icon-my-projects ebw-font__size-xl ebw-header__menu-icon"></span>
                                    <div className="py-2">My Projects</div>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <div className="ebw-header__rgt d-none d-lg-flex align-items-center">
                            <NavItem className="ebw-header__menu-item">
                                <div className="d-flex align-items-center" onClick={() => setAddNewService(true)}>
                                    <span className="ebw-icon ebw-icon--xl ebw-header__menu-icon">
                                        <img className="ebw-header__menu-icon--video" src="/images/icons/services-notification.svg" width={30} />
                                    </span>
                                </div>
                            </NavItem>
                            {video_url && video_url.length > 0 &&
                                <NavItem className="ebw-header__menu-item">
                                    <div className="py-1 d-flex align-items-center" onClick={() => setPlayVideo(true)}>
                                        <span className="ebw-icon ebw-icon--xl ebw-header__menu-icon">
                                            <img className="ebw-header__menu-icon--video" src="/images/icons/bulp.svg" />
                                        </span>
                                    </div>
                                    {/* </a> */}
                                </NavItem>}
                            <NavItem className="ebw-header__menu-item">
                                <a target="_blank" href="https://easybids.com/service-provider" className='ebw-header__menu-link'>
                                    <div className="py-1 d-flex align-items-center">
                                        <span className="ebw-header__menu-dwdtxt mr-2 d-none d-xl-block">Download the app</span>
                                        <span className="ebw-icon ebw-icon--xl icon-mobile ebw-header__menu-icon"></span>
                                    </div>
                                </a>
                            </NavItem>

                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/Support" className='ebw-header__menu-link'>
                                    <div className="py-1">
                                        <span className="ebw-icon ebw-icon--xl icon-support ebw-header__menu-icon"></span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem className="ebw-header__menu-item">
                                <div className="ebw-header__menu-link">
                                    <NotificationDropdown
                                        notificationData={firstFiveNotificationsData}
                                        notificationLoading={firstFiveNotificationsLoading}
                                        notificationCount={notificationCount}
                                    />
                                </div>
                            </NavItem>
                            {profile && !profile.is_pro_provider &&
                                <NavItem className="ebw-header__menu-item">
                                    <NavLink to="/pro-plus" className='ebw-header__menu-link d-flex flex-column align-items-center'>
                                        <span className="text-xs">Upgrade to</span>
                                        <img src="/images/icons/icon-pro.svg" className="img-fluid d-block w-100 h-100" alt="Pro +" />
                                    </NavLink>
                                </NavItem>}
                            <NavItem className="ebw-header__menu-item">
                                <div className=" ebw-header__profile">
                                    <HeaderDropdown
                                        profileData={profile}
                                        authData={auth.data}
                                    />
                                </div>
                            </NavItem>
                        </div>
                    </Navbar>
                    {/* Navbar Toggler on Left Side */}
                    <MobileHeader
                        notificationData={firstFiveNotificationsData}
                        notificationLoading={firstFiveNotificationsLoading}
                        notificationCount={notificationCount}
                        profileData={profile}
                        authData={auth.data}
                        video_url={video_url}
                    />
                    {/* Fixed Bottom Nav Tabs */}
                    <MobileNavTabs profileData={profile} video_url={video_url} />
                </header>
                <ProjectInConversation />
                <Modal isOpen={playVideo} size="lg" toggle={() => setPlayVideo(false)}>
                    <div className="">
                        {/* <ModalHeader toggle={() => redirectToReview()}></ModalHeader> */}
                        <ModalBody style={{ lineHeight: 0 }} className="p-0">
                            <iframe width="100%" height="500" src={video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </ModalBody>
                    </div>
                </Modal>
                <Modal isOpen={addNewService} toggle={() => setAddNewService(false)}>
                    <div className="px-5 ebw-modal__wrap">
                        <ModalHeader toggle={() => setAddNewService(false)}></ModalHeader>
                        <ModalBody className="pt-0">
                            <h3 className="text-4xl text-center pb-2">New Services</h3>
                            <div>
                                <NewService />
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </>
        );
    } else {
        return (
            <header className="ebw-header">
                <Navbar className="ebw-header__inner ebw-header__inner--prelogin d-flex align-items-center py-0">
                    <Nav className="ebw-header__lft d-inline-flex align-items-center">
                        <NavbarBrand className="ebw-header__brandwrap" href="/login">
                            <img className="ebw-header__brand img-fluid mx-auto d-block" src="/images/logo.svg" alt="Logo" />
                        </NavbarBrand>
                    </Nav>
                    <Nav className="ebw-header__rgt d-flex align-items-center ml-auto">
                        <NavItem className="ebw-header__menu-item d-none d-md-block">
                            <NavLink to="/Support" className="ebw-header__menu-link">
                                <div className="d-flex align-items-center py-2">
                                    <span className="ebw-icon ebw-icon--xl icon-support ebw-header__menu-icon"></span>
                                    <span className="pl-1">Support</span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className="ebw-header__menu-item">
                            {/* <NavLink exact to="" className=" d-flex align-items-center" > */}
                            <Button color="success" type="submit" onClick={(e) => registeration(e)} text="Register" >Register</Button>
                            {/* </NavLink> */}
                        </NavItem>
                    </Nav>
                </Navbar>
                <Modal isOpen={regModal} toggle={toggle} className="ebw-global__modal">
                    <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                        <ModalBody className="ebw-global__modal-body text-center py-6">
                            <h3 className="text-lg mb-4">Are you sure you want to restart your registration?</h3>

                            <div className="d-flex justify-content-center align-items-center">
                                <div className="">
                                    <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={toggle} >No</Button>
                                </div>
                                <div className="col-4">
                                    <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => confirmNewReg()} >Yes</Button>
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </header>
        );
    }
}

export default Header;