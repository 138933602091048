import React, { useState, useEffect } from "react";
import { Col, Row, Form, Badge, Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Buttons from "../buttons/button-primary";
import { InputText } from "../inputs/text-input";
import validate from "./register-business-validation";
import Textarea from "../inputs/textarea";
import InputNumber from "../inputs/number-input";
import Select from "../inputs/select";
import SearchSelect from "../inputs/search-select";
import { getServedCities } from '../../redux/actions/servedCItiesList';


//- Business Form ==> Register Page 
const RegisterBusiness = (props) => {
    // const dispatch = useDispatch();
    // const cities = useSelector(state => state.servedCities.cities);
    // const citiesLoading = useSelector(state => state.servedCities);
    // const citiesError = useSelector(state => state.servedCities);

    const [state, setState] = useState({
        company: "",
        companyType: "",
        businessAddress: "",
        servedCities: "",
        mileRadius: "",
        cityKey: "",
        currentChangingCityId: "",
        selectedCities: [],
        yearsOfBusiness: "",

        companyError: "",
        companyTypeError: "",
        businessAddressError: "",
        servedCitiesError: "",
        mileRadiusError: "",
        yearsOfBusinessError: "",
        valid: false,

        // Zipode Fix 
        zipcodeList: [],
        zipcodeForMile: "",
        miles_added: "",
        radiusError: "",
        // Zipode Fix 
    });
    // Zipode Fix 
    // const setNewState = (updates) => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         ...updates,
    //     }));
    // };
    const addZipcodeToList = () => {
        // Validation checks
        const zipcode = state.zipcodeList.find(zip => zip.name === state.zipcodeForMile);
        if (zipcode) {
            setState(prevState => ({...prevState, radiusError: "Zipcode already exists"}));
            return;
        }
        if (!state.zipcodeForMile) {
            setState(prevState => ({...prevState, radiusError: "Zipcode is required"}));
            return;
        }
        if (state.zipcodeForMile.length !== 5) {
            setState(prevState => ({...prevState, radiusError: "5-digit zip required"}));
            return;
        }
        if (!state.miles_added) {
            setState(prevState => ({...prevState, radiusError: "Miles radius required"}));
            return;
        }
        if (state.miles_added > 99) {
            setState(prevState => ({...prevState, radiusError: "Miles max limit 99"}));
            return;
        }
        if (state?.zipcodeList?.length >= 5) {
            setState(prevState => ({...prevState, radiusError: "5-digit zipcode required"}));
            return;
        }

        // Add new zipcode to the list
        setState(prevState => ({
            ...prevState,
            zipcodeList: [
                ...(state.zipcodeList || []),
                { name: state.zipcodeForMile, miles_added: state.miles_added }
            ],
            zipcodeForMile: "",
            miles_added: "",
            radiusError: "",
        }));
    };

    const onLocationRemoveHandler = (id) => {
        setState(prevState => ({
            ...prevState,
            zipcodeList: state?.zipcodeList?.filter((_, index) => index !== id),
        }));
    };
    // Zipode Fix 
    const handleInputChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value,
            companyError: "",
            companyTypeError: "",
            businessAddressError: "",
            yearsOfBusinessError: "",
        }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        let selected_cities = []
        if(state?.zipcodeList && state?.zipcodeList!=0){
            for (const zipcode of state?.zipcodeList) {
                selected_cities.push({
                    zipcode: zipcode.name,
                    radius: zipcode.miles_added
                });
            }
        }
        if(!state?.zipcodeList || selected_cities.length==0){
            setState(prevState => ({...prevState, radiusError:"Zipcode Served Required"}));
        }
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = {
            street_address: state.businessAddress,
            zipcode: state.zipCode,
            company_name: state.company,
            company_type: state.companyType,
            provider_locations_attributes: selected_cities,
            years_of_business: state.yearsOfBusiness,
        }
        console.log(data,'data');
        if(selected_cities.length!=0){
            errors.valid && props.handleRegisterBusiness(data);
        }
    }
    const companies = [
        { label: "Company Type", value: "0" },
        { label: "Sole Proprietor", value: "1" },
        { label: "LLC", value: "2" },
        { label: "Corp", value: "3" }
    ]

    return (
        <Form className="ebw-entry__register-form text-center mt-2" onSubmit={handleSubmit}>
            <Row form>
                <Col sm="12">
                    <InputText
                        label="Company Name*"
                        name="company"
                        id="register_company"
                        value={state.company}
                        error={state.companyError}
                        changeEvent={handleInputChange}
                    />
                </Col>

                <Col sm="12">
                    <Select
                        name="companyType"
                        label="Company Type *"
                        id="register_address"
                        value={state.companyType}
                        error={state.companyTypeError}
                        changeEvent={handleInputChange}
                        data={companies}
                    />
                </Col>

                <Col sm="12">
                    <Textarea
                        label="Business Address*"
                        name="businessAddress"
                        id="register_address"
                        value={state.businessAddress}
                        error={state.businessAddressError}
                        changeEvent={handleInputChange}
                    />
                </Col>

                <Col sm="12">
                    <InputNumber
                        label="Zip Code"
                        name="zipCode"
                        id="register_zipcode"
                        value={state.zipCode}
                        error={state.zipCodeError}
                        changeEvent={handleInputChange}
                    />
                </Col>
            </Row>

            <Row form>
                <Col xs="12" className="mb-2 mt-2">
                    <Label className="ebw-profile__type-text ebw-profile__name-gray text-left w-100">Areas Served*</Label>
                </Col>
                <Col xs="5">
                    <SearchSelect
                        label="Zip Code Served*"
                        type="number"
                        name="zipcodeForMile"
                        id="register_cities"
                        value={state.zipcodeForMile}
                        error={state.radiusError}
                        changeEvent={(e) => setState(prevState => ({...prevState, zipcodeForMile: e }))}
                    />
                </Col>
    
                <Col xs="5">
                    <InputNumber
                        label="Miles Radius"
                        name="mileRadius"
                        id="register_miles"
                        error={state.radiusError}
                        value={parseInt(state.miles_added)}
                        changeEvent={(e) => setState(prevState => ({...prevState, miles_added: e.target.value }))}
                    />
                </Col>
    
                <Col xs="2" className="h-100">
                    <a
                        role="button"
                        className="ebw-form__input-select--add my-auto d-block"
                        onClick={()=>addZipcodeToList()}
                        style={{ height: 53, fontSize: 20 }}
                    >
                        +
                    </a>
                </Col>
    
                <div className="es-measured-wrap ml-2">
                    {state?.zipcodeList?.map((data, i) => (
                        <div className="es-measured mr-2 mb-2" key={i} style={{background:'white',padding:10,borderRadius:10,display:'flex'}}>
                            <div className="es-measured-input mr-2">
                                Zip Code: {data.name} - Radius: {data.miles_added} mi
                            </div>
                            <div
                                className="es-measured-close"
                                onClick={() => onLocationRemoveHandler(i)}
                                style={{ cursor: "pointer", color: "#FF6347" }}
                            >
                                X
                            </div>
                        </div>
                    ))}
                </div>
            </Row>

            <Row form>
                <Col sm="12">
                    <InputNumber
                        label="Number of years in business*"
                        name="yearsOfBusiness"
                        id="register_yobus"
                        value={state.yearsOfBusiness}
                        error={state.yearsOfBusinessError}
                        changeEvent={handleInputChange}
                    />
                </Col>
            </Row>

            <Row className="mt-2">
                <Col xs="12" md="4" lg="4" className="mx-auto">
                    <Buttons text="Next" type="submit" />
                </Col>
            </Row>
        </Form>
    );
}
export default RegisterBusiness;