import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

const apiUrl = API.baseURL + `/v1/providers/signup/basic-info`;
async function postApi(data) {
    try {
        const response = axios.post(apiUrl, data, API.options);
        return await response;
    } catch (error) {
        throw error;
    }
}

function* registerPersonal(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
            yield put({ type: 'POST_REGISTER_PERSONAL_SUCCESS', registerPersonal: response.data });
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 2 });
        } else {

            // response.errors.length != 0 && toastr.error('Pegister personal  failed', response.errors[0]);
        }

    } catch (e) {
        yield put({ type: 'POST_REGISTER_PERSONAL_FAILED', message: e.response.data.data.errors });
    }
}

function* registerPersonalSaga() {
    yield takeEvery('POST_REGISTER_PERSONAL_REQUESTED', registerPersonal);
}

export default registerPersonalSaga;