import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../API';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';


async function getApi(data) {
    const apiUrl = API.baseURL + `/notification_list`;
    // const requestOptions = {
    //     method: 'GET',
    //     headers: API.options.headers,
    // };
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* getNotificationList(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.data.code == 200 || response.data.code == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'GET_NOTIFICATION_LIST_SUCCESS', notificationList: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_NOTIFICATION_LIST_FAILED', message: e.message });
    }
}

function* notificationListSaga() {
    yield takeEvery('GET_NOTIFICATION_LIST_REQUESTED', getNotificationList);
}

export default notificationListSaga;