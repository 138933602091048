import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import history from '../../common/history';
import axios from 'axios';

import { setAuth, setIncompleteRegAuth } from '../../utils';
import { toastr } from 'react-redux-toastr';

const apiUrl = API.baseURL + `/auth/sign_in.json`;
async function postApi(data) {
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* login(action) {
    try {
        const auth = yield call(postApi, action.data);
        if (auth.data.signup_status && auth.data.signup_status === "completed") {
            setAuth(auth.data.data, auth.headers);
            history.push('/dashboard');
            window.location.reload();
        } else if (auth.data.signup_status && auth.data.signup_status === "verification_status") {
            setIncompleteRegAuth(auth.data.data, auth.headers);
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 2 });
            yield put({ type: 'POST_REGISTER_PERSONAL_SUCCESS', registerPersonal: auth });
            history.push('/register');
            window.location.reload();
        } else {
            setIncompleteRegAuth(auth.data.data, auth.headers);
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: auth.data.signup_status == "business-info" ? 3 : 5 });
            history.push('/register');
            window.location.reload();
        }
    } catch (e) {
        // toastr.error('Login failed', 'Sorry unable to login successfully');
        yield put({ type: 'POST_LOGIN_FAILED', message: e.response.data.errors[0] });
    }
}

function* loginSaga() {
    yield takeEvery('POST_LOGIN_REQUESTED', login);
}

export default loginSaga;