import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import API from '../../API';
import history from '../../common/history';
import { setRegtwoAuth } from '../../utils/index';
import { toastr } from 'react-redux-toastr';


const apiUrl = API.baseURL + `/v1/providers/signup/additional-info`;
async function postApi(data) {
    try {
        const response = axios.post(apiUrl, data, API.options);
        return await response;
    } catch (error) {
        throw error;
    }
}

function* registerAdditional(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            setRegtwoAuth(true);
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
            yield put({ type: 'POST_REGISTER_ADDITIONAL_SUCCESS', registerPersonal: response.data.data });
            history.push('/dashboard');
            window.location.reload();
        } else {
            // response.errors.length != 0 && toastr.error('Pegister personal  failed', response.errors[0]);
        }
    } catch (e) {
        yield put({ type: 'POST_REGISTER_ADDITIONAL_FAILED', message: e.message });
    }
}

function* registerAdditionalSaga() {
    yield takeEvery('POST_REGISTER_ADDITIONAL_REQUESTED', registerAdditional);
}

export default registerAdditionalSaga;