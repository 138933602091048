import * as type from '../actions/types';

const initialState = {
    categories: [],
    loading: false,
    error: null,
}
export default function setAvailableCategories(state = initialState, action) {
    switch (action.type) {
        case type.STORE_AVAILABLE_CATEGORIES_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.STORE_AVAILABLE_CATEGORIES_SUCCESS:
            return {

                ...state,
                categories: action.data,
                loading: false,
            }
        case type.STORE_AVAILABLE_CATEGORIES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}