import React, { useState, useEffect } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import API from "../../API";
import Modal from "react-modal";
import Cookies from 'js-cookie';
import Loading from "react-loading";
import LoaderModal from "../../components/loaders/LoaderModal";
import Loader from "../../common/loader";
import { Circles, TailSpin,  } from 'react-loader-spinner';

const AddBalanceForm = ({ stripe, elements, onSuccess }) => {
  const [loadingShow, setLoadingShow] = useState(false);
  const [cardsLoader, setCardsLoader] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [error, setError] = useState("");
  const [addNewCard, setAddNewCard] = useState(false);
  const [availableCards, setAvailableCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [amount, setAmount] = useState("");
  const [availableAmounts, setAvailableAmounts] = useState([]);
  const [amountId, setAmountId] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(paymentSuccess);

  const closeModal = () => {
    setIsModalOpen(false);
    onSuccess();
  };
  useEffect(() => {
    fetchAvailableCards();
    fetchAvailableAmounts();
  }, []);

  const fetchAvailableCards = async () => {
    try {
      setCardsLoader(true);
      const response = await axios.get(
        `${API.baseURL}/providers/wallet_get_provider_card_details`,
        API.options
      );
      if (response.data.code === 200) {
        setAvailableCards(response.data.data.stripe_card_details || []);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.error("API Error:", err);
      setError("Failed to fetch card details");
    } finally {
      setCardsLoader(false);
    }
  };

  const fetchAvailableAmounts = async () => {
    try {
      const response = await axios.get(
        `${API.baseURL}/v2/providers/wallet_plans`,
        API.options
      );
      if (response.status === 200) {
        // const amounts = response.data.map((item) => item.amount);
        // const amounts = response.data.map((item) => item.amount);
        // console.log("amountsamountsamounts", response.data);
        setAvailableAmounts(response?.data);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.error("API Error:", err);
      setError("Failed to fetch available amounts");
    }
  };

 const  handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
        console.log("Stripe not loaded");
        return;
    }
    setLoadingShow(true)
    let result;
    if (addNewCard) {
        if (elements.getElement(CardElement)._empty) {
            setError("Enter the card details")
            setLoadingShow(false)
            return;
        }
        result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: cardHolderName,
            },
        });
    } else {
        result = { paymentMethod: { id: selectedCardId } };
    }
    confirmPayment(result);
};
const confirmPayment = async (result) => {
    if (result.error) {
        setError(result.error.message)
        setLoadingShow(false)
    } else {
        const data = {
            user_id: Cookies.get('provider_id'),
            plan_id: amountId,
            payment_method: result.paymentMethod.id,
        };

        try {
            const response = await axios.post(API.baseURL + `/v2/providers/add_amount_to_wallet`, data, API.options);
            if (response.data) {
                setPaymentSuccess(true)
                setLoadingShow(false)
            } else {
                setError(response.data.message)
                setLoadingShow(false)
            }
        } catch (error) {
            console.log("Error:", error);
            setError("Payment confirmation failed")
            setLoadingShow(false)
        }
    }
};



  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  console.log("paymentSuccess", paymentSuccess);
  return (
    <div>
      <form style={{ padding: 10 }}>
        <div style={{paddingBottom:20}}>
          <h6>Select Amount</h6>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {availableAmounts?.map((amt) => (
              <button
                key={amt.id}
                style={{
                  padding: "10px 20px",
                  border: amount === amt?.amount ? "2px solid #3D84FB" : "1px solid #EDF1F2",
                  background: amount === amt?.amount ? "#3D84FB" : "white",
                  color: amount === amt?.amount ? "#FFFFFF" : "#171717",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setAmount(amt.amount);
                  setAmountId(amt.id);
                }}
              >
                ${amt.amount}
              </button>
            ))}
          </div>
        </div>

        {cardsLoader ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: 20
            }}
          >
            <TailSpin
              height="40"
              width="40"
              color="#3D84FB"
              ariaLabel="loading-indicator"
              speed= {3}
            />
          </div>
        ) : (
          <div>
            <h6 className="mb-3">Choose Card</h6>
            {availableCards.map((card) => (
             <div 
              onClick={() => {
                setSelectedCardId(card.id);
                setAddNewCard(false);
              }} 
              key={card.id} 
              style={{ 
                display: "flex", 
                alignItems: "center", 
                marginBottom: "10px", 
                width: "100%",
                backgroundColor: selectedCardId === card.id ? '#F9F9F9' : '#FFFFFF',
                padding: '15px',
                borderRadius: '12px',
                border: selectedCardId === card.id ? '2px solid #3D84FB' : '1px solid #EDF1F2',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out'
              }}
            >
                <img
                  src={card.card_icon}
                  alt="Card"
                  style={{ 
                    width: "40px", 
                    height: "40px", 
                    marginRight: "15px",
                    borderRadius: '50%'
                  }}
                />
                <div style={{ flexGrow: 1 }}>
                  <div style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#171717',
                    marginBottom: '4px'
                  }}>{`**** **** **** ${card.last4_digit}`}</div>
                  <div style={{
                    fontSize: '12px',
                    color: '#666666'
                  }}>{`Expires: ${card.exp_month}/${card.exp_year}`}</div>
                </div>
                <input
                  type="radio"
                  value={card.id}
                  checked={selectedCardId === card.id}
                  onChange={() => {
                    setSelectedCardId(card.id);
                    setAddNewCard(false);
                  }}
                  style={{ 
                    marginRight: "10px",
                    accentColor: '#3D84FB'
                  }}
                />
              </div>
            ))}

            {addNewCard && <CardElement options={CARD_ELEMENT_OPTIONS} />}
          </div>
        )}

        {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}

        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <button
            disabled={!stripe || !amount}
            onClick={handleSubmit}
            style={{
              padding: "10px 30px",
              backgroundColor: (!stripe || amount <= 0 || !selectedCardId)?'#f3f3f3':'#3D84FB',
              color: (!stripe || amount <= 0 || !selectedCardId)?'#8d8d8d':'white',
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              marginRight: "20px",
            }}
          >
            Confirm
          </button>
          <button
            onClick={() => setPaymentSuccess(false)}
            style={{
              padding: "10px 30px",
              background: "white",
              color: "#171717",
              border: "1px solid #EDF1F2",
              borderRadius: "6px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
        </div>
      </form>

      {/* Loader Modal */}
        
        <LoaderModal loadingShow = {loadingShow} message= {'Processing payment... '} closeModal= {closeModal} />

      {paymentSuccess && (
        <Modal
          isOpen={paymentSuccess}
          onRequestClose={closeModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              width: "320px",
              borderRadius: "12px",
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.2)",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: "10px" }}>
              <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="52" height="52" rx="26" fill="#21A145" fillOpacity="0.1" />
                <path d="M35.3334 19L22.5 31.8333L16.6667 26" stroke="#21A145" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <h2 style={{ color: "#333", fontWeight: 'bold', fontSize: "18px", marginBottom: "5px" }}>Payment Successful!</h2>
            <p style={{ fontSize: "14px", color: "#545458", }}>
              Amount successfully added to your wallet.
            </p>
            <button
              onClick={() => {
                setPaymentSuccess(false)
                onSuccess()
              }}
              style={{
                display: "block",
                width: "100%",
                padding: "10px",
                backgroundColor: "#3D84FB",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              DONE
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddBalanceForm;


// {loadingShow && (
//   <div className="loading-overlay">
//     <Loading type="spin" color="#007bff" height={50} width={50} />
//     <p>Processing payment...</p>
//   </div>
// )}


// {paymentSuccess && (
//   <div style={{ textAlign: "center", marginTop: "20px" }}>
//     <p>Payment Successful!</p>
//     <button onClick={onSuccess} style={{ padding: "10px 30px", background: "#3D84FB", color: "white" }}>
//       Done
//     </button>
//   </div>
// )}