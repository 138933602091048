import React, { useState } from "react";
import Modal from "react-modal";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import API from '../../API';
import Cookies from 'js-cookie';  
const stripePromise = loadStripe(API.stripeId);
import "./payment-form.css";
Modal.setAppElement("#root"); // Accessibility setup for React-Modal

const PaymentForm = ({ onClose, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardHolder, setCardHolder] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setError("Stripe is not loaded yet.");
      return;
    }

    if (!cardHolder) {
      setError("Please enter the cardholder name.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);

      // Create a payment method using Stripe
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: cardHolder,
        },
      });

      if (paymentMethod.error) {
        setError(paymentMethod.error.message);
        setLoading(false);
        return;
      }

      console.log("Payment Method:", paymentMethod);
      confirmPayment(paymentMethod);
    } catch (err) {
      console.error("Error creating payment method:", err);
      setError("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  const confirmPayment = async (result) => {
    if (result.error) {
      setError(result.error.message);
      setLoading(false);
    } else {
      const data = {
        payment_method: result.paymentMethod.id,
        user_id: Cookies.get('provider_id')
      };

      try {
        const response = await axios.post(API.baseURL + "/v2/providers/add_card_to_stripe", data, API.options);
        if (response.status === 200) {
          setPaymentSuccess(true);
          setLoading(false);
          onSuccess();
        } else {
          setError(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        setError("Payment confirmation failed");
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          borderRadius: "10px",
          width: "400px",
          maxWidth: "90%",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Add Payment Card</h2>
      <form onSubmit={handleSubmit}>
        {/* Cardholder Name */}
        <div style={{ marginBottom: "20px" }}>
          <label style={{ fontSize: "14px", display: "block", marginBottom: "8px" }}>Card Holder</label>
          <input
            type="text"
            value={cardHolder}
            onChange={(e) => setCardHolder(e.target.value)}
            placeholder="Full Name"
            style={{
              width: "100%",
              padding: "12px",
              fontSize: "14px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          />
        </div>

        {/* Card Details */}
        <div style={{ marginBottom: "20px" }}>
          <label style={{ fontSize: "14px", display: "block", marginBottom: "8px" }}>Card Details</label>
          <div
            style={{
              padding: "12px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <CardElement />
          </div>
        </div>

        {/* Error Message */}
        {error && <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>}

        {/* Submit Button */}
        <button
          type="submit"
          disabled={!stripe || loading}
          style={{
            width: "100%",
            padding: "12px",
            backgroundColor: "#3478F6",
            color: "#fff",
            border: "none",
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {loading ? "Processing..." : "Submit"}
        </button>
      </form>

      {/* Payment Success Modal */}
      {paymentSuccess && (
        <Modal
        isOpen={paymentSuccess}
        onRequestClose={() => {
          setPaymentSuccess(false)
          onClose();
        }}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            // textAlign: 'center',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: '#fff',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <p style={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left'}}>Card Added Successfully!</p>
        <div style={{justifyContent: 'flex-end', textAlign: 'right'}}>
          <button
            onClick={() => onClose()}
            style={{
              // padding: '10px 30px',
              backgroundColor: 'transparent',
              color: 'black',
              border: 'none',
              // borderRadius: '6px',
              cursor: 'pointer',
              textAlign: 'right'
            }}
          >
            OK
          </button>
        </div>
      </Modal>
      )}
    </Modal>
  );
};

const AddCards = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        onClose={() => {props.setShowAddCard(false);console.log("Modal Closed")}}
        onSuccess={props.onSuccess}
        // ShowAddCard={props.ShowAddCard}
      />
    </Elements>
  );
};

export default AddCards;