import React, { useState } from 'react';
import { Modal, ModalBody, Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import { deleteMessage } from "../../redux/actions/deleteMessage";
import { getChat } from "../../redux/actions/getChat";


const DeleteMessage = (props) => {
    const data = {
        provider_id: props.provider_id,
        project_id: props.project_id
    };
    const dispatch = useDispatch();
    const deleteMessageHandle = (id) => {
        dispatch(deleteMessage(id));
        setTimeout(() => {
            dispatch(getChat(data));
        }, 2000);

        props.refreshChat(id);
    };
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <>
            <span onClick={toggle} className="ebw-icon ebw-icon--md icon-delete ebw-project__chat-conv--delete mr-2"></span>
            <Modal isOpen={modal} toggle={toggle}>
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="text-center py-6 ebw-modal__body">
                        <h3 className="text-4xl mb-4">Are you sure want to delete?</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="secondary" type="button" onClick={toggle} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="success" type="submit" onClick={() => deleteMessageHandle(props.message_id)} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default DeleteMessage;