import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import Login from "../containers/login/index";
import DashBoard from "../containers/dashboard/index";
import OtpVerification from "../containers/otp/otp";
import PrivateRoute from './private-route';
import PublicRoute from './public-route';
import CheckLogin from "./checkIslogin";
import Profile from "../containers/profile/index";
import Review from "../containers/review/index";
import ProjectsPage from "../containers/projects";
import Myprojects from "../containers/my-projects/index";
import Buyprojects from "../containers/buy-projects/index";
import Projectdetail from "../containers/project-detail/index";
import Buyprojectdetail from "../containers/buy-projects/buy-project-available-detail";
import BuyProjectContactDetail from "../containers/buy-projects/buy-project-contact-details.js";
import Myprojectinbiddingdetail from "../containers/my-projects/my-projects-inbidding-detail";
import Myprojectcompleteddetail from "../containers/my-projects/my-projects-completed-detail";
import Notification from "../containers/notifications/index";
import RegisterPAge from '../containers/register';
import Support from '../containers/support/index';
import Payment from '../containers/payment/index';
import TermsConditions from "../containers/terms-conditions/index";
import Faqs from '../containers/faqs/index';
import MyWorks from '../containers/my-works/index';
import MyWorksWiewer from "../containers/myWorksViewer";
import ForgotPassword from "../containers/forgot-password";
import PrivacyPolicy from "../containers/privacy-policy/index";
import ProPlus from "../containers/pro-plus/index";
import leadPayment from "../containers/lead-payment";
import NotFoundPage from "../containers/notFound/pageNotFound";
import GetReviess from "../containers/getReviews";
import WalletHistory from "../containers/wallet-history/index";
import NotificationSettings from "../containers/notification-settings/NotificationSettings.jsx";
import Header from '../layout/header';
import Footer from "../layout/footer.js";
export default function AppRouter() {
  return (
    <Router>
      <Header/>
      <Switch>
        <PublicRoute restricted={true} component={Login} path="/login" exact="true" />
        <PublicRoute restricted={false} component={OtpVerification} path="/otp" exact="true" />
        <PublicRoute restricted={false} component={ForgotPassword} path="/forgot-password" exact="true" />
        <CheckLogin restricted={false} path="/" exact="true" />

        <PublicRoute restricted={true} component={RegisterPAge} path="/register" exact="true" />
        <PublicRoute component={Support} path="/support" exact="true" />
        <PublicRoute component={TermsConditions} path="/terms-conditions" exact="true" />
        <PublicRoute component={PrivacyPolicy} path="/privacy-policy" exact="true" />

        <PrivateRoute component={DashBoard} path="/dashboard" exact="true" />
        <PrivateRoute component={Profile} path="/profile" exact="true" />
        <PrivateRoute component={Notification} path="/notifications" exact="true" />
        <PrivateRoute component={Review} path="/review" exact="true" />
        <PrivateRoute component={ProjectsPage} path="/projects/:category/:tab" exact="true" />
        <PrivateRoute component={Myprojects} path="/my-projects" exact="true" />
        <PrivateRoute component={Buyprojects} path="/leads" exact="true" />

        <PrivateRoute component={Projectdetail} path="/project-detail/:id/:from" exact="true" />
        <PrivateRoute component={Buyprojectdetail} path="/buy-project-available-detail" exact="true" />
        <PublicRoute component={Faqs} path="/faqs" exact="true" />
        <PrivateRoute component={MyWorks} path="/my-works" exact="true" />
        <PrivateRoute component={MyWorksWiewer} path="/my-works/:id" exact="true" />
        <PrivateRoute component={WalletHistory} path="/wallet-history" exact="true" />
        <PrivateRoute component={NotificationSettings} path="/notification-settings" exact="true" />
        <PrivateRoute component={Myprojectinbiddingdetail} path="/my-projects-inbidding-detail" exact="true" />
        <PrivateRoute component={Myprojectcompleteddetail} path="/my-project-completed-detail" exact="true" />
        <PrivateRoute component={BuyProjectContactDetail} path="/buy-project-contact-details" exact="true" />
        <PrivateRoute component={Payment} path="/payment" exact="true" />
        <PrivateRoute component={ProPlus} path="/pro-plus" exact="true" />
        <PrivateRoute component={leadPayment} path="/lead-payment/:id/:project_id" exact="true" />
        <PrivateRoute component={GetReviess} path="/get-reviews/:id" exact="true" />
        <PublicRoute path="*" component={NotFoundPage} />
      </Switch>
      <Footer />
    </Router>
  );
}
