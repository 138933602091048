import React from 'react';
import Modal from "react-modal";
import Loading from "react-loading";

function LoaderModal(props) {
    return (
        <Modal
        isOpen={props.loadingShow}
        onRequestClose={props.closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: '80px',
            width: "200px",
            textAlign: "center",
            borderRadius: "10px",
            padding: "10px",
            backgroundColor: "white",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <p style={{ marginTop: "13px", fontSize: "14px", textAlign: 'center' }}>{props.message}</p>
          <Loading type="spin" color="#007bff" height={20} width={20} />
        </div>
      </Modal>
    );
}

export default LoaderModal;