import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loader from 'react-spinners/BounceLoader';
import { Link } from "react-router-dom";
import { MyWorkSlider } from '../../components/sliders/mywork-slider';
import FileUpload from "../../components/common/uploadFile";
import { uploadAlbumFile } from "../../redux/actions/albumFileUpload";
import { getMyWorksFiles } from "../../redux/actions/myWorksFile";
import Main from '../../layout/main';



const MyWorkUploads = (props) => {
    const myWorksfileLoading = useSelector(state => state.uploadAlbumFile.loading);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
    const { myWorksFiles, myWorksFIlesLoading, myWorksFilesError, myWorksData, workId } = props;

    const currentProject = myWorksData && myWorksData.length != 0 && myWorksData.filter(x => x.id == workId)
    const dispatch = useDispatch();
    const [files, setFile] = useState([]);
    const removeFileFromList = (i) => {
        let newState = []
        files.length > 0 && files.forEach((item, index) => {
            index !== i && newState.push(item);
        })
        setFile([...newState])
    };
    const handleChangeFile = (data) => {
        setFile([...files, ...data])
    };
    const uploadFiles = () => {

        if (files.length != 0) {
            for (let index = 0; index < files.length; index++) {
                var formData = new FormData();
                if (files[index].blob !== undefined) {
                    formData.append('document[]', files[index].blob)
                }

                const data = {
                    data: formData,
                    id: workId
                }
                dispatch(uploadAlbumFile(data));
                setTimeout(() => {
                    dispatch(getMyWorksFiles(workId));
                }, 2000);
            }
        }
        setFile([])
    }
    return (
        <Main additionalClass_main="my-work--uploads pb-0">
            {myWorksFIlesLoading ?
                <div className="ebw-data__loader">
                    <Loader loading={myWorksFIlesLoading} />
                </div>

                :

                <section className="ebw-section__pages ebw-section--my-uploads">
                    <div className="ebw-my__uploads-inner">
                        <Container>
                            <Row>
                                <Col sm="12">
                                    <div className="ebw-my__uploads d-flex align-items-center mb-4">
                                        <Link to="/my-works" className="d-flex align-items-center">
                                            {/* <img src="./images/icons/icon-arrow-left.svg" alt="Previous" className="img-fluid d-block" /> */}
                                            <span className="ebw-icon ebw-icon--lg icon-arrow-left"></span>
                                            <span className="pl-1">My Works</span>
                                        </Link>
                                        {/* <h1 className="ebw-title__primary ebw-my__work-title pl-3">{projectName}</h1> */}
                                    </div>
                                </Col>
                                {myWorksFiles && myWorksFiles.documents && myWorksFiles.documents.length > 0 ?
                                    <Col sm="12" className="text-center mb-4">
                                        <div className="ebw-my__uploads-imgwrap py-6 py-md-8">
                                            <MyWorkSlider workId={props.workId} myWorksFiles={myWorksFiles.documents} />
                                        </div>
                                    </Col> :
                                    <Col sm="12" className="text-center">
                                        <div className="ebw-my__uploads-empty my-10">
                                            <h5 className="ebw-my__uploads-empty--title mb-2">No images available</h5>
                                            <p className="ebw-my__uploads-empty--text">Showcase your expertise through photos of completed projects and stand out from the crowd!</p>
                                        </div>
                                    </Col>}


                                <Col sm="12">
                                    <div className="ebw-my__uploads-files">
                                        <FileUpload
                                            type="both"
                                            multiple={true}
                                            files={files}
                                            handleChangeFile={handleChangeFile}
                                            removeFileFromList={removeFileFromList}
                                        />
                                        <div className="text-right">
                                            <button
                                                type="button"
                                                className="ebw-modal__submit ebw-button__primary d-inline-block"
                                                onClick={uploadFiles}
                                            >Upload</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </section>
            }
        </Main>
    );
}
export default MyWorkUploads;