import React, { useState } from 'react';

import { Container, Row, Col, FormGroup, Input, Modal, Form, ModalHeader, ModalBody, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';


import BidAmount from "./bidAmount";
import ProjectBudget from "./projectBudget";
import BidAmountDetailed from "./bidAmountDetailedView";
import FileUpload from "../common/uploadFile";
import { markProjectCompleted } from "../../redux/actions/markCompleteProject";
import { markCancelProject } from "../../redux/actions/markCancelProject";
import Textarea from "../inputs/textarea";

const CompletedAndHiredBidDetails = (props) => {
    console.log(props,'props');
    const { bid_amount,
        bid_type,
        hours,
        bid_detail,
        commissionDetails,
        status,
        id,
        title,
        created_at,
        pending_amount,
        down_payment,
        project_id,
        project_status,
        project_post_type,
        budget,
        projectData
    } = props;
    const dispatch = useDispatch();
    const markcompleteProjectLoading = useSelector(state => state.markcompleteProject.loading);
    const markCancelProjectLoading = useSelector(state => state.markCancelProject.loading);
    const [modal, setModal] = useState(false);
    const [files, setFile] = useState([]);
    const [editItem, setEditItem] = useState("");
    const [reason, setReason] = useState([]);


    const toggle = (item) => {
        setModal(!modal); setModal
        setEditItem(item);
    }

    const handleChangeFile = (data) => {
        setFile([...files, ...data])

    }
    const removeFileFromList = (i) => {
        let newState = []
        files.length > 0 && files.forEach((item, index) => {
            index !== i && newState.push(item);
        })
        setFile([...newState])
    }

    const markComplete = (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append('project_status', "4")

        for (let index = 0; index < files.length; index++) {
            if (files[index].blob !== undefined) {
                formData.append('documents[]', files[index].blob)
            }
        }
        const data = {
            data: formData,
            id: id
        }

        dispatch(markProjectCompleted(data));
        setFile([]);
        setModal(!modal);
        // location.reload();
    };
    const cancelProject = (event) => {
        event.preventDefault();

        const data = JSON.stringify({
            cancellation_request: {
                description: reason,
                requested_user_type: "2",
                status: 1,
                project_id: project_id,
                provider_project_id: id,
            }
        });
        dispatch(markCancelProject(data));
        setReason("");
        setModal(!modal);
        location.reload();

    };
    const reasonInputHanler = (event) => {
        setReason(event.target.value);
    }
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    return (
        <>
            <div className="bid-details-card h-100 p-4 shadow-sm rounded bg-white w-100">
    {/* Header */}
    <div className="pb-3 bid-details-title mb-3">Bid Details</div>
    
    <div className="bid-content">
        <div className="bid-content-wrap">
            {/* Title and Date */}
            <div className="">
                <div className="bid-title">{capitalize(projectData?.title)}</div>
                <div className="bid-date">{projectData?.published_date}</div>
            </div>

            {/* Awarded vs Budget */}
            {projectData?.status === "Awarded" ? (
                <>
                    <div className="bid-subtitle mb-2" >Type : {projectData?.bid_type}</div>
                    <BidAmount 
                        bid_type={projectData?.bid_type} 
                        bid_amount={projectData?.bid_amount} 
                        hours={projectData?.hours} 
                    />
                    <BidAmountDetailed 
                        bid_type={projectData?.bid_type} 
                        bid_amount={projectData?.bid_amount} 
                        hours={projectData?.hours} 
                        pending_amount={projectData?.pending_amount??'0'} 
                        down_payment={projectData?.down_payment??'0'} 
                    />
                </>
            ) : (
                <ProjectBudget budget={projectData?.budget} />
            )}

            {/* Action Buttons */}
            {projectData?.project_status !== 5 && (
                <div className="mt-4">
                    <Button onClick={() => toggle("mark")} className="btn btn-success w-100 p-3">
                        <span>Mark Complete</span>
                        {/* <i className="fas fa-check-circle"></i> */}
                    </Button>
                    <Button onClick={() => toggle("cancel")} className="btn btn-danger mt-2 w-100 p-3">
                        <span>Cancel Project</span>
                        {/* <i className="fas fa-times-circle"></i> */}
                    </Button>
                </div>
            )}
                </div>
            </div>
        </div>
        <Modal isOpen={modal} toggle={toggle}>

                {editItem == "mark" ?
                    <div className="px-5 py-5 ebw-modal__wrap">
                        <ModalHeader toggle={toggle} className="ebw-profile__modal-head px-0 px-md-2 py-0" style={{ border: "none" }}>
                            <div className="ebw-modal__title text-center">Upload your work to highlight your service</div>
                        </ModalHeader>

                        <ModalBody className="ebw-modal__body">
                            <Form className="ebw-modal__form">
                                <FileUpload
                                    type="both"
                                    multiple={true}
                                    files={files}
                                    handleChangeFile={handleChangeFile}
                                    removeFileFromList={removeFileFromList}
                                />
                                <div className="text-right mt-4">
                                    <button onClick={(e) => markComplete(e)} className="ebw-modal__submit ebw-button__primary" type="submit">Update</button>
                                </div>
                            </Form>
                        </ModalBody>
                    </div>
                    :

                    <div className="px-5 py-5 ebw-modal__wrap">
                        <ModalHeader toggle={toggle} className="ebw-profile__modal-head px-0 px-md-2 py-0" style={{ border: "none" }}>
                            <div className="ebw-modal__title text-center">Reason for cancellation</div>
                        </ModalHeader>

                        <ModalBody className="ebw-modal__body px-0 px-md-2">
                            <Form className="ebw-modal__form">
                                <div className="ebw-modal__input-wrap mb-4">
                                    <Textarea
                                        changeEvent={(e) => reasonInputHanler(e)}
                                        name="reason"
                                        value={reason}
                                        id="uploadDP"
                                        className="ebw-modal__textarea py-2"
                                        label="Enter the reason for cancellation here."
                                    />

                                </div>

                                <div className="text-right">
                                    <button
                                        onClick={(e) => cancelProject(e)}
                                        className="ebw-modal__submit ebw-button__primary"
                                        type="submit"
                                    >Update</button>
                                </div>
                            </Form>
                        </ModalBody>
                    </div>
                }

                {/* <div>
                    <div className="ebw-profile__modal-close" onClick={toggle}>&times;</div>
                </div> */}
            </Modal>
        </>
    );
}

export default CompletedAndHiredBidDetails;