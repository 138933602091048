import React from "react"
import ContentLoader from "react-content-loader"
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Row, Col
} from 'reactstrap';

const ReviewsLoader = (props) => (
    <Row>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 220"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="8" /> 
                <rect x="0" y="3" rx="0" ry="0" width="8" height="200" /> 
                <rect x="392" y="1" rx="0" ry="0" width="8" height="200" /> 
                <rect x="-1" y="195" rx="0" ry="0" width="400" height="8" /> 
                <circle cx="68" cy="67" r="37" /> 
                <rect x="127" y="40" rx="5" ry="5" width="217" height="15" /> 
                <rect x="129" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="167" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="207" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="36" y="128" rx="0" ry="0" width="307" height="4" /> 
                <rect x="37" y="143" rx="0" ry="0" width="269" height="4" /> 
                <rect x="39" y="157" rx="0" ry="0" width="196" height="4" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 220"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="8" /> 
                <rect x="0" y="3" rx="0" ry="0" width="8" height="200" /> 
                <rect x="392" y="1" rx="0" ry="0" width="8" height="200" /> 
                <rect x="-1" y="195" rx="0" ry="0" width="400" height="8" /> 
                <circle cx="68" cy="67" r="37" /> 
                <rect x="127" y="40" rx="5" ry="5" width="217" height="15" /> 
                <rect x="129" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="167" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="207" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="36" y="128" rx="0" ry="0" width="307" height="4" /> 
                <rect x="37" y="143" rx="0" ry="0" width="269" height="4" /> 
                <rect x="39" y="157" rx="0" ry="0" width="196" height="4" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 220"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="8" /> 
                <rect x="0" y="3" rx="0" ry="0" width="8" height="200" /> 
                <rect x="392" y="1" rx="0" ry="0" width="8" height="200" /> 
                <rect x="-1" y="195" rx="0" ry="0" width="400" height="8" /> 
                <circle cx="68" cy="67" r="37" /> 
                <rect x="127" y="40" rx="5" ry="5" width="217" height="15" /> 
                <rect x="129" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="167" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="207" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="36" y="128" rx="0" ry="0" width="307" height="4" /> 
                <rect x="37" y="143" rx="0" ry="0" width="269" height="4" /> 
                <rect x="39" y="157" rx="0" ry="0" width="196" height="4" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 220"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="8" /> 
                <rect x="0" y="3" rx="0" ry="0" width="8" height="200" /> 
                <rect x="392" y="1" rx="0" ry="0" width="8" height="200" /> 
                <rect x="-1" y="195" rx="0" ry="0" width="400" height="8" /> 
                <circle cx="68" cy="67" r="37" /> 
                <rect x="127" y="40" rx="5" ry="5" width="217" height="15" /> 
                <rect x="129" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="167" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="207" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="36" y="128" rx="0" ry="0" width="307" height="4" /> 
                <rect x="37" y="143" rx="0" ry="0" width="269" height="4" /> 
                <rect x="39" y="157" rx="0" ry="0" width="196" height="4" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 210"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="8" /> 
                <rect x="0" y="3" rx="0" ry="0" width="8" height="200" /> 
                <rect x="392" y="1" rx="0" ry="0" width="8" height="200" /> 
                <rect x="-1" y="195" rx="0" ry="0" width="400" height="8" /> 
                <circle cx="68" cy="67" r="37" /> 
                <rect x="127" y="40" rx="5" ry="5" width="217" height="15" /> 
                <rect x="129" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="167" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="207" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="36" y="128" rx="0" ry="0" width="307" height="4" /> 
                <rect x="37" y="143" rx="0" ry="0" width="269" height="4" /> 
                <rect x="39" y="157" rx="0" ry="0" width="196" height="4" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 220"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="8" /> 
                <rect x="0" y="3" rx="0" ry="0" width="8" height="200" /> 
                <rect x="392" y="1" rx="0" ry="0" width="8" height="200" /> 
                <rect x="-1" y="195" rx="0" ry="0" width="400" height="8" /> 
                <circle cx="68" cy="67" r="37" /> 
                <rect x="127" y="40" rx="5" ry="5" width="217" height="15" /> 
                <rect x="129" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="167" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="207" y="65" rx="5" ry="5" width="30" height="16" /> 
                <rect x="36" y="128" rx="0" ry="0" width="307" height="4" /> 
                <rect x="37" y="143" rx="0" ry="0" width="269" height="4" /> 
                <rect x="39" y="157" rx="0" ry="0" width="196" height="4" />
            </ContentLoader>
        </Col>
    </Row>
)

export default ReviewsLoader