import React, { useState } from 'react'
import Main from '../../layout/main'
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, CardImg, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const MyProjectInbiddingDetail = () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropdown = () => setDropdownOpen(prevState => !prevState);

    return (
        <>
            <Header/>
            
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Projects Completed Details</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="myprojects-completed">
                <Container>
                    <div className="ebw-prodet mt-10 mb-14">
                        <div className="ebw-prodet__inwrap d-md-flex justify-content-between">
                            <div className="d-md-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <i className="ebw-prodet__angle fa fa-angle-left mr-1" aria-hidden="true"></i>
                                    <div className="ebw-prodet__text">All projects</div>
                                </div>
                                <div className="ebw-prodet__title pl-md-6 ebw-title__primary">Roof repairing</div>
                            </div>
                            <div className="ebw-prodet__more d-flex align-items-center">
                                <div className=" d-flex align-items-center pt-2 pr-4 pb-2 pl-4">
                                    <div className="ebw-prodet__more-text d-flex align-items-center">
                                        <span className="pr-2">More</span>
                                        <span className="ebw-icon ebw-icon--lg icon-more-horizontal ebw-prodet__more-icon"></span>
                                    </div>
                                    {/* <div className="ebw-prodet__round d-flex align-items-center">
                                        <span className="ebw-prodet__round-blue"></span>
                                        <span className="ebw-prodet__round-blue"></span>
                                        <span className="ebw-prodet__round-blue"></span>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="ebw-prodet__row pb-12">
                        <Col sm={12} md={4} className="">
                            <div className="ebw-prodet__title">Customer</div>
                            <div className=" d-flex  ebw-prodet__profile pt-4">
                                <img src="./images/profile/profile.png" className="ebw-prodet__img ebw-header__profile-img"></img>
                                <div className="ebw-prodet__details pl-4">
                                    <div className="ebw-prodet__name">Jessie Prado</div>
                                    <div className="d-flex pt-1">
                                        <span className="ebw-icon ebw-icon--lg icon-location"> </span>
                                        <div className="ml-1">Customers full address after hired Lemon grove, California</div>
                                    </div>
                                    <div className="d-flex justify-content-between pt-7">
                                        <div className="d-flex align-items-center">
                                            <i className="ebw-icon ebw-icon--lg icon-phone" aria-hidden="true"></i>
                                            <div className="ebw-myproject__customer-call pl-1">+1 12323 02934</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4}></Col>

                    </Row>
                    <div className="mt-6">
                        <div className="ebw-prodet__title ebw-prodet__title-project">Project Description</div>
                        <div className="ebw-prodet__description pt-4">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem Sed ut perspiciatis unde omnis iste natus error sit voluptatem nde omnis iste natus error sit voluptatem Sed ut perspiciatis unde omnis iste natus error sit voluptatemtis unde omnis is tis unde omnis is
                        </div>
                    </div>
                    <Row className="mt-14">
                        <Col md={4} className="">
                            <div>
                                <div className="ebw-prodet__dimension">Dimension</div>
                                <div className="d-flex align-items-center mt-1">
                                    <div className="ebw-prodet__dimension">Width :</div>
                                    <div className="ebw-prodet__dimension-grey ml-3">2.6 feet</div>
                                    <div className="ebw-prodet__dimension-grey ml-2">1 Inch</div>
                                </div>
                                <div className="d-flex align-items-center mt-1">
                                    <div className="ebw-prodet__dimension">Height :</div>
                                    <div className="ebw-prodet__dimension-grey ml-3">5 feet</div>
                                    <div className="ebw-prodet__dimension-grey ml-2">3 Inch</div>
                                </div>
                            </div>
                            <div className="ebw-prodet__show mt-5 d-flex align-items-center">
                                <span>Show more</span>
                                <span className="ebw-icon ebw-icon--md icon-arrow-down ebw-prodet__angle ml-1"></span>
                            </div>

                            <div md={8} className="d-md-flex ebw-prodet__roof">
                                <div className="pt-8 ebw-prodet__roofscope-content">
                                    <div className="ebw-prodet__dimension">Type of roof</div>
                                    <div className="ebw-prodet__dimension-grey pt-1">Stone Coated Steel</div>
                                    <div className="ebw-prodet__dimension pt-4">Removal & haul away</div>
                                    <div className="ebw-prodet__dimension-grey pt-1">Stone Coated Steel</div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="ebw-prodet__roofscope ">
                                <div className="d-flex align-items-center mt-1 mb-1 ebw-prodet__roofscope-text">
                                    {/*<img className="ebw-prodet__file-icon ml-2" src="../images/icons/file-icon.png"></img>*/}
                                    <span className="ebw-icon ebw-icon--md icon-document ml-2"></span>
                                    <span className="ml-1 pt-1 pb-1">Roofscope data available now.</span>
                                </div>
                                <img className="ebw-prodet__map mt-2" src="./images/projectdetails/detail-map.png"></img>
                            </div>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                </Container>
            </Main>

            <Footer/>
        </>
    )
}

export default MyProjectInbiddingDetail

