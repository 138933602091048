import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';


async function postApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/projects/${data.project_id}/bid`;
    // const postData = JSON.stringify({
    //     bid_amount: data.bid_amount,
    //     bid_breakdown: data.bid_breakdown,
    //     bid_type: data.bid_type?1 : 0,
    //     hours: data.hours,
    //     'bid[documents_attributes][][document]':[]
    // });
    const formData = new FormData();
    formData.append('bid_amount', data.bid_amount);
    formData.append('bid_breakdown', data.bid_breakdown);
    formData.append('bid_type', data.bid_type == "hourly" ? 1 : 0);
    formData.append('hours', data.bid_estimated_hours);
    formData.append('bid[documents_attributes][][document]', "");
    if (data.status != "In-Bidding") {
        try {
            const response = axios.post(apiUrl, formData, API.options)
            return await response;
        } catch (error) {
            throw error;
        }
    } else {
        try {
            const response = axios.put(apiUrl+'/'+data.bid_id, formData, API.options)
            return await response;
        } catch (error) {
            throw error;
        }
    }


}

function* submitBid(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            location.reload();
        } 
        yield put({ type: 'POST_SUBMIT_BID_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'POST_SUBMIT_BID_FAILED', message: "You can not bid the project!" });
    }
}

function* submitBidSaga() {
    yield takeEvery('POST_SUBMIT_BID_REQUESTED', submitBid);
}

export default submitBidSaga;