import * as type from '../actions/types';
const initialState = {
    inBidding: [],
    offset: 0,
    loading: false,
    error: null,
}
export default function inBiddingProjectsData(state = initialState, action) {
    switch (action.type) {
        case type.GET_ALL_IN_BIDDING_PROJECTS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ALL_IN_BIDDING_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                available: action.data,
                offset: 0,
            }
        case type.GET_ALL_IN_BIDDING_PROJECTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}