import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';

async function postApi(data) {
    const apiUrl = API.baseURL + `/cancellation_request`;
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* projectCancel(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            yield put({ type: 'POST_PROJECT_CANCEL_SUCCESS', registerPersonal: response.data.data });
            // toastr.success('Cancelled', 'Cancelled successfully');
            window.reload();
        } else {
            // response.errors.length != 0 && toastr.error('Cancellation Failed', response.errors[0]);
        }
        location.reload();
    } catch (e) {
        yield put({ type: 'POST_PROJECT_CANCEL_FAILED', message: e.message });
    }
}

function* markProjectCancel() {
    yield takeEvery('POST_PROJECT_CANCEL_REQUESTED', projectCancel);
}

export default markProjectCancel;