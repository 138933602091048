import React, { useEffect } from 'react';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { useSelector, useDispatch } from 'react-redux';
import Projectdetail from "./project-detail";
import { getCurrentProject } from "../../redux/actions/currentProject";
import { getCommissionDetails } from "../../redux/actions/commissionDetails";
import { projectVideosData } from "../../redux/actions/projectVideos";
import { relatedProjects } from "../../redux/actions/relatedProjects";;


const ProjectDetailPage = (props) => {
    const { project_id, id, from } = props.match.params;
    console.log(props,'props');
    // log
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCurrentProject(id));
        // dispatch(getCommissionDetails());
        // dispatch(projectVideosData(id));
        // dispatch(relatedProjects(id))
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [project_id, id, from])
    const projectDataNotFound = useSelector(state => state.currentProjectData.notFound);
    const projectData = useSelector(state => state.currentProjectData.projectData);
    const projectDataLoading = useSelector(state => state.currentProjectData.loading);
    const projectDataError = useSelector(state => state.currentProjectData.error);
    const commissionDetails = useSelector(state => state.commissionDetails.commissionDetails);
    const projectVideos = useSelector(state => state.projectVideos.data);
    // const relatedProjects = useSelector(state => state.relatedProjects.data);
    console.log(from,'from');
    return (
        <Projectdetail
            projectDataNotFound={projectDataNotFound}
            projectData={projectData}
            projectDataLoading={projectDataLoading}
            projectDataError={projectDataError}
            commissionDetails={commissionDetails}
            projectVideos={projectVideos}
            from={from}
        />
    );
}

export default ProjectDetailPage;