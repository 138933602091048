import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import MyProjects from "./my-projects";
import { allProjectsData } from "../../redux/actions/allProjects";
import { getProfileEditData } from "../../redux/actions/profileEditData";
import { inBiddingProjectsData } from '../../redux/actions/inBiddingProjects';
import { hiredProjectsData } from '../../redux/actions/hiredProjects';
const MyProjectsPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(allProjectsData());
        dispatch(inBiddingProjectsData());
        dispatch(getProfileEditData());
        dispatch(hiredProjectsData())
    }, [])

    return (
        <MyProjects />
    );
}

export default MyProjectsPage;