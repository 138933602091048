import React from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Main from "../../layout/main";
import axios from 'axios';
import API from '../../API';

const GetFeedBack = (props) => {
    const { id } = props;
    const shareWith = () => {
        axios.get(API.baseURL + `/reviews/get_generate_link?provider_id=${id}`, API.options).then(response => response.data)
            .then((res) => {
                if (res && res.data && res.data.secure_number) {
                    if (navigator.share) {
                        navigator.share({
                            title: 'Review my work',
                            text: 'We had a great time working on your project & hope that you are happy with your new space. What did you think of our work? Let us know here',
                            // url: `https://easybids.com/provider-rating/${res.data.secure_number}`,
                            url: ` https://staging.easybids.com/provider-rating/${res.data.secure_number}`,
                            dialogTitle: 'Share via',
                        })
                            .then(() => console.log(''))
                            .catch((error) => console.log(''));
                    }
                }
            }).catch((error) => {
            });
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Have questions about EasyBids pro ? We strive to constantly listen to the questions we receive and build out helpful resources to quickly address such inquiries." />
                <title>Share review link</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="faqs pb-0">
                <section className="ebw-section ebw-section--getreview">
                    <div className="ebw-faqs__inner">
                        <Container>
                            <Row>
                                <Col className="ebw-greviews__col" sm="6">
                                    <div className="ebw-greviews__wrap">
                                        <div className="ebw-greviews__image mb-3 text-center">
                                            <img src="/images/get-review/get-review.svg" alt="" />
                                        </div>
                                        <div className="ebw-greviews__content">
                                            <div className="ebw-greviews__content-info mb-4">
                                                <div className="ebw-greviews__info ebw-greviews__info--bold mb-2">Easy Bids has a new convenient way for you to build your star rating and win more bids. </div>
                                                <div className="ebw-greviews__info">It’s Easy, just follow these 3 steps:</div>
                                            </div>
                                            <div className="ebw-greviews__steps mb-4">
                                                <div className="ebw-greviews__step dflex flex-wrap mb-2">
                                                    <div className="ebw-greviews__step-left text-center">
                                                        <div className="">STEP</div>
                                                        <div className="ebw-greviews__step-number">1</div>
                                                    </div>
                                                    <div className="ebw-greviews__step-right">
                                                        <div className="ebw-greviews__step-title">Share a review link</div>
                                                        <div className="ebw-greviews__step-para">Share the review link via WhatsApp or text message with previous & new customers</div>
                                                    </div>
                                                </div>
                                                <div className="ebw-greviews__step dflex flex-wrap mb-2">
                                                    <div className="ebw-greviews__step-left text-center">
                                                        <div className="">STEP</div>
                                                        <div className="ebw-greviews__step-number">2</div>
                                                    </div>
                                                    <div className="ebw-greviews__step-right">
                                                        <div className="ebw-greviews__step-title">Request a review</div>
                                                        <div className="ebw-greviews__step-para">Request your customer to review your quality of work & share their experience</div>
                                                    </div>
                                                </div>
                                                <div className="ebw-greviews__step dflex flex-wrap">
                                                    <div className="ebw-greviews__step-left text-center">
                                                        <div className="">STEP</div>
                                                        <div className="ebw-greviews__step-number">3</div>
                                                    </div>
                                                    <div className="ebw-greviews__step-right">
                                                        <div className="ebw-greviews__step-title">Review Page</div>
                                                        <div className="ebw-greviews__step-para">Thank your customers. Those reviews will be displayed on your review page</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Button onClick={() => shareWith()} className="ebw-button ebw-button__primary btn btn-secondary w-100">SHARE THE REVIEW LINK NOW!</Button>
                                            <div className="ebw-greviews__bottom">
                                                <div className="ebw-greviews__tick-wrap">
                                                    <img src="/images/icons/tick.svg" />
                                                </div>
                                                <div className="ebw-greviews__conformation-text">The reviews are also shown to customers whenever you place a bid on their projects, increasing the chances of winning the project.</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default GetFeedBack;