import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import {
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import TempDropdown from '../buttons/dropdown';
import Range from "../range/range";
import DatePickerRange from "./dateRangePicker"
import VALUES from "../../values/values"

const Filter = (props) => {
    const { currentData, wholeData, otherFilterActive } = props;
    const profiledata = useSelector(state => state.getProfileEditData);
    const locations = profiledata && profiledata.profileEdit && profiledata.profileEdit.provider_locations && profiledata.profileEdit.provider_locations;

    const [state, setState] = useState({
        startDate: "",
        endDate: "",
        location: "All",
        budgetRangeStart: 0,
        budgetRangeEnd: 0,
    });

    const filterCondition = (city, startDate, endDate, wholeData) => {
        let outData = [];
        if (startDate == "" && city == "All") {
            outData = wholeData;
        } else if (startDate == "" && city != "All") {
            outData = wholeData.filter(x => x.city.split(',')[0] == city);
        } else if (startDate != "" && city == "All") {
            outData = wholeData.filter(x => x.time_stamp_created_at > startDate && x.time_stamp_created_at < endDate);
        } else if (startDate != "" && city != "All") {
            const dateData = wholeData.filter(x => x.time_stamp_created_at > startDate && x.time_stamp_created_at < endDate);
            outData = dateData.filter(x => x.city.split(',')[0] == city);
        }
        return outData;
    };
    const setDateAll = () => {
        setState((prevProps) => ({
            ...prevProps,
            endDate: "",
            startDate: "",
        }));
        const newData = filterCondition(state.location, "", "endDate", wholeData);
        props.storeFilterdArray(newData)
        props.filterUpdated(newData);
    }
    const setCityHandler = (data) => {
        const dataToProjects = {
            ...state,
            location: data,
        };
        setState((prevProps) => ({
            ...prevProps,
            location: data,
        }));
        const newData = filterCondition(data, state.startDate, state.endDate, wholeData);
        props.storeFilterdArray(newData)
        props.filterUpdated(newData);
    }

    const getStartEndDates = (dates, states) => {
        const dataToProjects = {
            ...state,
            startDate: dates.start.format("YYYY-MM-DD"),
            endDate: dates.end.format("YYYY-MM-DD"),
        };
        setState((prevProps) => ({
            ...prevProps,
            startDate: dates.start.format("YYYY-MM-DD"),
            endDate: dates.end.format("YYYY-MM-DD"),
        }));
        const newData = filterCondition(state.location, dates.start.format("YYYY-MM-DD"), dates.end.format("YYYY-MM-DD"), wholeData);
        props.storeFilterdArray(newData)
        props.filterUpdated(newData);

    }
    useEffect(() => {
        props.storeFilterdArray(wholeData);
    }, []);

    // DO NOT REMOVE OR MODIFY THIS COMMENTED CODE
    // const resetSliderValue = () => {
    //     setState((prevProps) => ({
    //         ...prevProps,
    //         budgetRangeStart: 10,
    //         budgetRangeEnd: 80,
    //     }));
    // }

    // const changeSliderValueHandler = (data) => {
    //     const dataToProjects = {
    //         ...state,
    //         budgetRangeStart: data[0],
    //         budgetRangeEnd: data[1],
    //     };
    //     setState((prevProps) => ({
    //         ...prevProps,
    //         budgetRangeStart: data[0],
    //         budgetRangeEnd: data[1],
    //     }));
    //     const rangeValueStart = (VALUES.filter_range_max / 100) * data[0];
    //     const rangeValueEnd = (VALUES.filter_range_max / 100) * data[1]
    //     const newData = newDataRange(wholeData, rangeValueStart, rangeValueEnd)
    //     // wholeData && wholeData.length != 0 && wholeData.filter(x => x.budget < rangeValueEnd && x.budget > rangeValueStart);
    //     const newDataChecked = otherFilterActive ? checkInCurrentData(newData) : newData;

    //     props.storeFilterdArray(newDataChecked)
    //     props.filterUpdated(dataToProjects);
    // }

    // DO NOT REMOVE OR MODIFY THIS COMMENTED CODE





    return (
        <div className="d-flex flex-wrap align-items-center justify-content-md-end justify-content-md-around">
            {/* <TempDropdown dropdownName="Budget" dropdownsubName={`${state.budgetRangeStart} - ${state.budgetRangeEnd}`} additionalClassName=" ebw-button__dropdown--white">
                <DropdownMenu className="ebw-button__dropdown--reset-menu" right>
                    <div className="ml-3 mr-3">
                        <Range
                            changeSliderValueHandler={changeSliderValueHandler}
                            budgetRangeStart={state.budgetRangeStart}
                            budgetRangeEnd={state.budgetRangeEnd}
                        />
                        <div className="d-flex  justify-content-end ebw-button__dropdown--reset pt-3" onClick={() => resetSliderValue()}>Reset</div>
                    </div>
                </DropdownMenu>
            </TempDropdown> */}
            {/* {`${state.startDate} - ${state.endDate}`}  */}
            <TempDropdown dropdownName="Date" dropdownsubName={state.startDate != "" ? `${state.startDate} - ${state.endDate}` : "All"} additionalClassName="ebw-button__dropdown--white mr-4 mb-2 mb-md-0" >
                <DropdownMenu className="ebw-button__dropdown--date-menu" right>
                    {/* <DatePicker className="ebw-button__dropdown--date ml-2" selected={startDate} onChange={date => setDatesHandler(date)} /> */}
                    <div className="ebw-tab__filter-dropdown--reset d-flex ml-auto px-0 btn btn-link ml-3" onClick={() => setDateAll()}>Reset</div>
                    <DatePickerRange
                        getStartEndDates={getStartEndDates}
                        wholeData={wholeData}
                    />

                </DropdownMenu>
            </TempDropdown>

            <TempDropdown dropdownName="Location:" dropdownsubName={state.location} additionalClassName=" ebw-button__dropdown--white mb-2 mb-md-0" >
                <DropdownMenu right>
                    <DropdownItem onClick={() => setCityHandler("All")}>All</DropdownItem>
                    {locations && locations.length != 0 && locations.map((city, index) => {
                        return (
                            <DropdownItem key={index} onClick={() => setCityHandler(city.city)}>{city.city}</DropdownItem>
                        )
                    })}


                </DropdownMenu>
            </TempDropdown>
        </div>
    );
}

export default Filter;