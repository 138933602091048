import React, { useState, useEffect } from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import CardSecondary from '../../components/card/card-secondary';
import Main from '../../layout/main'
import ProjectsLoader from "../../components/projects/projectsSkeleton"
import API from '../../API';
import axios from 'axios';
const Myprojects = (props) => {
    const inbiddingData = useSelector(state => state.inBiddingData.available)
    const { projectsData } = props
    const [hiredData, sethiredData] = useState([])
    const [completedData, setcompletedData] = useState([])
    const [bookmarksData, setbookmarksData] = useState([])
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    useEffect(() => {
        axios.get(API.baseURL + `/v2/providers/my_projects/in_processing`, API.options)
        .then(response => {
            console.log(response,'response');
            sethiredData(response?.data?.data?.projects)
        })
        axios.get(API.baseURL + `/v2/providers/my_projects/history`, API.options)
        .then(response => {
            console.log(response,'response');
            setcompletedData(response?.data?.data?.projects)
        })
        axios.get(API.baseURL + `/v2/providers/activities/bookmark`, API.options)
        .then(response => {
            console.log(response,'response');
            setbookmarksData(response?.data?.data)
        })
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Check out all your projects at a single place.The projects you bidded,You bought and the bids approved.Check everything at a single place" />
                <title>My Projects - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="myprojects">
                <Nav tabs className="ebw-projects__navtab">
                    <Container>
                        <Row>
                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    In-Bidding
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Hired
                                </NavLink>
                            </NavItem>

                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    Completed
                                </NavLink>
                            </NavItem>

                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { toggle('4'); }}
                                >
                                    Bookmarks
                                </NavLink>
                            </NavItem>

                            {/* <NavItem className="ebw-tab__item ml-auto">
                                <CategoryDropdown />
                            </NavItem> */}
                        </Row>
                    </Container>
                </Nav>
                <section className="ebw-section ebw-section__pages">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Container>
                                <>
                                    <div className="d-md-flex justify-content-between">
                                        <div className="ebw-title__primary">In-Bidding</div>
                                    </div>
                                    {projectsData.loading ?
                                        <div className="ebw-data__loaderr mt-4">
                                            <ProjectsLoader />
                                        </div> :
                                        <>
                                            {inbiddingData?.length == 0 &&
                                                <Row>
                                                    <Col sm="12  mt-4" >
                                                        <div className="ebw-section__empty position-relative">
                                                            <div className="ebw-section__empty-imgs w-100">
                                                                <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                            </div>
                                                            <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                                <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Place winning bids after carefully assessing the available projects.</div>
                                                                <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                {inbiddingData?.length !== 0 && inbiddingData?.map((projectInBidding, index) => {
                                                        return (
                                                            <Col sm="6" lg="4" className="mt-4" key={projectInBidding.id}>
                                                                <CardSecondary
                                                                    projectName={projectInBidding.name}
                                                                    bidAmount={projectInBidding.bid_amount}
                                                                    bidtype={projectInBidding.bid_type}
                                                                    projectEstimationHour={projectInBidding.hours}
                                                                    projectImage={projectInBidding.project_image_url}
                                                                    projectDate={projectInBidding.created_at}
                                                                    projectCategory={projectInBidding.project_type}
                                                                    projectLocation={projectInBidding.city}
                                                                    projectLinks={`/project-detail/${projectInBidding.id}/my_projects`}
                                                                    Estimate
                                                                    project_post_type={projectInBidding.project_post_type}
                                                                    status={projectInBidding.status}
                                                                    project_status={projectInBidding.status}
                                                                    lead_purchased={projectInBidding.lead_purchased}
                                                                    noShowLeadCard={true}
                                                                    bookmark={projectInBidding.bookmarked_by_current_provider}
                                                                    project_id={projectInBidding.id}
                                                                />
                                                            </Col>
                                                        )
                                                    }
                                                )}
                                            </Row>
                                        </>
                                    }
                                </>

                            </Container>

                        </TabPane>

                        <TabPane tabId="2">
                            <Container>

                                <>
                                    <div className="d-md-flex justify-content-between">
                                        <div className="ebw-title__primary">Hired</div>
                                    </div>
                                    {projectsData.loading ?
                                        <div className="ebw-data__loader mt-4">
                                            <ProjectsLoader loading={projectsData.loading} />
                                        </div> :
                                        <>
                                            {hiredData && hiredData.length == 0 &&
                                                <Row>
                                                    <Col sm="12  mt-4" >
                                                        <div className="ebw-section__empty position-relative">
                                                            <div className="ebw-section__empty-imgs w-100">
                                                                <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                            </div>
                                                            <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                                <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Get hired by placing winning bids on projects within your area of expertise.</div>
                                                                <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                {hiredData.length != 0 && hiredData.map((projectHired, index) => {
                                                    return (
                                                            <Col key={index} sm="6" lg="4" className=" mt-4">
                                                                <CardSecondary
                                                                    projectName={projectHired.name}
                                                                    bidAmount={projectHired.bid_amount}
                                                                    bidtype={projectHired.bid_type}
                                                                    projectEstimationHour={projectHired.hours}
                                                                    projectImage={projectHired.project_image_url}
                                                                    projectDate={projectHired.created_at}
                                                                    projectCategory={projectHired.project_type}
                                                                    projectLocation={projectHired.city}
                                                                    projectLinks={`/project-detail/${projectHired.id}/my_projects`}
                                                                    Estimate
                                                                    project_post_type={projectHired.project_post_type}
                                                                    status={projectHired.status}
                                                                    project_status={projectHired.status}
                                                                    lead_purchased={projectHired.lead_purchased}
                                                                    bookmark={projectHired.bookmarked_by_current_provider}
                                                                    project_id={projectHired.id}
                                                                />
                                                            </Col>
                                                        )
                                                }
                                                )}
                                            </Row></>}
                                </>

                            </Container>
                        </TabPane>

                        <TabPane tabId="3">
                            <Container>

                                <>
                                    <div className="d-md-flex justify-content-between">
                                        <div className="ebw-title__primary">Completed</div>
                                    </div>
                                    {
                                        projectsData.loading ?
                                            <div className="ebw-data__loader  mt-4">
                                                <ProjectsLoader loading={projectsData.loading} />
                                            </div> :
                                            <>
                                                {!completedData &&
                                                    <Row>
                                                        <Col sm="12  mt-4" >
                                                            <div className="ebw-section__empty position-relative">
                                                                <div className="ebw-section__empty-imgs w-100">
                                                                    <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                                </div>
                                                                <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                                    <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Bid, Win, Complete, Repeat! Grow your business with just a click</div>
                                                                    <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                {completedData && completedData.length == 0 &&
                                                    <Row>
                                                        <Col sm="12  mt-4" >
                                                            <div className="ebw-section__empty position-relative">
                                                                <div className="ebw-section__empty-imgs w-100">
                                                                    <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                                </div>
                                                                <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                                    <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Bid, Win, Complete, Repeat! Grow your business with just one click.</div>
                                                                    <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    {completedData.length != 0 && completedData.map((projectCompleted, index) => {
                                                        return (
                                                                <Col key={index} sm="6" lg="4" className="mt-4">
                                                                    <CardSecondary
                                                                        projectName={projectCompleted.name}
                                                                        bidAmount={projectCompleted.bid_amount}
                                                                        bidtype={projectCompleted.bid_type}
                                                                        projectEstimationHour={projectCompleted.hours}
                                                                        projectImage={projectCompleted.project_image_url}
                                                                        projectDate={projectCompleted.created_at}
                                                                        projectCategory={projectCompleted.project_type}
                                                                        projectLocation={projectCompleted.city}
                                                                        projectLinks={`/project-detail/${projectCompleted.id}/my_projects`}
                                                                        Estimate
                                                                        project_post_type={projectCompleted.project_post_type}
                                                                        status={projectCompleted.status}
                                                                        project_status={projectCompleted.status}
                                                                        lead_purchased={projectCompleted.lead_purchased}
                                                                        bookmark={projectCompleted.bookmarked_by_current_provider}
                                                                        project_id={projectCompleted.id}
                                                                    />
                                                                </Col>
                                                            )
                                                    }
                                                    )}
                                                </Row></>}
                                </>
                            </Container>
                        </TabPane>

                        <TabPane tabId="4">
                            <Container>
                                <>
                                    <div className="d-md-flex justify-content-between">
                                        <div className="ebw-title__primary">Bookmarks</div>
                                    </div>
                                    {
                                        projectsData.loading ?
                                            <div className="ebw-data__loader mt-4">
                                                <ProjectsLoader loading={projectsData.loading} />
                                            </div> :
                                            <>
                                                {!bookmarksData &&
                                                    <Row>
                                                        <Col sm="12  mt-4" >
                                                            <div className="ebw-section__empty position-relative">
                                                                <div className="ebw-section__empty-imgs w-100">
                                                                    <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                                </div>
                                                                <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                                    <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Browse, Bid, Build, Repeat! Turn bookmarks into successful projects with just one click!</div>
                                                                    <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                {bookmarksData && bookmarksData.length == 0 &&
                                                    <Row>
                                                        <Col sm="12  mt-4" >
                                                            <div className="ebw-section__empty position-relative">
                                                                <div className="ebw-section__empty-imgs w-100">
                                                                    <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                                </div>
                                                                <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                                    <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Browse, Bid, Build, Repeat! Turn bookmarks into successful projects with just a click!</div>
                                                                    <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    {bookmarksData.length != 0 && bookmarksData.map((projectbookmarks, index) => {
                                                        return (
                                                                <Col key={index} sm="6" lg="4" className="mt-4">
                                                                    <CardSecondary
                                                                        projectName={projectbookmarks.name}
                                                                        bidAmount={projectbookmarks.bid_amount}
                                                                        bidtype={projectbookmarks.bid_type}
                                                                        projectEstimationHour={projectbookmarks.hours}
                                                                        projectImage={projectbookmarks.project_image_url}
                                                                        projectDate={projectbookmarks.created_at}
                                                                        projectCategory={projectbookmarks.project_type}
                                                                        projectLocation={projectbookmarks.city}
                                                                        projectLinks={`/project-detail/${projectbookmarks.id}/my_projects`}
                                                                        Estimate
                                                                        project_post_type={projectbookmarks.project_post_type}
                                                                        status={projectbookmarks.status}
                                                                        project_status={projectbookmarks.status}
                                                                        lead_purchased={projectbookmarks.lead_purchased}
                                                                        bookmark={projectbookmarks.bookmarked_by_current_provider}
                                                                        project_id={projectbookmarks.id}
                                                                    />
                                                                </Col>
                                                            )
                                                    }
                                                    )}
                                                </Row></>}
                                </>
                            </Container>
                        </TabPane>
                    </TabContent>
                </section>
            </Main>
        </div>
    );
}

const mapStateToProps = state => ({
    projectsData: state.allProjectsData,
    inbiddingData: state.inbiddingData,
});
export default connect(mapStateToProps, {})(Myprojects);