import React, { Component } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TempDropdown extends Component {

    constructor(props){
        super(props);
    
        this.state = {
            isOpen: false
        }
    }
    
    toggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }
    
    render(){
        return(
            <Dropdown className={`ebw-dropdown ${this.props.additionalClassName}`} group isOpen={this.state.isOpen} toggle={this.toggle}  style={{height:52}}>
                <DropdownToggle className="menu-button" caret>
                    {this.props.dropdownName}
                    <span className="ebw-button__dropdown--All pl-2">{this.props.dropdownsubName}</span>
                    <span className="ebw-button__dropdown--Allwhite pl-1 pr-1">{this.props.dropdownAll}</span>

                    {/* Green dropdown bid details  */}
                    {/* <div className="ebw-button__dropdown--bid">
                        <div className="ebw-button__dropdown--bidwrap">
                            <img className="ebw-prodet__bid-image ml-3" src="./images/projectdetails/icon-hammer.png"></img>
                            <span className="ml-1">{this.props.dropdownbidName}</span>
                        </div>
                    </div> */}

                    {/* Ignore Dropdown bid details */}
                    {/* <div className="ebw-button__dropdown--bid ebw-button__dropdown--ignore">
                        <div className="ebw-button__dropdown--bidwrap">
                            <img className="ebw-prodet__bid-image ml-3" src="../images/projectdetails/icon-hammer.png"></img>
                            <span className="ml-1">{this.props.dropdownignoreName}</span>
                        </div>
                    </div> */}

                    {/* Ask Dropdown bid details */}
                    {/* <div className="ebw-button__dropdown--bid ebw-button__dropdown--ask">
                        <div className="ebw-button__dropdown--bidwrap">
                            <span className="ml-1">{this.props.dropdownaskName}</span>
                        </div>
                    </div> */}

                </DropdownToggle>
                {this.props.children}
            </Dropdown>
        )
    }
}

export default TempDropdown