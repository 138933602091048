import React from "react"
import ContentLoader from "react-content-loader"
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Row, Col
} from 'reactstrap';

const ProjectDetailsLoader = (props) => (
    <Row className="align-items-start py-5">
        <Col sm="12">
            <ContentLoader 
                    speed={23}
                    width="100%"
                    height="100%"
                    viewBox="0 0 600 85"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="0" y="25" rx="5" ry="5" width="120" height="15" /> 
                    <rect x="130" y="25" rx="5" ry="5" width="70" height="15" /> 
                </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 315"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="5" rx="5" ry="5" width="591" height="45" /> 
                <circle cx="42" cy="117" r="42" /> 
                <rect x="108" y="94" rx="0" ry="0" width="242" height="26" /> 
                <rect x="109" y="132" rx="0" ry="0" width="75" height="9" /> 
                <rect x="0" y="189" rx="0" ry="0" width="244" height="10" /> 
                <rect x="0" y="206" rx="0" ry="0" width="235" height="4" /> 
                <rect x="191" y="132" rx="0" ry="0" width="19" height="9" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 400 450"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="5" width="400" height="300" /> 
                <rect x="4" y="320" rx="0" ry="0" width="90" height="90" /> 
                <rect x="106" y="320" rx="0" ry="0" width="90" height="90" /> 
                <rect x="206" y="320" rx="0" ry="0" width="90" height="90" /> 
                <rect x="307" y="320" rx="0" ry="0" width="90" height="90" />
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 450"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="5" width="600" height="45" /> 
                <rect x="0" y="55" rx="0" ry="0" width="382" height="18" /> 
                <circle cx="403" cy="64" r="10" /> 
                <rect x="0" y="98" rx="5" ry="5" width="600" height="45" /> 
                <rect x="0" y="155" rx="0" ry="0" width="382" height="17" /> 
                <circle cx="403" cy="164" r="10" /> 
                <rect x="0" y="347" rx="0" ry="0" width="290" height="57" /> 
                <rect x="311" y="347" rx="0" ry="0" width="286" height="57" /> 
                <rect x="0" y="199" rx="5" ry="5" width="600" height="121" />
            </ContentLoader>
        </Col>
        <Col sm="12">
        <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 1200 50"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="5" width="1200" height="5" /> 
            </ContentLoader>
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 380"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="0" width="300" height="45" /> 
                <rect x="0" y="90" rx="5" ry="0" width="600" height="30" /> 
                <rect x="0" y="135" rx="5" ry="0" width="200" height="30" /> 

                <rect x="0" y="200" rx="5" ry="0" width="600" height="30" /> 
                <rect x="0" y="245" rx="5" ry="0" width="200" height="30" /> 
            </ContentLoader>
        </Col>

        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 380"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="0" width="300" height="45" /> 
                <rect x="0" y="90" rx="5" ry="0" width="600" height="30" /> 
                <rect x="0" y="135" rx="5" ry="0" width="200" height="30" /> 

                <rect x="0" y="200" rx="5" ry="0" width="600" height="30" /> 
                <rect x="0" y="245" rx="5" ry="0" width="200" height="30" /> 
            </ContentLoader>
        </Col>

        <Col sm="4">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 380"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="0" width="300" height="45" /> 
                <rect x="0" y="90" rx="5" ry="0" width="600" height="30" /> 
                <rect x="0" y="135" rx="5" ry="0" width="200" height="30" /> 

                <rect x="0" y="200" rx="5" ry="0" width="600" height="30" /> 
                <rect x="0" y="245" rx="5" ry="0" width="200" height="30" /> 
            </ContentLoader>
        </Col>
    </Row>
)

export default ProjectDetailsLoader;
