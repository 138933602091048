import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import SubmitBid from "../projectDetails/submitBid";
import { deleteBid } from "../../redux/actions/deleteBid";
const AvailableAndInbiddingBideDetails = (props) => {
    const dispatch = useDispatch();
    const {
        bid_amount,
        bid_type,
        hours,
        bid_detail,
        status,
        id,
        bid_id
    } = props;

    const deleteBidLoading = useSelector(state => state.deleteBid.loading);
    const [deletebidProjectId, setDeletebidProjectId] = useState(null);
    const [deletebidCfmModal, setDeletebidCfmModal] = useState(false);
    const submitBidMessage = useSelector(state => state.submitBidMessage.error);
    const [unavailable, setUnavailable] = useState(false);
    const [unavailableMessage, setUnavailableMessage] = useState("");
    useEffect(() => {
        if (submitBidMessage != "") {
            setUnavailable(true);
            setUnavailableMessage(submitBidMessage)
        }
    }, [submitBidMessage]);

    const toggleb = () => {
        setDeletebidCfmModal(!deletebidCfmModal);
    }
    const toggleC = () => {
        setUnavailable(!unavailable);
    }

    const deleteThisBid = (project_id) => {
        setDeletebidProjectId(project_id)
        setDeletebidCfmModal(true)
    }
    const confirmDeleteThisBid = () => {
        const data = { project_id: deletebidProjectId };
        dispatch(deleteBid(data));
    }
    const reloadPage = () => {
        toggleC();
        location.reload();
    }
    return (
        <div className="ebw-prodet__content h-100 w-100">
            <div className="ebw-prodet__content h-100 w-100">
                <div className="ebw-prodet__content-wrap">
                    <div className="position-relative">
                        <h3 className='mb-3'>{status == null ? "Bid Now" : "Edit Bid"}</h3>
                    </div>
                </div>

                <SubmitBid
                    bid_amount={bid_amount}
                    bid_type={bid_type}
                    hours={hours}
                    bid_detail={bid_detail}
                    status={status}
                    project_id={id}
                    bid_id={bid_id}
                />
                {status === 'In-Bidding' &&
                    <div className="mt-4">
                        <Button onClick={() => deleteThisBid(id)} disabled={deleteBidLoading} className="ebw-button ebw-button__border text-left btn btn-secondary position-relative">
                            Delete Bid
                            <span className="ebw-prodet__btn-icon icon-arrow-right ebw-icon--lg position-absolute"></span>
                        </Button>
                    </div>
                }

            </div>
            
            <Modal isOpen={deletebidCfmModal} toggle={toggleb} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-5">Are you sure you want to delete this bid?</h3>

                        <div className="d-flex justify-content-center">
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={toggleb} >No</Button>
                            </div>
                            <div className="col-4 ">
                                <Button className="ebw-button ebw-button__primary" style={{backgroundColor:'#2196f3'}} color="primary" type="submit" onClick={() => confirmDeleteThisBid()} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={unavailable} toggle={reloadPage} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-4">{unavailableMessage}</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => reloadPage()} >Okay</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    );
}

export default AvailableAndInbiddingBideDetails;