import React from "react";
import ContentLoader from "react-content-loader";
import { Row } from "reactstrap";
import { Card } from 'reactstrap';
const AvailableProjectsLoader = ({ count = 8 }) => {
  return (
    <Row>
      {[...Array(count)].map((_, index) => (
        <div className="ebw-card__wrap ebw-card__wrap--secondary mr-3 mb-3" key={index}>
          <Card className="ebw-card ebw-card__secondary">
            <ContentLoader
              speed={2}
              width="100%"
              height={200}
              viewBox="0 0 400 220"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              // {...props}
            >
              {/* Card Image */}
              <rect x="0" y="0" rx="10" ry="10" width="100%" height="140" />
              
              {/* Card Title */}
              <rect x="15" y="155" rx="4" ry="4" width="60%" height="15" />

              {/* Card Subtitle */}
              <rect x="15" y="180" rx="4" ry="4" width="40%" height="12" />

              {/* Status / Date */}
              <rect x="75%" y="155" rx="4" ry="4" width="20%" height="12" />
              <rect x="75%" y="180" rx="4" ry="4" width="20%" height="12" />

            </ContentLoader>
          </Card>
      </div>
      ))}
    </Row>
  );
};

export default AvailableProjectsLoader;