import React from 'react';
import ButtonsSecondary from "../buttons/button-secondary";

const SendMessage = (props) => {
    return (
        <ButtonsSecondary
            clickEvent={props.sendMessage}
            additionalClass={props.additionalClass}
            type="button"
            text="Send"
        />
    );
}

export default SendMessage;