import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API from "../../API";
import ReactToggle from 'react-toggle';
import 'react-toggle/style.css';
import './toggleStyles.css';
import Header from "../../layout/header";
import Modal from "react-modal";
import PushNotificationIcon from '../../../src/assets/svgs/push-icon.svg';
import EmailIcon from '../../../src/assets/svgs/email-grey.svg';
import SmsIcon from '../../../src/assets/svgs/sms-grey.svg';
import ArrowUpGrey from '../../../src/assets/svgs/arrow-up-grey.svg';
import ArrowDownGrey from '../../../src/assets/svgs/arrow-down-grey.svg';
import LoaderModal from '../../components/loaders/LoaderModal';
import { Container,Row,Col } from 'reactstrap';
import Loading from 'react-loading';
const NotificationSettings = () => {
  const [settings, setSettings] = useState([]);
  const [settingsAlert, setSettingsAlert] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // Fetch notification settings from the API
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(API.baseURL + `/notification_settings`, API.options);
        setSettings(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchSettingsAlert = async () => {
      try {
        const response = await axios.get(API.baseURL + `/all_notifications_settings/0`, API.options);
        setSettingsAlert(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSettingsAlert();
    fetchSettings();
  }, []);

  // Handle toggle change
  const handleToggleChange = (id, type) => {
    setSettings((prev) =>
      prev.map((setting) =>
        setting.id === id ? { ...setting, [type]: !setting[type] } : setting
      )
    );
  };
  const toggleDropdown = (id) => {
    setOpenDropdownId((prev) => (prev === id ? null : id));
  };
  // Update settings in the API
  const handleSave = async () => {
    setLoading(true)
    const updatedSettings = settings.map(({ id, ...rest }) => ({ id, ...rest }));
    try {
      const response = await axios.post(API.baseURL + '/notification_settings', { notification_settings: updatedSettings }, API.options);
      const responseAlert = await axios.post(API.baseURL + '/all_notifications_settings', { sms_notification: settingsAlert.sms_notification, email_notification: settingsAlert.email_notification, push_notification: settingsAlert.push_notification }, API.options);
      setLoading(false)
      setTimeout(() => {
        setAlertMessage(response.data.message || responseAlert.data.message || 'Your notification settings have been successfully updated!');
        setShowAlert(true);
      }, 500);
    } catch (error) {
      setLoading(false)
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  // if (loading) return <LoaderModal loadingShow = {loading} message= {'loading... '} closeModal= {closeModal} />;
  // if (error) return <p>Error: {error}</p>;

  return (
    <Container>
      <Row
        className="ebw-notification__row d-flex justify-content-center align-items-center"
        style={{ marginTop: '7%' }}
      >
        <Col sm="6">    
        <Header />  
        {
          loading ? 
          <div style={{ 
            minHeight: "calc(100vh - 100px)", 
            paddingTop: "120px", 
            display: "flex", 
            flexDirection: "column",
            justifyContent: "center", 
            alignItems: "center",
            gap: "20px"
          }}>
            <div className="loading-spinner" style={{
              width: "40px",
              height: "40px",
              border: "3px solid #f3f3f3",
              borderTop: "3px solid #3498db",
              borderRadius: "50%",
              animation: "spin 1s linear infinite"
            }}></div>
            <div style={{
              fontSize: "18px",
              color: "#666",
              textAlign: "center"
            }}>
              <div>Loading notification settings</div>
              <div style={{fontSize: "14px", marginTop: "5px"}}>This may take a moment...</div>
            </div>
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
          </div>
          : 
            <div className="layout" >
              {/* <p className="section-title" style={{}}>Alert types</p> */}
              <p style={{
                paddingTop: '10px',
                marginLeft: '1.5%',
                fontWeight: '600',
                fontSize: '14px',
                color: 'rgb(84, 84, 88)',
                textTransform: 'uppercase'
              }}>
                Alert types
              </p>
              <div className="card" style={{ borderRadius: 10, boxShadow: 'none', width: '97%', margin: '0 1.5%', marginBottom: 15 }}>
                <div className="card-content" style={{ padding: 20 }}>
                  {/* Row 1 */}
                  <div className="custom-row" style={{ borderBottom: '1px solid #EDF1F2', display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <div className="left-section">
                      <img src={PushNotificationIcon} alt="Push Notifications" width="16" height="16" style={{ marginRight: '10px' }} />
                      <span style={{ fontSize: '16px', fontWeight: 'normal' }}>Push Notifications</span>
                    </div>
                    <ReactToggle
                      checked={settingsAlert.push_notification}
                      icons={false}
                      onChange={() => {
                        setSettingsAlert(prevSettings => ({
                          ...prevSettings,
                          push_notification: !prevSettings.push_notification
                        }));
                      }}
                      aria-label="Push Notifications"
                    />
                  </div>

                  {/* Row 2 */}
                  <div className="custom-row" style={{ borderBottom: '1px solid #EDF1F2', display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', paddingTop: '15px' }}>
                    <div className="left-section">
                      <img src={SmsIcon} alt="SMS" width="16" height="16" style={{ marginRight: '10px' }} />
                      <span style={{ fontSize: '16px', fontWeight: 'normal' }}>SMS</span>
                    </div>
                    <ReactToggle
                      checked={settingsAlert.sms_notification}
                      icons={false}

                      onChange={() => {
                        setSettingsAlert(prevSettings => ({
                          ...prevSettings,
                          sms_notification: !prevSettings.sms_notification
                        }));
                      }}
                      aria-label="SMS"
                    />
                  </div>

                  {/* Row 3 */}
                  <div className="custom-row" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px' }}>
                  <div className="left-section">
                      <img src={EmailIcon} alt="Email" width="16" height="16" style={{ marginRight: '10px' }} />
                      <span style={{ fontSize: '16px', fontWeight: 'normal' }}>Email</span>
                    </div>
                    <ReactToggle
                      checked={settingsAlert.email_notification}
                      icons={false}
                      onChange={() => {
                        setSettingsAlert(prevSettings => ({
                          ...prevSettings,
                          email_notification: !prevSettings.email_notification
                        }));
                      }}
                      aria-label="Email"
                    />
                    
                  </div>
                </div>
              </div>

              <p style={{ paddingTop: "10px", marginLeft: "1.5%", fontWeight: 600, fontSize: "14px", color: "rgb(84, 84, 88)", textTransform: "uppercase" }}>notifications you receive</p>
              {settings.map((item) => (
                <div className="card" style={{ boxShadow: 'none', width: '97%', margin: '0 1.5%', padding: '10px', borderBottom: '0.5px solid #ddd',marginBottom:10 }} key={item.id}>
                  {/* Dropdown Header */}
                  <div
                    className="dropdown-header"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '10px 0',
                      cursor: 'pointer',
                    }}
                    onClick={() => toggleDropdown(item.id)}
                  >
                    <div >
                      <span style={{ display: 'block', fontSize: '14px', fontWeight: '400', marginLeft: '5px', textTransform: 'capitalize', color: '#171717' }}>
                        {item?.notification_type}
                      </span>
                      <span style={{ marginTop: '5px', fontSize: '14px', color: '#B2B2B2', marginLeft: '5px' }}>
                        {[
                          item?.push_notification ? 'Push Notifications' : null,
                          item?.sms_notification ? 'SMS' : null,
                          item?.email_notification ? 'Email' : null,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    </div>
                    {openDropdownId === item.id ? (
                      <img src={ArrowUpGrey} alt="Up Arrow" width="12" height="14" style={{ marginRight: '10px' }} />
                      // <p>arrowup</p>

                    ) : (
                      <img src={ArrowDownGrey} alt="Down Arrow" width="12" height="14" style={{ marginRight: '10px' }} />
                      // <p>arrowDown</p>
                    )}
                  </div>

                  {/* Dropdown Content */}
                  {openDropdownId === item.id && (
                    <div className="card-content" style={{ paddingTop: '10px', display: 'inline' }}>
                      {/* Push Notifications */}
                      <div className="custom-row" style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', paddingTop: '15px' }}>
                        <div className="left-section">
                          <img src={PushNotificationIcon} alt="Email" width="16" height="16" style={{ marginRight: '10px', marginLeft: '5px' }} />
                          <span style={{ fontSize: '16px', fontWeight: 'normal' }}>Push Notifications</span>
                        </div>
                        <ReactToggle
                          checked={item.push_notification}
                          icons={false}
                          onChange={() => handleToggleChange(item?.id, 'push_notification')}
                          aria-label="Email"
                        />
                      </div>

                      
                      {/* SMS */}

                      <div className="custom-row" style={{ display: 'flex', justifyContent: 'space-between',paddingBottom: '10px', paddingTop: '15px' }}>
                        <div className="left-section">
                          <img src={SmsIcon} alt="SMS" width="16" height="16" style={{ marginRight: '10px', marginLeft: '5px' }} />
                          <span style={{ fontSize: '16px', fontWeight: 'normal' }}>SMS</span>
                        </div>
                        <ReactToggle
                          checked={item.sms_notification}
                          icons={false}
                          onChange={() => handleToggleChange(item?.id, 'sms_notification')}
                          aria-label="SMS"
                        />
                      </div>
                      {/* Email */}

                      <div className="custom-row" style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', paddingTop: '15px' }}>
                        <div className="left-section">
                          <img src={EmailIcon} alt="Email" width="16" height="16" style={{ marginRight: '10px', marginLeft: '5px' }} />
                          <span style={{ fontSize: '16px', fontWeight: 'normal' }}>Email</span>
                        </div>
                        <ReactToggle
                          checked={item.email_notification}
                          icons={false}
                          onChange={() => handleToggleChange(item?.id, 'email_notification')}
                          aria-label="Email"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}

              <button
                onClick={handleSave}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginTop: 10,
                  margin: '1.5%',
                  padding: "10px 20px",
                  backgroundColor: "#3880ff", 
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginBottom: 30,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                Save
              </button>

              {showAlert && (
                <Modal
                  isOpen={showAlert}
                  onRequestClose={() => {
                    setShowAlert(false)
                  }}
                  style={{
                    content: {
                      top: '50%',
                      left: '50%',
                      right: 'auto',
                      bottom: 'auto',
                      transform: 'translate(-50%, -50%)',
                      width: '400px',
                      // textAlign: 'center',
                      borderRadius: '10px',
                      padding: '20px',
                      backgroundColor: '#fff',
                    },
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <p style={{ fontSize: '14px', fontWeight: 'normal', textAlign: 'left' }}>{alertMessage}</p>
                  <div style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                    <button
                      onClick={() => setShowAlert(false)}
                      style={{
                        backgroundColor: 'transparent',
                        color: 'black',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'right'
                      }}
                    >
                      OK
                    </button>
                  </div>
                </Modal>
              )}
            </div>
        }
          
        </Col>
      </Row>
    </Container>
    
  );
};

export default NotificationSettings;