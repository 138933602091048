import * as type from '../actions/types';

const initialState = {
    provider_stats: false,
    stats: {},
    projects: {},
    suggested_projects: [],
    ongoing_projects: [],
    new_service: false,
    showNoData: false,
    video_url: "",
    loading: false,
    error: null,
}

export default function dashoardData(state = initialState, action) {
    switch (action.type) {
        case type.GET_DASHBOARD_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                provider_stats: action.data.stats,
                stats: action.data.projects,
                suggested_projects: action.data.suggested_projects,
                suggested_lead_projects: action.data.suggested_lead_projects,
                ongoing_projects: action.data.ongoing_projects,
                new_service: action.data.new_services,
                showNoData: true,
                video_url: action.data.video_url,
                projectAvailable:action.data.available_projects,
                activities: action.data.activities,
                my_projects:action.data.my_projects,
            }
        case type.GET_DASHBOARD_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}