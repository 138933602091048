import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import CardSecondary from '../../components/card/card-secondary';
import Filter from "../common/filter";
import { InputText } from "../inputs/text-input";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AvailableProjectsLoader from "../../components/projects/projectsSkeleton"
const AvailableProjects = (props) => {
    const { projectAvailableData, availableLoading, serviced_zipcodes, serviced_zipcodes_count } = props;
    const [availableProjectsData, setAvailableProjects] = useState([...projectAvailableData])
    const [otherFilterActive, setOtherFilterActive] = useState(false)
    const [searchVal, setSearchVal] = useState("");
    const [showZipcodeInfoModal, setShowZipcodeInfoModal] = useState(false);
    const [state, setState] = useState({
        startDate: "",
        endDate: "",
        location: "",
        budgetRangeStart: 10,
        budgetRangeEnd: 80,
    });
    const filterUpdated = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            startDate: data.startDate,
            endDate: data.endDate,
            location: data.location,
            budgetRangeStart: data.budgetRangeStart,
            budgetRangeEnd: data.budgetRangeEnd,
        }));
    };
    const storeFilterdArray = (data) => {
        setAvailableProjects(data);
        setOtherFilterActive(true)
    };
    const startedSearching = (event) => {
        event.preventDefault();
        setSearchVal(event.target.value)
        const searchAvailable = projectAvailableData && projectAvailableData.length > 0 && projectAvailableData.filter(availProjects => {
            return (availProjects.title.toLowerCase()).includes(event.target.value.toLowerCase());
        })
        if (event.target.value === "") {
            setAvailableProjects(projectAvailableData);
        } else {
            setAvailableProjects(searchAvailable);
        }
    }
    const toggleZipcodeInfoModal = () => {
        setShowZipcodeInfoModal(!showZipcodeInfoModal);
    };
    return (
        <div>
            <div className="d-md-flex flex-wrap justify-content-between align-items-center mb-3">
                <div className="ebw-title__primary">Available Projects</div>
                {projectAvailableData && projectAvailableData.length != 0 &&
                    <>
                        <InputText
                            label="Search"
                            name="search_filter"
                            id="search_filter"
                            value={searchVal}
                            error=""
                            changeEvent={(event) => startedSearching(event)}
                            parentClass="ebw-form__input-search-wrap"
                            additionalClass="ebw-form__input-search ebw-form__input--projects"
                        />
                        <Filter
                            otherFilterActive={otherFilterActive}
                            startDate={state.startDate}
                            endDate={state.endDate}
                            filterUpdated={filterUpdated}
                            currentData={availableProjectsData}
                            wholeData={projectAvailableData}
                            storeFilterdArray={storeFilterdArray}
                        />
                    </>
                }
            </div>
            {availableLoading ?
                <div className="ebw-data__loaderr mt-5">
                    <AvailableProjectsLoader loading={availableLoading} />
                </div> : 
                <>
                    {projectAvailableData && projectAvailableData.length == 0 &&
                        <Row>
                            <Col sm="12 mt-5" >
                                <div className="ebw-section__empty position-relative">
                                    <div className="ebw-section__empty-imgs w-100">
                                        <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                    </div>
                                    <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                        <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">A variety of projects in plumbing, carpentry, landscaping, and more available for bidding.
                                            
                                        </div>
                                        <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12 mt-5">
                                <div className="ebw-section__empty position-relative">
                                    <div >
                                        <div >
                                            {serviced_zipcodes_count === 0 ? (
                                                <div className="text-center px-4">
                                                    <p className="mb-4" style={{ fontSize: '20px' }}>
                                                        You do not have any service areas configured.<br></br><br></br> Go to your settings to do this now to see the projects available in your area.
                                                    </p>
                                                    <button 
                                                        onClick={() => window.location.href = '/profile'} 
                                                        style={{
                                                            padding: '10px 20px',
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#3478F6',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '8px',
                                                            cursor: 'pointer',
                                                            maxWidth: '200px',
                                                            margin: '0 auto',
                                                        }}
                                                    >
                                                        Go to settings
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="text-center px-4">
                                                    <p className="mb-3" style={{ fontSize: '24px' }}>
                                                        There are currently no available projects in your serviced zip codes.
                                                        <span 
                                                            onClick={toggleZipcodeInfoModal}
                                                            style={{ 
                                                                cursor: 'pointer',
                                                                marginLeft: '8px',
                                                                display: 'inline-flex',
                                                                verticalAlign: 'middle',
                                                            }}
                                                        >
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#3478F6">
                                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                                                            </svg>
                                                        </span>
                                                    </p>
                                                    <p className="mb-4" style={{ fontSize: '16px' }}>
                                                        Expand your coverage area to see more available projects.
                                                    </p>
                                                    <button 
                                                        onClick={() => window.location.href = '/profile'} 
                                                        style={{
                                                            padding: '10px 20px',
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#3478F6',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '8px',
                                                            cursor: 'pointer',
                                                            maxWidth: '200px',
                                                            margin: '0 auto',
                                                        }}
                                                    >
                                                        Go to settings
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row>
                        {projectAvailableData && projectAvailableData?.length !== 0 && 
                            projectAvailableData?.map((projectAvailable) => {
                                return (
                                    <Col key={projectAvailable.id} sm="4 mt-4" md="6" lg="4">
                                        <CardSecondary
                                            projectName={projectAvailable.name}
                                            projectImage={projectAvailable.project_image_url}
                                            projectDate={projectAvailable.created_at}
                                            project_status={projectAvailable.status}
                                            projectCategory={projectAvailable.project_type}
                                            projectLocation={projectAvailable.city}
                                            projectId={projectAvailable.id}
                                            projectLinks={`/project-detail/${projectAvailable.id}/projects`}
                                            project_post_type={projectAvailable.project_post_type}
                                            lead_purchased={projectAvailable.lead_purchased}
                                            bookmark={projectAvailable.bookmarked_by_current_provider}
                                            project_id={projectAvailable.id}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </>
            }
            <Modal isOpen={showZipcodeInfoModal} toggle={toggleZipcodeInfoModal} className="ebw-profile__modal">
                <div className="px-2 py-2 ebw-modal__wrap">
                    <ModalHeader toggle={toggleZipcodeInfoModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                        <div className="ebw-modal__title mb-1">Serviced Zip Codes</div>
                    </ModalHeader>
                    <ModalBody className="ebw-modal__body" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <div>
                            <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                The zipcodes you serve determine which projects will be available to you.
                            </p>

                            {serviced_zipcodes && serviced_zipcodes.length > 0 ? (
                                <div>
                                    <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                        Your current service areas include:
                                    </p>
                                    <div style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                        marginBottom: '15px'
                                    }}>
                                        {serviced_zipcodes.map((zipcode, index) => (
                                            <div key={index} style={{
                                                padding: '5px 10px',
                                                backgroundColor: '#f0f7ff',
                                                borderRadius: '4px',
                                                border: '1px solid #d0e3ff',
                                                fontSize: '14px',
                                            }}>
                                                {zipcode}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p style={{ fontSize: '14px', marginBottom: '10px', color: '#f44336' }}>
                                    You don't have any service zipcodes configured yet.
                                </p>
                            )}
                            
                            <p style={{ fontSize: '14px', marginBottom: '10px' }}>
                                If you've just made a change, sometimes it takes several minutes for the updates to take effect. You can also remove the zip code entries and try again.
                            </p>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                    <div className="text-right mt-1 mb-1">
                            <button 
                                onClick={toggleZipcodeInfoModal} 
                                className="ebw-button ebw-button__primary"
                            >
                                OK
                            </button>
                        </div>
                    </ModalFooter>
                </div>
            </Modal>
            {/* <div className="text-right mt-6 mt-md-10">
                <PaginationCmn
                    activePage={activePage}
                    itemsPerPage={itemPerPage}
                    totalItems={availableProjectsData && availableProjectsData.length}
                    handlePaginationChange={(data) => setActivePage(data)}
                />
            </div> */}
        </div>
    );
}

export default AvailableProjects;