import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, FormGroup, Input } from 'reactstrap';

import Main from '../../layout/main';
import LeadPaymentCard from "../../components/leadPayment/leadPaymentCard";
import LeadPaymentLoader from "../../components/leadPayment/leadPaymentSkeleton";

class LeadPayment extends Component {

    state = {}
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    render() {
        const { projectData } = this.props;
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Lead payment</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>

                <Main additionalClass_main="profile mb-12">
                    {this.props.projectDataLoading ?
                        <div className="ebw-data__loaderr">
                            <LeadPaymentLoader />
                        </div> :

                        <Container>
                            <div className="ebw-prodet mt-5 mt-md-10 mb-4 mb-md-14">
                                <div className="ebw-prodet__inwrap d-md-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="ebw-prodet__back-link d-flex align-items-center">
                                            <span className="ebw-prodet__angle ebw-icon ebw-icon--lg icon-arrow-left" aria-hidden="true"></span>
                                            <Link to="/leads"><div className="ebw-prodet__text">Consulting Projects</div></Link>

                                        </div>
                                        <div className="ebw-prodet__title pl-3 pl-md-6 ebw-title__primary d-none d-md-block">{this.capitalize(projectData.title)}</div>
                                    </div>

                                    <div className="ebw-prodet__more d-flex align-items-start justify-content-between">
                                        <div className="ebw-prodet__title ebw-title__primary pr-3 d-block d-md-none">{this.capitalize(projectData.title)}</div>

                                        {/* <div className="ebw-prodet__more-text d-flex align-items-center">
                                        <span className="pr-2">More</span>
                                        <span className="ebw-icon ebw-icon--lg icon-more-horizontal ebw-prodet__more-icon"></span>
                                    </div> */}

                                        {/* <div className="ebw-prodet__round d-flex align-items-center">
                                            <span className="ebw-prodet__round-blue"></span>
                                            <span className="ebw-prodet__round-blue"></span>
                                            <span className="ebw-prodet__round-blue"></span>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                            <Row className="ebw-prodet__row justify-content-between pb-12">
                                <Col md="6" lg="7" className="">
                                    <div className="ebw-prodet__customer-profile">
                                        <div className="ebw-prodet__title">Customer</div>
                                        <div className=" d-flex align-items-center ebw-prodet__profile pt-4">
                                            <img src={projectData.consumer_avatar_url} className="ebw-prodet__img ebw-header__profile-img"></img>
                                            <div className="ebw-prodet__details pl-4">
                                                <div className="ebw-prodet__name">{this.capitalize(projectData.consumer_name)}</div>
                                                <div className="d-flex align-items-center pt-1">
                                                    <span className="ebw-icon ebw-icon--lg icon-location"></span>
                                                    <div>{`${projectData.address && this.capitalize(projectData.address.city)}, ${projectData.address && this.capitalize(projectData.address.state)}`}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ebw-card mt-4 p-4">

                                            <div className="ebw-prodet__content-wrap">
                                                <div className="d-flex justify-content-between">
                                                    <div className="ebw-prodet__name">{projectData.title}</div>
                                                    <div className="ebw-prodet__date ebw-card__date">{projectData.published_date}</div>
                                                </div>
                                                <div className="ebw-prodet__subtitle ebw-card__subtitle">{projectData.project_type}</div>

                                                {/* <div className="d-flex justify-content-between pt-5 pb-2"><p className="mb-0 card-text">Project Budget</p><p class="mb-0 card-text">${projectData.budget}</p></div> */}
                                            </div>

                                        </div>
                                        <div className="pl-5 pt-5">
                                            <p className="ebw-text ebw-para--grey ebw-para--sm">Easybids stands by its pros and offers a 100% money back guarantee,
                                                if you are not satisfied or decide to choose another pro. See <Link to="/terms-conditions">terms and conditions</Link></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6" lg="5" className="mb-10">
                                    <LeadPaymentCard additionalClass="ebw-entry__pro-card--full-width" project_id={this.props.project_id} />
                                </Col>
                            </Row>
                        </Container>
                    }
                </Main>

            </div>
        );
    }
}

export default LeadPayment;