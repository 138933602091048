import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationList } from "../../redux/actions/notificationList";
import Notification from "./notification";

const NotificationPAge = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNotificationList());
    }, [])
    const notificationData = useSelector(state => state.notificationList.notification_list);
    const notificationLoading = useSelector(state => state.notificationList.loading);
    return (
        <>
            {!notificationLoading &&
                < Notification
                    notificationData={notificationData}
                    notificationLoading={notificationLoading}
                />
            }
            {notificationLoading && (
                <div style={{ 
                    minHeight: "calc(100vh - 100px)", 
                    paddingTop: "120px", 
                    display: "flex", 
                    flexDirection: "column",
                    justifyContent: "center", 
                    alignItems: "center",
                    gap: "20px"
                }}>
                    <div className="loading-spinner" style={{
                        width: "40px",
                        height: "40px",
                        border: "3px solid #f3f3f3",
                        borderTop: "3px solid #3498db",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite"
                    }}></div>
                    <div style={{
                        fontSize: "18px",
                        color: "#666",
                        textAlign: "center"
                    }}>
                        <div>Fetching your notifications</div>
                        <div style={{fontSize: "14px", marginTop: "5px"}}>This may take a moment...</div>
                    </div>
                    <style>
                        {`
                            @keyframes spin {
                                0% { transform: rotate(0deg); }
                                100% { transform: rotate(360deg); }
                            }
                        `}
                    </style>
                </div>
            )}
        </>
    );
}

export default NotificationPAge;