import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import axios from 'axios';
async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/projects`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* allProjectsData(action) {
    try {
        const response = yield call(getApi, action.data);
        yield put({ type: 'GET_ALL_PROJECTS_DATA_SUCCESS', data: response.data.data });
        yield put({ type: 'SET_AVAILABLE_PROJECTS_DATA_SUCCESS', data: response.data.data.available });

    } catch (e) {
        yield put({ type: 'GET_ALL_PROJECTS_DATA_FAILED', message: e.message });
    }
}

function* allProjectsSaga() {
    yield takeEvery('GET_ALL_PROJECTS_DATA_REQUESTED', allProjectsData);
}

export default allProjectsSaga;