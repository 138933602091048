import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allProjectsData } from "../../redux/actions/allProjects";
import { getProfileEditData } from "../../redux/actions/profileEditData"
import Projects from "./projects";
import Header from '../../layout/header';
import Footer from '../../layout/footer';

const ProjectsPage = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(allProjectsData());
    }, []);
    const available = useSelector(state => state.allProjectsData.available);
    const availableLoading = useSelector(state => state.allProjectsData.loading);
    const serviced_zipcodes = useSelector(state => state.allProjectsData.serviced_zipcodes);
    const serviced_zipcodes_count = useSelector(state => state.allProjectsData.serviced_zipcodes_count);
    return (
        <Projects
            availableData={available}
            availableLoading={availableLoading}
            serviced_zipcodes={serviced_zipcodes}
            serviced_zipcodes_count={serviced_zipcodes_count}
        />
    );
}

export default ProjectsPage;