import * as type from '../actions/types';

const initialState = {
    loading: false,
    error: "",
}

export default function submitBidMessage(state = initialState, action) {
    switch (action.type) {
        case type.POST_SUBMIT_BID_REQUESTED:
            return {
                ...state,
                loading: true,
                error: ""
            }
        case type.POST_SUBMIT_BID_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ""
            }
        case type.POST_SUBMIT_BID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}