import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Main from "../../layout/main";
import Loader from "../../common/loader";

const Faqs = (props) => {
    const { loading } = props;
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Have questions about EasyBids pro ? We strive to constantly listen to the questions we receive and build out helpful resources to quickly address such inquiries." />
                <title>FAQ - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="faqs pb-0">
                <section className="ebw-section ebw-section--faqs">
                    <div className="ebw-faqs__inner">
                        <Container>
                            {loading ?
                                <div className="ebw-data__loader">
                                    <Loader loading={loading} />
                                </div>
                                :
                                <Row>
                                    <Col sm="12">
                                        <h1 className="ebw-title__primary ebw-support__title ebw-support__title--terms mb-3 pb-2">Frequently Asked Questions</h1>

                                        <div className="ebw-support__frequent">
                                            <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">WHO PAYS THE FEE?</p>
                                                <p className="ebw-support__frequent-ans mb-0">
                                                    The customer pays the fee up-front to log in the job, however, it is subtracted from the total bid price.<br />
                                                    <strong>NOTE: </strong>When inputting your bid price, the system will advise you of your net amount after the small Easy Bids fee.
                                                </p>
                                            </div>

                                            {/* <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">WHAT IS THE FEE SCALE?</p>
                                                <p className="ebw-support__frequent-ans">
                                                    <span>1000 or less = 5%</span><br />
                                                    <span>2000 or less = 25%</span><br />
                                                    <span>3000 or less = 35%</span><br />
                                                    <span>4000 or less = 45%</span>
                                                </p>
                                            </div> */}

                                            <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">HOW DO WE GET PAID?</p>
                                                <p className="ebw-support__frequent-ans mb-0">
                                                    Customer pays you directly. You will provide the customer with a final invoice, job description and schedule of values.
                                                </p>
                                            </div>

                                            <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">HOW DO CHANGE ORDERS WORK?</p>
                                                <p className="ebw-support__frequent-ans mb-0">
                                                    If the customer agrees upon the change order pricing, they will pay you directly.
                                                </p>
                                            </div>

                                            <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">WHEN IS THE CONNECTION MADE FROM CONSUMER TO SERVICE PROVIDER?</p>
                                                <p className="ebw-support__frequent-ans mb-0">
                                                    The customer and service provider will receive relevant contact information to schedule a site walk,once the customer hires the service provider.
                                                </p>
                                            </div>

                                            <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">WHAT IS THE PROCESS?</p>
                                                <p className="ebw-support__frequent-ans mb-0">
                                                    Customer inputs job details, service providers bid, customer hires you and you begin…It’s that simple!
                                                </p>
                                            </div>

                                            <div className="ebw-support__frequent-inwrap">
                                                <p className="ebw-support__frequent-qstn text-uppercase">HOW DO WE GET MEASUREMENTS TO BID?</p>
                                                <p className="ebw-support__frequent-ans mb-0">
                                                    Several ways. For outdoors, we use aerial footage to capture measurements of roofs, windows,solar, gutters, patios, fencing, decking and more. For indoors, we use augmented reality to measure anything from cabinets, floors, doors, and much more.
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default Faqs;