import React, { useState } from "react";
import { Col, Row, Form } from 'reactstrap';
import Cookies from 'js-cookie';
import { InputText } from "../inputs/text-input";
import InputNumber from "../inputs/number-input";
import Buttons from "../buttons/button-primary";
import validate from "./register-verification-validation";
import Select from "../inputs/select";
import { Checkbox } from "../inputs/checkbox";

//- Verification Form ==> Register Page
const RegisterVerification = (props) => {
    // let refList = [{ label: "how did you hear about us", value: 0 }]
    // props.refarralList.length != 0 && props.refarralList.forEach(reff => {
    //     refList.push({ label: reff.name, value: reff.id },)
    // })
    const [state, setState] = useState({
        googleid: "",
        yelpid: "",
        insuranceid: "",
        licenceid: "",
        referral_id: [],

        googleidError: "",
        yelpidError: "",
        insuranceidError: "",
        licenceidError: "",
        referral_id_error: "",
    });
    const handleInputChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value,
            googleidError: "",
            yelpidError: "",
            // yearsOfBusinessError: "",
            insuranceidError: "",
            licenceidError: "",
            referral_id_error: "",
        }));
    };
    const handleReffChange = (event) => {
        event.target.checked ?
            setState((prevProps) => ({
                ...prevProps,
                referral_id: [...state.referral_id, event.target.value]
            })) :
            setState((prevProps) => ({
                ...prevProps,
                referral_id: state.referral_id.filter(x => x != event.target.value)
            }));
        setState((prevProps) => ({
            ...prevProps,
            googleidError: "",
            yelpidError: "",
            insuranceidError: "",
            licenceidError: "",
            referral_id_error: "",
        }))

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));

        const data = {
            auth_token: Cookies.get('authentication_token'),
            googleid: state.googleid,
            yelpid: state.yelpid,
            insuranceid: state.insuranceid,
            licenceid: state.licenceid,
            referral_id: state.referral_id,
        };
        errors.valid && props.handleRegisterVerification(data);
    }
    return (
        <>
            <Form className="ebw-entry__register-form text-center mt-2" onSubmit={handleSubmit} >
                <Row form>
                    <Col sm="12">
                        <InputText
                            label="Insurance ID (if any)"
                            name="insuranceid"
                            id="register_insuranceid"
                            value={state.insuranceid}
                            changeEvent={handleInputChange}
                            error={state.insuranceidError}
                        />
                    </Col>
                    <Col sm="12">
                        <InputText
                            label="Licence ID (if any)"
                            name="licenceid"
                            id="register_licenceid"
                            value={state.licenceid}
                            changeEvent={handleInputChange}
                            error={state.licenceidError}
                        />
                    </Col>

                    {/* <Col sm="12">
                        <InputText
                            label="Google ID"
                            name="googleid"
                            id="register_googleid"
                            value={state.googleid}
                            changeEvent={handleInputChange}
                            error={state.googleidError}
                        />
                    </Col>

                    <Col sm="12">
                        <InputText
                            label="Yelp ID"
                            name="yelpid"
                            id="register_yelpid"
                            value={state.yelpid}
                            changeEvent={handleInputChange}
                            error={state.yelpidError}
                        />
                    </Col> */}
                    <Col sm="12 mb-8 p-3" style={{backgroundColor:'white'}}>
                        <div className="text-left mb-3">How did you hear about us? *</div>
                        <Row>
                            {props?.refarralList?.length != 0 && props?.refarralList?.map((reff, index) => (
                                <Col key={reff} xs="6" className="mb-2">
                                    <Checkbox
                                        id="register_address"
                                        value={reff.id}
                                        onChange={handleReffChange}
                                        text={reff.name}
                                    />
                                </Col>
                            ))}
                            {state.referral_id_error ? <div className="ebw-error mt-2">{state.referral_id_error}</div> : ""}

                        </Row>
                        {/*<Select
                            name="companyType"
                            id="register_address"
                            value={state.companyType}
                            error={state.companyTypeError}
                            changeEvent={handleInputChange}
                            data={refList}
                       /> */}

                    </Col>

                    {/* <Col sm="12">
                        <InputNumber
                            label="Years of experience"
                            name="yearsOfBusiness"
                            id="years_of_business"
                            value={state.yearsOfBusiness}
                            changeEvent={handleInputChange}
                            error={state.yearsOfBusinessError}
                        />
                    </Col> */}

                    <Col sm="12 mt-3">
                        <p className="ebw-entry__register-terms">
                            <span className="ebw-entry__register-terms--text">By Signing Up You Agree To Our</span>
                            <a href="/terms-conditions" target="_blank" className="ebw-entry__register-terms--link pl-1"> Terms & Conditions</a>
                            <span className="ebw-entry__register-terms--text px-1">and</span>
                            <a href="/privacy-policy" target="_blank" className="ebw-entry__register-terms--link"> Privacy & Policy</a>
                        </p>
                    </Col>

                    <Col sm="12" md="4" xl="4" className="mx-auto text-center">
                        <Buttons text="Submit" type="submit" />
                        {/* <a href="#" className="ebw-entry__register-terms--verify mt-3">Verify Identity Later</a> */}
                    </Col>
                </Row>
            </Form>
        </>
    );
}
export default RegisterVerification;