import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Support from "./support";

const SupportPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <Support />
    );
}

export default SupportPage;