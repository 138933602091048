import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import TermsConditions from "./terms-conditions";

const TermsConditionsPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <TermsConditions />
    );
}

export default TermsConditionsPage;