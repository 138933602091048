import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import users from './users';
import login from './login';
import setAuth from "./setAuth";
import registerPersonal from './registerpersonal';
import registerOtp from "./register-otp";
import servedCities from "./servedCities";
import storeBusiness from "./storeBusiness";
import serviceTypes from "./serviceList";
import registerBusiness from "./registerBusiness";
import registerAdditional from "./registerAdditional";
import getRefarralList from "./refarralList";
import dashoardData from "./dashboardData";
import getProfileData from "./profileData";
import getNotificationList from "./notificationList";
import allProjectsData from "./allProjects";
import projectsAvailableData from "./projectsAvailable";
import inConversationData from "./inConversation";
import setRegStep from "./activeRegStep";
import getProfileEditData from "./profileEditData";
import currentProjectData from "./currentProject";
import commissionDetailsData from "./commissionDetails";
import myWorks from "./myWorks";
import myWorksFiles from "./myWorksFiles";
import buyPtojects from "./buyProjects";
import activateChatBox from "./activateChatbox";
import currentChat from "./getChat";
import updateEmail from "./updateEmail";
import updateEmailOtp from "./updateEmailOtp";
import getNotificationCount from "./notificationCount";
import selectedCategoryData from "./categoryFilter";
import selectedTypes from "./selectedTypes";
import customerReviews from "./customerReviews";
import deleteProject from "./ignoreThisProject";
import deleteBid from "./deleteBid";
import markcompleteProject from "./markCompleteProject";
import markCancelProject from "./markCancelProject";
import loginOtpF from "./loginOtpFirstStep";
import loginOtpS from "./loginOtpSecondStep";
import loginOtpResend from "./loginOtpResendOtp";
import registerEditMobile from "./registrationEditMobileNumber";
import projectVideoData from "./projectVideos";
import availableProjectsData from "./availableProjects";
import firstFiveNotifications from "./firstFiveNotifications";
import setAvailableCategories from "./setAvailableCategories";
import logout from "./logout";
import selectedAllTypes from "./setSelectAllservice";
import relatedProjects from "./relatedProjects";
import sendMessage from "./sendMessage";
import submitBidMessage from "./submitBid";
import uploadAlbumFile from "./albumFileUpload";
import newService from "./getNewServices";
import inBiddingProjectsData from './allInBidding';
import hiredProjectsData from './allHired';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'auth',
    "registerPersonal",
    "registerOtp",
    "servedCities",
    "storeBusiness",
    "serviceTypes",
    "registerBusiness",
    "registerAdditional",
    "registrationStep",
    "getProfileEditData",
  ],
};

const rootReducer = combineReducers({

  users: users,
  login: login,
  toastr: toastrReducer,
  registerPersonal: registerPersonal,
  registerOtp: registerOtp,
  servedCities: servedCities,
  storeBusiness: storeBusiness,
  serviceTypes: serviceTypes,
  registerBusiness: registerBusiness,
  registerAdditional: registerAdditional,
  refarralList: getRefarralList,
  auth: setAuth,
  dashoardData: dashoardData,
  profileData: getProfileData,
  notificationList: getNotificationList,
  allProjectsData: allProjectsData,
  projectAvailable: projectsAvailableData,
  conversations: inConversationData,
  registrationStep: setRegStep,
  getProfileEditData: getProfileEditData,
  currentProjectData: currentProjectData,
  commissionDetails: commissionDetailsData,
  myWorks: myWorks,
  myWorksFiles: myWorksFiles,
  buyPtojects: buyPtojects,
  activateChatBox: activateChatBox,
  chat: currentChat,
  updateEmail: updateEmail,
  updateEmailOtp: updateEmailOtp,
  notificationCount: getNotificationCount,
  categoryFilter: selectedCategoryData,
  selectedTypes: selectedTypes,
  customerReviews: customerReviews,
  ignoreProject: deleteProject,
  deleteBid: deleteBid,
  markcompleteProject: markcompleteProject,
  markCancelProject: markCancelProject,
  loginOtpFirstStep: loginOtpF,
  loginOtpSecondStep: loginOtpS,
  loginOtpResendOtp: loginOtpResend,
  registerEditMobile: registerEditMobile,
  projectVideos: projectVideoData,
  availableProjects: availableProjectsData,
  firstFiveNotifications: firstFiveNotifications,
  setAvailableCategories: setAvailableCategories,
  logout: logout,
  relatedProjects: relatedProjects,
  sendMessage: sendMessage,
  submitBidMessage: submitBidMessage,
  uploadAlbumFile: uploadAlbumFile,
  newService: newService,
  inBiddingData: inBiddingProjectsData,
  hiredProjectsData: hiredProjectsData
});

export default persistReducer(persistConfig, rootReducer);