import React from 'react';
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";

const InputEmail = (props) => {
    // an onchange validation can be done here 
    return (
        <div>
            <FloatingLabel
                id={props.name}
                name={props.name}
                placeholder={props.label}
                className="custom-class ebw-form__input"
                type="email"
                value={props.value}
                onChange={props.changeEvent}
                disabled={props.disabled}

            />
            {props.error && <p className="ebw-input__error mt-0 mb-2 text-left">{props.error}</p>}
        </div>
    );
}
export default InputEmail;