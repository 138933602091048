import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import MyWorks from "./my-works";
import { getMyworks } from "../../redux/actions/getMyworks"

const MyWorksPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMyworks());
    }, []);
    const myWorksData = useSelector(state => state.myWorks.myWorks);
    const myWorksLoading = useSelector(state => state.myWorks.loading);
    const myWorksError = useSelector(state => state.myWorks.error);

    return (
        <MyWorks
            myWorksData={myWorksData}
            myWorksLoading={myWorksLoading}
            myWorksError={myWorksError}
        />
    );
}

export default MyWorksPage;