import React, { useState } from 'react';
import Modal from 'react-modal';
import trashIcon from '../../assets/svgs/trash-02.svg';
import AddCards from './payment-form';
import LoaderModal from '../../components/loaders/LoaderModal';
// Ensure React Modal is properly configured
Modal.setAppElement('#root');

const ManageCardsModal = ({
  showManageCards,
  setShowManageCards,
  availableCards,
  setCardDeletionId,
  setDeleteConfirmationPopup,
  deleteCardHandler,
  fetchAvailableCards
}) => {
  const [deleteConfirmationPopup, setDeletePopup] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [loadingShow, setLoadingShow] = useState(false);
  const handleDeleteConfirmation = async() => {
    setLoadingShow(true)
   await deleteCardHandler();
    setLoadingShow(false)
    setDeletePopup(false);
  };

  const renderAddCardForm = () => <AddCards onSuccess={fetchAvailableCards} showAddCard={showAddCard} setShowAddCard={setShowAddCard}/>;

  return (
    <>
      {/* Manage Cards Modal */}
      <Modal
        isOpen={showManageCards}
        onRequestClose={() => setShowManageCards(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
            width: '100%',
            maxWidth: '400px',
            padding: '20px',
            maxHeight: '90vh',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        {/* Header */}
        <div
          style={{
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              margin: 0,
              color: '#545458',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Manage Cards
          </p>
          <button
            onClick={() => setShowManageCards(false)}
            style={{
              background: 'none',
              border: 'none',
              fontSize: '24px',
              color: '#545458',
              cursor: 'pointer',
            }}
          >
            &times;
          </button>
        </div>

        {/* Card List */}
        {availableCards?.length > 0 &&
          availableCards.map((card) => (
            <div
              key={card.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#F9F9F9',
                borderRadius: '12px',
                padding: '15px',
                marginBottom: '10px',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                justifyContent: 'space-between',
              }}
            >
              {/* Card Details */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={card.card_icon}
                  alt="Card Icon"
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    marginRight: '10px',
                  }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#171717',
                      fontWeight: 'bold',
                    }}
                  >
                    {`*** *** **** ${card.last4_digit}`}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#B2B2B2',
                      marginTop: '4px',
                    }}
                  >
                    {`Expires: ${card.exp_month}/${card.exp_year}`}
                  </div>
                </div>
              </div>

              {/* Delete Button */}
              <button
                onClick={() => {
                  setCardDeletionId(card.id);
                  setDeletePopup(true);
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                  color: 'black',
                }}
              >
                <img
                  src={trashIcon}
                  alt="Delete Icon"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </button>
            </div>
          ))}

        {/* Add Card Button */}
        <button
          onClick={() => { event.preventDefault();setShowAddCard(true);}} // Add relevant handler
          style={{
            marginTop: '20px',
            padding: '10px 15px',
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundColor: '#3478F6',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'center',
          }}
        >
          + Add Card
        </button>
        {showAddCard && (
            <div style={{ width: 500, height: 500 }}>
            {showAddCard && renderAddCardForm()}
            </div>
        )}
      </Modal>
      
      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteConfirmationPopup}
        onRequestClose={() => setDeletePopup(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
            padding: '20px',
            width: '300px',
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px' }}>Delete Card</p>
          <p style={{ fontSize: '14px', color: '#555', marginBottom: '20px' }}>
            Are you sure you want to permanently remove this card?
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={handleDeleteConfirmation}
              style={{
                backgroundColor: '#3D84FB',
                color: 'white',
                fontSize: '14px',
                fontWeight: 'bold',
                border: 'none',
                borderRadius: '6px',
                padding: '10px 20px',
                cursor: 'pointer',
              }}
            >
              CONFIRM
            </button>
            <button
              onClick={() => setDeletePopup(false)}
              style={{
                background: 'transparent',
                color: 'black',
                fontSize: '14px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Modal>
      <div style={{height: 1000}}>
      <LoaderModal loadingShow = {loadingShow} message= {'Deleting card... '} />
      </div>

    </>
  );
};

export default ManageCardsModal;