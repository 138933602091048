import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';

async function postApi(data) {
    const apiUrl = API.baseURL + `/providers/update.json`;
    try {
        const response = axios.put(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* updateProfile(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Success', 'Updated profile successfully');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to update', response.errors[0]);
        }
        yield put({ type: 'POST_UPDATE_PROFILE_SUCCESS', data: response.data });
        yield put({ type: 'GET_PROFILE_DATA_REQUESTED', data: response.data });
        location.reload();
    } catch (e) {
        yield put({ type: 'POST_UPDATE_PROFILE_FAILED', message: e.message });
    }
}

function* updateProfileSaga() {
    yield takeEvery('POST_UPDATE_PROFILE_REQUESTED', updateProfile);
}

export default updateProfileSaga;