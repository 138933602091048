import React from 'react';
import { Container } from 'reactstrap';
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import Main from '../../layout/main'
import AvailableProjects from '../../components/projects/availableProjects';
const Projects = (props) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content=" Easy Bids Pro is the easiest way to bid on projects in your area. Whether it's plumbing, carpentry, landscaping or anything else you need done, find an easy project near you and get started today!" />
                <title>Bidding - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="projects">
                <section className="ebw-section ebw-section__pages">
                    <Container>
                        <AvailableProjects availableLoading={props.availableLoading} projectAvailableData={props.availableData} serviced_zipcodes={props.serviced_zipcodes} serviced_zipcodes_count={props.serviced_zipcodes_count}/>
                    </Container>
                </section>
            </Main>
        </div>
    );
}

export default Projects;
