import React, { useEffect } from 'react';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import WalletHistory from "./wallet-history";

const WalletPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <WalletHistory />
    );
}

export default WalletPage;