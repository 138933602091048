import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import axios from 'axios';
async function getApi(data) {
    const apiUrl = API.baseURL + `/providers/web_related_projects?project_id=${data}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* relatedProjectsData(action) {
    try {
        const response = yield call(getApi, action.data);
        yield put({ type: 'GET_RELATED_PROJECTS_SUCCESS', data: response.data.data.related_projects });

    } catch (e) {
        yield put({ type: 'GET_RELATED_PROJECTS_FAILED', message: e.message });
    }
}
function* relatedProjectsSaga() {
    yield takeEvery('GET_RELATED_PROJECTS_REQUESTED', relatedProjectsData);
}
export default relatedProjectsSaga;