import React, {useState} from 'react';
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
const InputPassword = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisiblity = () => {
        setShowPassword(showPassword ? false : true);
    };

    return (
        <div >
            <div className='position-relative'>
                <FloatingLabel
                    id={props.name}
                    name={props.name}
                    placeholder={props.label}
                    className="custom-class ebw-form__input position-relative"
                    type={showPassword ? "text" : "password"}
                    value={props.value}
                    autoComplete="new-password"
                    onChange={props.changeEvent}
                />
                {/* Eye Icon for Show/Hide Password */}
                <div className="ebw-form__input-show--password position-absolute" style={{ cursor: "pointer" }} onClick={togglePasswordVisiblity}>
                {showPassword ? (
                    // Eye Slash (Hide Password)
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M17.94 17.94A10.08 10.08 0 0 1 12 20C7 20 2.73 16.36 1 12c.72-1.64 1.81-3.13 3.12-4.41M9.88 9.88A3 3 0 0 1 14.12 14.12M10.73 5.08A10.07 10.07 0 0 1 12 4c5 0 9.27 3.64 11 8-1.1 2.48-2.9 4.62-5.06 6.17M3 3l18 18"></path>
                    </svg>
                ) : (
                    // Eye (Show Password)
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M1 12C2.73 16.36 7 20 12 20s9.27-3.64 11-8C21.27 7.64 17 4 12 4S2.73 7.64 1 12z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                )}
                </div>
                {/* <div className="ebw-form__input-show--password position-absolute">
                    <span onClick={togglePasswordVisiblity} className="ebw-form__input-show--text" >{showPassword ? "Hide" : "Show"}</span>
                </div> */}
            </div>
            {props.error && <p className="ebw-input__error mt-0 mb-2 text-left">{props.error}</p>}
        </div>
    );
}
export default InputPassword;