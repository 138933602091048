import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';


const apiUrl = API.baseURL + `/v1/providers/signup/business-info`;
async function postApi(data) {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: API.options.headers,
    //     body: data
    // };
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* registerBusiness(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // setAuth(response.data.data, response.headers);
            yield put({ type: 'POST_REGISTER_BUSINESS_SUCCESS', registerPersonal: response.data.data });
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 5 });
            location.reload();
            // localStorage.setItem("providerID",action.data.data.provider.provider_id);
            // toastr.success('Pegister Business Success', 'You are successfully completed the 3rd step');
        } else {
            // response.errors.length != 0 && toastr.error('Register business  failed', response.errors[0]);
        }
        // history.push('/');

    } catch (e) {
        // toastr.error('Failed', "Register business  failed");
        yield put({ type: 'POST_REGISTER_BUSINESS_FAILED', message: e.message });
    }
}

function* registerBusinessSaga() {
    yield takeEvery('POST_REGISTER_BUSINESS_REQUESTED', registerBusiness);
}

export default registerBusinessSaga;