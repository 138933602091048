import React from 'react';
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";

const Textarea = (props) => {
    return (
        <>
            <FloatingLabel
                id={props.id}
                component="textarea"
                name={props.name}
                placeholder={props.label}
                className="ebw-form__input ebw-form__input-textarea mb-0"
                type="textarea"
                value={props.value}
                onChange={props.changeEvent}
            />
            {props.error && <p className="ebw-input__error mt-0 mb-2 text-left">{props.error}</p>}
        </>
    );
}
export default Textarea;