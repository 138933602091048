import * as type from '../actions/types';

const initialState = {
    available: [],
    offset: 0,
    loading: false,
    error: null,
}

export default function allProjectsData(state = initialState, action) {
    switch (action.type) {
        case type.GET_ALL_PROJECTS_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ALL_PROJECTS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                available: action.data.projects,
                offset: 0,
                serviced_zipcodes: action.data.serviced_zipcodes,
                serviced_zipcodes_count: action.data.serviced_zipcodes_count,
            }
        case type.GET_ALL_PROJECTS_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}