import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';

import Main from '../../layout/main'

const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="terms-conditions pb-0">
                <section className="ebw-section ebw-section--terms">
                    <div className="ebw-terms__inner">
                        <Container>
                            <Row>
                                <Col sm="12">
                                    <h1 className="ebw-support__title ebw-support__title--terms pb-3">Privacy Policy</h1>
                                    <div className="ebw-support__frequent">
                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">General T&C</p>
                                            <p className="ebw-support__frequent-ans">
                                                Modification of these Conditions of Use, Jurisdiction, and applicable Law
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                These conditions of use may be modified by the publisher at any time. The conditions of use applicable to any use of the site are those available and published on the site at the time it is consulted by the user. The nullity or non-validity of a provision of these conditions of use shall not affect the validity of the remaining provisions of these conditions.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">Disclaimer</p>
                                            <p className="ebw-support__frequent-ans">
                                                Although all information on the site has been checked thoroughly, the user should not take the accuracy of the information for granted. In any case, the publisher cannot be held responsible for the accuracy of the information provided nor give a guarantee that the details are complete, accurate and current. All information provided is subject to modification, error or omission.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                The publisher also disclaims all responsibility for contents appearing on third parties web sites to which the site makes hyperlinks or from which it receives hyperlinks.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                Finally, the publisher cannot be held liable for and makes no warranty in the event of interruption or malfunction of the site. The publisher cannot be held responsible for direct or indirect damage incurred by the user as a result of access to the site and its use.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">Data Protection and Privacy Policy</p>
                                            <p className="ebw-support__frequent-ans">
                                                Modification of these Conditions of Use, Jurisdiction, and applicable Law
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                These conditions of use may be modified by the publisher at any time. The conditions of use applicable to any use of the site are those available and published on the site at the time it is consulted by the user. The nullity or non-validity of a provision of these conditions of use shall not affect the validity of the remaining provisions of these conditions.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">Data Security</p>
                                            <p className="ebw-support__frequent-ans">
                                                The publisher uses Security Sockets Layer (SSL) encryption technology to encrypt sensitive personal information before it travels over the internet.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                The security of personal information is important to the publisher. The publisher follows generally accepted standards to protect the personal information submitted, both during transmission and once the publisher receives it. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, the publisher can never guarantee its absolute security.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                Because of the technical requirement to ensure reliability and security, the publisher may store personal information in locations outside of the publisher’s direct control (for instance, on servers located in data centers, at hosting providers, or on backup servers)
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default PrivacyPolicy;