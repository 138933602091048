const validate = (inputs) => {
    //Email errors
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let errors = {
        valid: true,
    };

    if (!validEmailRegex.test(inputs.email)) {
        errors.emailError = 'Invalid email address';
        errors.valid = false;
    } else if (!inputs.email) {
        errors.emailError = 'Enter Email';
        errors.valid = false;
    }
    //Password Errors
    if (inputs.password.length < 6) {
        errors.passwordError = 'Check Password'
        errors.valid = false;
    }
    if (!inputs.password) {
        errors.passwordError = 'Enter Password'
        errors.valid = false;
    }

    return errors;
}
export default validate;