import { all } from 'redux-saga/effects';
import { registerOtp } from '../actions/register-otp';
import loginSaga from './loginSaga';
import setAuthSaga from './setAuthSaga'
import registerOtpSaga from './registerOtpSaga';
import registerPersonalSaga from "./registerpersonalSaga";
import userSaga from './userSaga';
import servedCities from './servedCitiesSaga';
import storeBusinessSaga from './storeBusinessSaga';
import serviceTypeList from "./serviceListSaga";
import registerBusinessSaga from "./registerBusinessSaga";
import registerAdditionalSaga from "./registerAdditionalSaga";
import refarralListSaga from "./refarralListSaga";
import dashboardDataSaga from "./dashboardDataSaga";
import notificationListSaga from "./notificationListSaga";
import allProjectsSaga from "./allProjectsSaga";
import availableProjectsSaga from "./projectsAvailableSaga";
import inConversationSaga from "./inConversationSaga";
import getProfileDataSaga from "./profileDataSage";
import getEditProfileDataSaga from "./profileEditDataSaga";
import currentProjectSaga from "./currentProjectSaga";
import commissionDetailsSaga from "./commissionDetailsSaga";
import submitBidSaga from "./submitBidSaga";
import myWorksSaga from "./myWorksSaga";
import myWorksFilesSaga from "./myWorksFilesSaga";
import myWorksNewFolderSaga from "./myWorksNewFolderSaga";
import buyProjectsSaga from "./buyProjectSaga";
import markProjectComplet from "./markCompleteProjectSaga";
import markProjectCancel from "./markCancelProjectSaga";
import getChatSaga from "./getChatSaga";
import deleteMessageSaga from "./deleteMessageSaga";
import sendMessageSaga from "./sendMessageSaga";
import updateEmailSaga from "./updateEmailSaga";
import myWorksAlbumRenameSaga from "./myWorksAlbumRenameSaga";
import myWorksAlbumDeleteSaga from "./myWorksAlbumDeleteSaga";
import albumFIleUploadSaga from "./albumFileUploadSaga";
import updateProfileImageSaga from "./updateProfileImageSaga";
import notificationCountSaga from "./notificationCountSaga";
import categoryFilterSaga from "./categoryFilterSaga";
import selectedTypesSaga from "./selectedTypesSaga";
import socialSignupSaga from "./socialSignupSaga";
import updateProfileSaga from "./updateProfileSaga";
import customerReviewSaga from "./customerReviewsSaga";
import regResendOtpSaga from "./registerResendOtpSaga";
import ignoreThisProjectSaga from "./ignoreThisProjectSaga";
import deleteBidSaga from "./deleteBidSaga";
import loginOtpFirstStepSaga from "./loginWithOtpFirstStepSaga";
import loginOtpSecondStepSagafrom from "./loginWithOtpSecondStepSaga";
import loginOtpResendSaga from "./loginWithOtpResendSaga";
import regEditMobileSaga from "./registrationEditMobileNumberSaga";
import projectVideosSaga from "./projectViseosSaga";
import storeAvailableProjectsSaga from "./availableProjectsSaga";
import firstFiveNotificationsSaga from "./firstFiveNotificationsSaga";
import setAvailableCategoriesSaga from "./setAvailableCategoriesSaga";
import myWorksAlbumFileDeleteSaga from "./deleteMyWorksAlbumfileSaga";
import logoutSaga from "./logoutSaga";
import selectedAllTypesSaga from "./selectedAllServiceSaga";
import setSocialIncompleteSaga from "./socialIncompleteRegSaga";
import setOtpLoginStepSaga from "./loginOtpStepSaga";
import relatedProjectsSaga from "./relatedProjectsSaga";
import newServices from "./newServicesSaga";
import inBiddingProjectsSaga from './inBiddingProjectsSaga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    loginSaga(),
    registerPersonalSaga(),
    registerOtpSaga(),
    servedCities(),
    storeBusinessSaga(),
    serviceTypeList(),
    registerBusinessSaga(),
    registerAdditionalSaga(),
    refarralListSaga(),
    setAuthSaga(),
    dashboardDataSaga(),
    notificationListSaga(),
    allProjectsSaga(),
    availableProjectsSaga(),
    inConversationSaga(),
    getProfileDataSaga(),
    getEditProfileDataSaga(),
    currentProjectSaga(),
    commissionDetailsSaga(),
    submitBidSaga(),
    myWorksSaga(),
    myWorksFilesSaga(),
    myWorksNewFolderSaga(),
    buyProjectsSaga(),
    markProjectComplet(),
    markProjectCancel(),
    getChatSaga(),
    deleteMessageSaga(),
    sendMessageSaga(),
    updateEmailSaga(),
    myWorksAlbumRenameSaga(),
    myWorksAlbumDeleteSaga(),
    albumFIleUploadSaga(),
    updateProfileImageSaga(),
    notificationCountSaga(),
    categoryFilterSaga(),
    selectedTypesSaga(),
    socialSignupSaga(),
    updateProfileSaga(),
    customerReviewSaga(),
    regResendOtpSaga(),
    ignoreThisProjectSaga(),
    deleteBidSaga(),
    loginOtpFirstStepSaga(),
    loginOtpSecondStepSagafrom(),
    loginOtpResendSaga(),
    regEditMobileSaga(),
    projectVideosSaga(),
    storeAvailableProjectsSaga(),
    firstFiveNotificationsSaga(),
    setAvailableCategoriesSaga(),
    myWorksAlbumFileDeleteSaga(),
    logoutSaga(),
    selectedAllTypesSaga(),
    setSocialIncompleteSaga(),
    setOtpLoginStepSaga(),
    relatedProjectsSaga(),
    newServices(),
    inBiddingProjectsSaga()
  ])
}
