import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../API';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import myWorksSaga from "./myWorksSaga";

async function getApi(data) {

    const apiUrl = API.baseURL + `/album/${data}`;
    try {
        const response = axios.delete(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* myWorksAlbumDelete(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.data.code == 200 || response.data.code == 201) {
            // toastr.success('Success', 'Album deleted successfully');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to delete the album', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'DELETE_MY_WORKS_ALBUM_SUCCESS', data: response.data.data });
        // yield put({ type: 'DELETE_MY_WORKS_ALBUM_REQUESTED', data: {} });

    } catch (e) {
        yield put({ type: 'DELETE_MY_WORKS_ALBUM_FAILED', message: e.message });
    }
}

function* myWorksAlbumDeleteSaga() {
    yield takeEvery('DELETE_MY_WORKS_ALBUM_REQUESTED', myWorksAlbumDelete);
}

export default myWorksAlbumDeleteSaga;