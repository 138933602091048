import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';



async function postApi(data) {
    const apiUrl = API.baseURL + `/resend_otp?confirmation_token=${data}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* resendOtp(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Success', 'The code sent to your mobile again');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to send message', response.errors[0]);
        }
        yield put({ type: 'GET_REGISTER_RESEND_OTP_SUCCESS', data: response.data });
    } catch (e) {
        yield put({ type: 'GET_REGISTER_RESEND_OTP_FAILED', message: e.message });
    }
}

function* regResendOtpSaga() {
    yield takeEvery('GET_REGISTER_RESEND_OTP_REQUESTED', resendOtp);
}

export default regResendOtpSaga;