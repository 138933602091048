import * as type from '../actions/types';

const initialState = {
    myWorksFiles: [],
    loading: false,
    error: null,
}

export default function myWorksFiles(state = initialState, action) {
    switch (action.type) {
        case type.GET_MY_WORKS_FILES_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_MY_WORKS_FILES_SUCCESS:
            return {
                ...state,
                loading: false,
                myWorksFiles: action.data
            }
        case type.GET_MY_WORKS_FILES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}