import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardData } from '../../redux/actions/dashboardData';
import { firstFiveNotifications } from "../../redux/actions/firstFiveNotifications";
import { allProjectsData } from "../../redux/actions/allProjects";
import { projectsAvailableData } from "../../redux/actions/projectsAvailable";
import { inConversationData } from "../../redux/actions/inConversation"
import { getProfileData } from "../../redux/actions/profileData"
import { getProfileEditData } from "../../redux/actions/profileEditData"
import DashBoard from "./dashboard";

const RegisterPAge = () => {
    const dispatch = useDispatch();
    const data = {
        project_type_id: "1",
        project_sub_type_id: "1"
    }
    useEffect(() => {
        dispatch(getDashboardData(data));
        dispatch(firstFiveNotifications());
        dispatch(allProjectsData());
        dispatch(projectsAvailableData());
        dispatch(inConversationData());
        dispatch(getProfileData());
        dispatch(getProfileEditData());
    }, [])

    return (
        <DashBoard />
    );
}

export default RegisterPAge;