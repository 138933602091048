import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import VALUES from "../../values/values"

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

const marks = [
  {
    value: 0,
    label: `$${VALUES.filter_range_start}`,
  },
  {
    value: 20,
    label: `$${((VALUES.filter_range_max - VALUES.filter_range_start) / 100) * 20}`,
  },
  {
    value: 60,
    label: `$${((VALUES.filter_range_max - VALUES.filter_range_start) / 100) * 60}`,
  },
  {
    value: 100,
    label: `$${VALUES.filter_range_max}`,
  },
];

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([20, 60]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.changeSliderValueHandler(newValue);

  };

  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        marks={marks}
      />
    </div>
  );
}