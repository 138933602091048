import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import Main from '../../layout/main';
import ProjectNotFoundPage from "../notFound/projectNotFound";
import QuestionAnswers from "../../components/projectDetails/questionAnswers";
import AvailableAndInbiddingBideDetails from "../../components/projectDetails/bidDetailsAsideOne";
import ContactedBidDetails from "../../components/projectDetails/BidDetailsAsideThree";
import CompletedAndHiredBidDetails from "../../components/projectDetails/BidDetailsAsideFour";
import ProjectsSlider from "../../components/projects/projectslider";
import ProjectDetailsLoader from "../../components/projectDetails/projectDetailsSkeleton";
import RelatedProjects from "../../components/projectDetails/relatedProjects";
import Sticky from 'react-sticky-el';
import PDFViewer from "./pdfViewer";
import { Typography } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

class Projectdetail extends Component {
    state = {
        isOpen: false,
        BidType: "",
        BidAmount: "",
        BidHourly: "",

        BidPriceEror: "",
        BidTypeEror: "",
        BidHourlyError: ""
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    EditBidToggler() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    checkIncludes = (question_answers) => {
        let includes = false;
        question_answers.forEach(element => {
            if (element.question_type === 7 && element.answer != "-") {
                includes = true
            }
        })
        return includes;
    }

    PROJECTS_DETAILS = (question_answers) => {
        return question_answers && (
            <>
                {question_answers.map((data, i) => {
                    if (data.question_type === 7) {
                        if (data.answer !== "-") {
                            const measure = JSON.parse(data.answer).measurements;
                            return measure.map((data, t) => data != null && (
                                <QuestionAnswers
                                    key={t}
                                    type="dimension"
                                    DimensionTitle={data.title}
                                    lengthDimension={data.measuring_length}
                                    widthDimension={data.measuring_breadth}
                                    heightDimension={data.measuring_height}
                                    unit={data.unit}
                                />
                            ));
                        }
                    } else if (data.question_type === 4) {
                        return (
                            <QuestionAnswers
                                key={i}
                                ProjectQuestionIndex={i + 1}
                                ProjectQuestion={data.question}
                                ProjectAnswer={data.answer === true ? "Yes" : "No"}
                                type=""
                            />
                        );
                    } else if (data.question_type === 6) {
                        return (
                            <QuestionAnswers
                                key={i}
                                ProjectQuestionIndex={i + 1}
                                ProjectQuestion={data.question}
                                ProjectAnswer={data.answer.join(", ")}
                                type=""
                            />
                        );
                    } else {
                        return (
                            <QuestionAnswers
                                key={i}
                                ProjectQuestionIndex={i + 1}
                                ProjectQuestion={data.question}
                                ProjectAnswer={data.answer}
                                type=""
                            />
                        );
                    }
                })}
            </>
        )
    };
    render() {
        
        const { from, projectDataNotFound, projectData, projectDataLoading, projectDataError, commissionDetails, projectVideos } = this.props;
        if (!projectData || !projectData.project) {
            return <p>Loading...</p>; // Or any placeholder UI
        }
        const {
            full_address,
            consumer_avatar_url,
            consumer_name,
            created_at,
            description,
            budget,
            files,
            bid_amount,
            project_type,
            title,
            no_of_bids,
            question_answers,
            pdf,
            roof_scope,
            bid_type,
            hours,
            bid_detail,
            id,
            project_id,
            status,
            consumer_mobile,
            pending_amount,
            down_payment,
            lead_fee,
            project_post_type,
            provider_id,
            project_status,
            message_count,
            unread_msg,
            can_provider_contact_customer,
            discounted_price,
            refund_status,
            provider_user_id,
            public_project_id,
            project_thumbnail,
            customer_avathar,
            provider_project_id,
            amount_payable_to_easybids_after_discount,
            is_commission_paid,
            bid_id,
            consumer_email,
            project_all_images_url,
            project_all_videos_url
        } = projectData?.project;

        console.log("projectDataprojectData", refund_status);

        const capitalize = (str) => {
            const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
            return str2
        }
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Project Details</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="product-details pb-0">
                    <section className="ebw-section__pages">
                        <Container className="ebw-prodet__container">
                            {projectDataNotFound && projectDataNotFound ?
                                <ProjectNotFoundPage /> :
                                <>
                                    {projectDataLoading ? <ProjectDetailsLoader loading={projectDataLoading} />:
                                        <>
                                            <div className="ebw-prodet mb-3">
                                                <div className="ebw-prodet__inwrap d-md-flex justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ebw-prodet__back-link d-flex align-items-center">
                                                            <span className="ebw-prodet__angle ebw-icon ebw-icon--lg icon-arrow-left" aria-hidden="true"></span>
                                                            {from === "dashboard" ?
                                                                <Link to="/dashboard"><div className="ebw-prodet__text">Dashboard</div></Link> :
                                                                from === "leads" ?
                                                                    <Link to="/leads"><div className="ebw-prodet__text">Leads Projects</div></Link> :
                                                                from === "my_projects" ?
                                                                    <Link to="/my-projects"><div className="ebw-prodet__text">My Projects</div></Link> :
                                                                    <Link to="/projects/all/1"><div className="ebw-prodet__text">Available Projects</div></Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="test_scroll">
                                                <Row className="ebw-prodet__row py-2 py-md-12">
                                                    <Col lg="8">
                                                        <div className="d-lg-flex ebw-card ebw-card__others ebw-card__others--shadow">
                                                            <div className="ebw-prodet__content  ebw-prodet__customer-profile">
                                                                <div className=" d-flex align-items-center ebw-prodet__profile">
                                                                    <div className="ebw-prodet__details">
                                                                        <div className="ebw-prodet__title ebw-title__primary d-none d-md-block">{capitalize(title)}</div>
                                                                        <div className="d-flex align-items-center pt-1 pb-2">
                                                                            <span className="ebw-icon ebw-icon--lg icon-location"></span>
                                                                            <div>{full_address}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{ marginBottom: 16 }}>
                                                                        <p strong>ID: {public_project_id}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <div className="ebw-prodet__title ebw-prodet__title-project">Project Description</div>
                                                                    <div className="ebw-prodet__description pt-2">
                                                                        {description}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="ebw-prodet__slider">
                                                                <div style={{ position: 'absolute', right: 17, top: 20, zIndex: 9999}}>
                                                                    <span style={{ background: '#000000aa', color: 'white', padding: '4px 8px', borderRadius: '4px' }}>
                                                                        {project_all_images_url?.length || 0} Photo • {project_all_videos_url?.length || 0} Video
                                                                    </span>
                                                                </div>
                                                                
                                                                <Swiper
                                                                    spaceBetween={30}
                                                                    centeredSlides={true}
                                                                    // autoplay={{
                                                                    //     delay: 2500,
                                                                    //     disableOnInteraction: false,
                                                                    // }}
                                                                    pagination={{
                                                                        type: "fraction",
                                                                        clickable: true,
                                                                    }}
                                                                    navigation={{
                                                                        prevEl: '.prev3',
                                                                        nextEl: '.next3',
                                                                    }}
                                                                    className="mySwiper"
                                                                >
                                                                    {project_all_images_url && project_all_images_url.map((image, index) => (
                                                                        <SwiperSlide key={index}>
                                                                            <img 
                                                                                src={image || "/images/projects/project-details.png"} 
                                                                                alt={`Project Image ${index + 1}`}
                                                                                style={{width:'100%', height:360, objectFit:'cover'}}
                                                                            />
                                                                        </SwiperSlide>
                                                                    ))}
                                                                    
                                                                    {project_all_videos_url && project_all_videos_url.map((video, index) => (
                                                                        <SwiperSlide key={`video-${index}`}>
                                                                            <video 
                                                                                controls
                                                                                style={{width:'100%', height:360, objectFit:'cover'}}
                                                                            >
                                                                                <source src={video} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </SwiperSlide>
                                                                    ))}
                                                                </Swiper>
                                                                <div className="ebw-slider__navigation">
                                                                    <div className="ebw-slider__navigation-arrow prev3">
                                                                        <i className="ebw-icon ebw-icon--xl icon-arrow-left ebw-slider__navigation-arrow--icon"></i>
                                                                    </div>
                                                                    <div className="ebw-slider__navigation-arrow next3 ebw-slider__navigation-arrow--active">
                                                                        <i className="ebw-icon ebw-icon--xl icon-arrow-right ebw-slider__navigation-arrow--icon"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {((can_provider_contact_customer && project_status == 2) || (can_provider_contact_customer && project_status == 4)) && (
                                                            <div className="d-lg-flex ebw-card ebw-card__others ebw-card__others--shadow mt-3">
                                                                {can_provider_contact_customer && (project_status == 2) &&
                                                                    <AvailableAndInbiddingBideDetails
                                                                        bid_amount={bid_amount}
                                                                        bid_type={bid_type}
                                                                        hours={hours}
                                                                        bid_detail={bid_detail}
                                                                        commissionDetails={commissionDetails}
                                                                        status={status}
                                                                        id={id}
                                                                        created_at={created_at}
                                                                        project_type={project_type}
                                                                        title={title}
                                                                        provider_id={provider_id}
                                                                        project_id={project_id}
                                                                        message_count={message_count}
                                                                        unread_msg={unread_msg}
                                                                        bid_id={bid_id}
                                                                    />
                                                                }
                                                                {can_provider_contact_customer && (project_status == 4) &&(
                                                                    <CompletedAndHiredBidDetails
                                                                        bid_amount={bid_amount}
                                                                        bid_type={bid_type}
                                                                        hours={hours}
                                                                        bid_detail={bid_detail}
                                                                        commissionDetails={commissionDetails}
                                                                        status={status}
                                                                        title={title}
                                                                        created_at={created_at}
                                                                        pending_amount={pending_amount}
                                                                        down_payment={down_payment}
                                                                        id={id}
                                                                        project_id={project_id}
                                                                        project_status={project_status}
                                                                        project_post_type={project_post_type}
                                                                        budget={budget}
                                                                        projectData={projectData?.project}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </Col>

                                                    <Col sm="12" lg="4">
                                                        <ContactedBidDetails
                                                            bid_amount={bid_amount}
                                                            project_id={id}
                                                            provider_user_id={provider_user_id}
                                                            provider_id={provider_id}
                                                            consumer_mobile={consumer_mobile}
                                                            customer_email={consumer_email}
                                                            can_provider_contact_customer={can_provider_contact_customer}
                                                            public_project_id={public_project_id}
                                                            project_thumbnail={project_thumbnail}
                                                            title={title}
                                                            project_type={project_type}
                                                            consumer_name={consumer_name}
                                                            full_address={full_address}
                                                            customer_avathar={customer_avathar}
                                                            project_status={project_status}
                                                            consumer_avatar_url={consumer_avatar_url}
                                                            provider_project_id={provider_project_id}
                                                            amount_payable_to_easybids_after_discount={amount_payable_to_easybids_after_discount}
                                                            is_commission_paid={is_commission_paid}
                                                            discounted_price={discounted_price}
                                                            refund_status={refund_status}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </Container>
                    </section>
                </Main>
            </div>
        );
    }
}

export default Projectdetail;

