import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import StatsLoader from "./statsSkeleton";

// import { getNotificationList } from "../../redux/actions/notificationList";
import { getNotificationCount } from "../../redux/actions/notificationCount";
import DasboardNotification from "./dashboardNotification";


const Stats = (props) => {
  const { loading, provider_stats, stats, notifications } = props

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotificationCount());
    // dispatch(getNotificationList());
  }, []);
  // notificationCount
  const notificationCount = useSelector(state => state.notificationCount.notification_count);
  const firstFiveNotifications = useSelector(state => state.firstFiveNotifications.data);
  const firstFiveNotificationsLoading = useSelector(state => state.firstFiveNotifications.loading);
  const firstFiveNotificationsError = useSelector(state => state.firstFiveNotifications.error);
  const firstTwoNotifications = firstFiveNotifications && firstFiveNotifications.length != 0 && firstFiveNotifications.slice(0, 2);

  return (
    <section className="ebw-section ebw-section--achievements">
      <div className="ebw-achievements__inwrap container px-0 px-md-3">
        <div className="ebw-achivements__wrap d-flex flex-wrap align-items-center">
          <div className={`ebw-achievements__items d-flex flex-wrap align-items-start mb-3 mb-md-0 ${firstTwoNotifications.length ? "ebw-achievements__items-withMsg" : ""}`}>
            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  />
                </div>
                :
                (

                  <>
                    <div className="ebw-achievements__item-icon  mb-2">
                      <img className="img-fluid" src="./images/dashboard/saved.svg" alt="Saved" />
                    </div>
                    <div className="d-md-flex flex-wrap align-items-end mb-2 text-center text-md-left">
                      <div className="ebw-achievements__item-count">$<span>{provider_stats && provider_stats.saved}</span></div>
                      <div className="ebw-achievements__item-title ml-md-2">Saved</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">{provider_stats && provider_stats.saved == "0" ? "Bid on projects, get hired and start saving while earning." : "You have made amazing savings using Pro membership"}</div>
                  </>

                )
              }
            </div>

            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  // width={150}
                  // height={100}
                  />
                </div> :
                (

                  <>
                    <div className="ebw-achievements__item-icon  mb-2">
                      <img className="img-fluid" src="./images/dashboard/bidded.svg" alt="Bidded" />
                    </div>
                    <div className="d-md-flex flex-wrap align-items-end mb-2 text-center text-md-left">
                      <div className="ebw-achievements__item-count"><span>{provider_stats && provider_stats.project_interactions}</span></div>
                      <div className="ebw-achievements__item-title ml-md-2">Interactions</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">{provider_stats && provider_stats.project_interactions == 0 ? "There are many projects in need of your expertise. Start interacting!" : "Keep interacting on projects of your choice"}</div>
                  </>

                )
              }
            </div>

            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  />
                </div> :
                (

                  <>
                    <div className="ebw-achievements__item-icon  mb-2">
                      <img className="img-fluid" src="./images/dashboard/completed.svg" alt="Completed" />
                    </div>
                    <div className="d-md-flex flex-wrap align-items-end mb-2 text-center text-md-left">
                      <div className="ebw-achievements__item-count"><span>{provider_stats && provider_stats?.project_completed}</span></div>
                      <div className="ebw-achievements__item-title ml-md-2">Completed</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">{provider_stats && provider_stats?.project_completed == 0 ? " Bid on projects, complete them & start growing your business" : `You have completed ${provider_stats?.project_completed??'0'} projects`}</div>
                  </>

                )
              }
            </div>
          </div>
          {firstTwoNotifications.length ?
            <div className="ebw-achievements__msg-box px-3 px-md-0">
              <div className="ebw-card ebw-card__others py-3">
                <div className="ebw-achievements__notification-list">
                  {<DasboardNotification firstTwoNotifications={firstTwoNotifications} notificationLoading={firstFiveNotificationsLoading} />}
                  <div className="text-center pt-3">
                    <Link className="ebw-button__plain--blue" to="/notifications/">Show all notifications</Link>
                  </div>
                </div>
              </div>
            </div> :
            ""
          }
        </div>
      </div>
    </section>
  );
}

export default Stats;