import React from 'react';
import { Link } from "react-router-dom";
import { Col } from 'reactstrap';

import { MyWorksDropdown } from '../dropdown/dropdown';
import { InputTextNoBorder } from "../inputs/text-input";


const WorksFolder = (props) => {
    const { created_at, id, name, provider_id, updated_at } = props;
    return (
        <Col xs="6" sm="4" lg="3" xl="2" className="mb-5">
            <div className="ebw-uploads__folder">

                <MyWorksDropdown provider_id={provider_id} id={id} name={name} />
                <div className="ebw-uploads__folder text-center">
                    <Link className="ebw-uploads__folder-link" to={`/my-works/${id}`}>
                        <div className="ebw-uploads__folder-img"><img src="/images/myworks/works.jpg" alt="Folder" className="img-fluid mx-auto d-block" /></div>
                    </Link>
                    <div className="ebw-uploads__folder-name">
                        <InputTextNoBorder
                            label={name}
                            id={id}
                            name="work_project"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default WorksFolder;