import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../layout/footer';
import Header from '../../layout/header';
import BuyProjects from "./buy-projects";
import { buyProjectData } from "../../redux/actions/buyProjects";
import { getProfileEditData } from "../../redux/actions/profileEditData"

const BuyProjectsPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(buyProjectData());
        dispatch(getProfileEditData());
    }, []);

    const buyPtojectsData = useSelector(state => state.buyPtojects.buyPtojectsData);
    const buyPtojectsLoading = useSelector(state => state.buyPtojects.loading);
    const buyPtojectsError = useSelector(state => state.buyPtojects.error);

    console.log("buyPtojectsDatabuyPtojectsData", buyPtojectsData);

    return (
        <BuyProjects
        buyPtojectsData={buyPtojectsData}
        buyPtojectsLoading={buyPtojectsLoading}
        buyPtojectsError={buyPtojectsError} />
    );
}

export default BuyProjectsPage;