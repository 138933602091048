import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import CardSecondary from '../card/card-secondary';
import ProjectsLoader from "../../components/projects/projectsSkeleton";
const SuggestedProjects = (props) => {
    const projectAvailableData = props.data;
    console.log(projectAvailableData, 'projectAvailableData');
    
    return (
        <section className="ebw-section ebw-section--white ebw-section--projects-available overflow-hidden">
            <div className=" ebw-projects__available container">
                {(projectAvailableData?.length != 0 || props?.loading || props?.show) && (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="ebw-title__primary">{props.title}</div>
                    </div>
                )}
                {props?.loading && <ProjectsLoader count={3}/>}
                {(projectAvailableData?.length === 0 && !props?.loading && props?.show) && (
                    <div className="ebw-section__empty position-relative">
                        <div className="ebw-section__empty-imgs w-100">
                            <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                        </div>
                        <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                            <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white "> A variety of projects on plumbing, carpentry, landscaping & more to bid on</div>
                            <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                        </div>
                    </div>
                )}
                {(projectAvailableData?.length > 0 && !props?.loading) && (
                    <div className="ebw-available__cards">
                        <Swiper
                            spaceBetween={15}
                            navigation={{
                                prevEl: `.prev-${props.btnId}`, // Unique class for "prev"
                                nextEl: `.next-${props.btnId}`, // Unique class for "next"
                            }}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 2,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                            }}
                            className="ebw-swiper__container"
                        >
                            {projectAvailableData && projectAvailableData.length !== 0 && projectAvailableData.map((projectAvailable) => (
                                <SwiperSlide key={projectAvailable.id}>
                                    <CardSecondary
                                        projectName={projectAvailable.name}
                                        projectImage={projectAvailable.project_image_url}
                                        projectDate={projectAvailable.created_at}
                                        project_status={projectAvailable.status}
                                        projectCategory={projectAvailable.project_type}
                                        projectLocation={projectAvailable.city}
                                        projectId={projectAvailable.id}
                                        projectLinks={`/project-detail/${projectAvailable.id}/dashboard`}
                                        project_post_type={projectAvailable.project_post_type}
                                        lead_purchased={projectAvailable.lead_purchased}
                                        bookmark={projectAvailable.bookmarked_by_current_provider}
                                        project_id={projectAvailable.id}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="ebw-slider__navigation">
                            <div className={`ebw-slider__navigation-arrow prev-${props.btnId}`}>
                                <i className="ebw-icon ebw-icon--xl icon-arrow-left ebw-slider__navigation-arrow--icon"></i>
                            </div>
                            <div className={`ebw-slider__navigation-arrow next-${props.btnId} ebw-slider__navigation-arrow--active`}>
                                <i className="ebw-icon ebw-icon--xl icon-arrow-right ebw-slider__navigation-arrow--icon"></i>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </section>
    );
}

export default SuggestedProjects;