import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import { toastr } from 'react-redux-toastr'
import axios from 'axios';


async function getApi(data) {
    const apiUrl = API.baseURL + `/providers/web_projects_available`;
    // const requestOptions = {
    //     method: 'GET',
    //     headers: API.options.headers,
    // };
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* availableProjectsData(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'GET_PROJECTS_AVAILABLE_DATA_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_PROJECTS_AVAILABLE_DATA_FAILED', message: e.message });
    }
}

function* availableProjectsSaga() {
    yield takeEvery('GET_PROJECTS_AVAILABLE_DATA_REQUESTED', availableProjectsData);
}

export default availableProjectsSaga;