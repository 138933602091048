import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import axios from 'axios';
async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/activities/in_bidding`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* allbiddingData(action) {
    try {
        const response = yield call(getApi, action.data);
        yield put({ type: 'GET_ALL_IN_BIDDING_PROJECTS_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_ALL_PROJECTS_DATA_FAILED', message: e.message });
    }
}

function* inBiddingProjectsSaga() {
    yield takeEvery('GET_ALL_IN_BIDDING_PROJECTS_REQUESTED', allbiddingData);
}

export default inBiddingProjectsSaga;