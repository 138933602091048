import React, { Component } from 'react';
import { isLogin } from "../utils/index";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        isLogin() ?
            <footer className="ebw-footer">
                <div className="ebw-section ebw-footer__inner pb-5 pt-5">
                    <Container className="container-fluid">
                        <Row>
                            <Col xs="6" md="3" lg="2">
                                <div className="ebw-footer__wrap">
                                    <div className="ebw-footer__wrap-category">Help</div>
                                    <ul className="ebw-footer__list">
                                        <li className="ebw-footer__item">
                                            <Link to="/faqs" className="ebw-footer__link">FAQs</Link>
                                        </li>
                                        <li className="ebw-footer__item">
                                            <Link to="/support" className="ebw-footer__link">Support</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs="6" md="3" lg="2">
                                <div className="ebw-footer__wrap">
                                    <div className="ebw-footer__wrap-category">Company</div>
                                    <ul className="ebw-footer__list">
                                        <li className="ebw-footer__item">
                                            <a target="_blank" href="https://easybids.com/" className="ebw-footer__link">Easybids</a>
                                        </li>
                                        <li className="ebw-footer__item">
                                            <a target="_blank" href="https://easybids.com/service-provider/#provider-work" className="ebw-footer__link">How it works</a>
                                        </li>
                                        <li className="ebw-footer__item">
                                            <a target="_blank" href="https://blog.easybids.com/" className="ebw-footer__link">Blog</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col md="3" lg="2">
                                <div className="ebw-footer__wrap">
                                    <div className="ebw-footer__wrap-category">Services</div>
                                    <ul className="ebw-footer__list">
                                        <li className="ebw-footer__item">
                                            <a target="_blank" href="https://easybids.com/#services-home" className="ebw-footer__link">Line of services</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col md={{ size: 6 }} lg={{ size: 3,offset:3 }}>
                                <div className=""><img className="ebw-footer__brand img-fluid d-block mb-4 mx-auto mx-xl-0" src="/images/logo.svg" /></div>
                                <div className="ebw-footer__wrap--store text-center text-lg-left">
                                    <div className="ebw-footer__wrap-category ebw-footer__wrap-category--available">Available on Mobile</div>
                                    <div className="d-flex align-items-center">
                                        <a className="ebw-footer__rgt-item" target="_blank" href="https://play.google.com/store/apps/details?id=easybids.provider.app">
                                            <img src="/images/icons/google-play.png" alt="Google Play" className="img-fluid d-block" />
                                        </a>

                                        <a className="ebw-footer__rgt-item ml-2" target="_blank" href="https://apps.apple.com/us/app/easybids-pro-get-contracts/id1511563795">
                                            <img src="/images/icons/apple-store.png" alt="App Store" className="img-fluid d-block" />
                                        </a>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="12">
                                <div className="ebw-footer__address mt-5 ">
                                    <div className="ebw-footer__address-info d-flex  flex-wrap justify-content-center align-items-center">
                                        <div className="ebw-footer__address-desc px-3">USA, San Diego, CA</div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <a href="mailto:info@easybids.com" className="ebw-footer__address-link px-3">info@easybids.com</a>
                                            <a href="tel:(888) 598-1187" className="ebw-footer__address-link px-3">(888) 598-1187</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="ebw-footer__btm">
                    <div className="ebw-footer__btm-inner">
                        <Container>
                            <Row>
                                <Col sm="12">
                                    <div className="ebw-footer__copyright d-flex flex-column flex-md-row justify-content-center align-items-center">
                                        <div className="ebw-footer__copyright-lft d-flex flex-column flex-md-row justify-content-center align-items-center">
                                            <div className="ebw-footer__copyright-desc text-sm">
                                                © 2008 - 2025. All rights reserved.
                                            </div>
                                        </div>

                                        <div className="ebw-footer__copyright-rgt ml-md-auto">
                                            <Link to="/terms-conditions" className="ebw-footer__copyright-link text-sm">
                                                Terms & Conditions
                                            </Link>

                                            <Link to="/privacy-policy" className="ebw-footer__copyright-link text-sm pl-3">
                                                Privacy Policy
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </footer>
            : <></>
    )
}

export default Footer