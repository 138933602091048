import React, { useState } from 'react';
import { Form, Row, Col } from "reactstrap";
import axios from 'axios';
import { InputText, InputTextOtp } from "../inputs/text-input";
import OtpInputNumber from "../inputs/otp-input";
import Buttons from "../buttons/button-primary";
import API from '../../API';


//- Forgot Password OTP View
const ForgotPasswordOTP = (props) => {
    const validate = (inputs) => {
        let errors = {
            valid: true,
        };
        if (inputs.otp.length < 6) {
            errors.otpError = 'Enter 6 digit OTP';
            errors.valid = false;
        }
        return errors;
    };
    const [state, setState] = useState({
        otp: "",
        otpError: "",
    });
    const handleOtpChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            otp: event,
            otpError: "",
        }));
    };
    const handleSubmitOTP = (event) => {
        event.preventDefault();
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = JSON.stringify({
            otp: state.otp,
            email: props.email
        });
        errors.valid && axios.post(API.baseURL + `/validate_password_otp`, data, API.options)
            .then(response => {
                const result = response.data;
                props.completedForgotPassword(2, props.email)
            })
            .catch(error => {
                setState((prevProps) => ({
                    ...prevProps,
                    otpError: error && error.response.data.data.errors
                }));
                // this.setState({
                //     overAllErrors: error.response.data.data.errors,
                //     loadingShow: false
                // });
            });

    }
    return (
        <Form onSubmit={(e) => handleSubmitOTP(e)} className="ebw-entry__tab-form" >
            <Row form>
                <Col sm="12">
                    <div className="ebw-entry__forgot mb-4 text-center">
                        <h5 className="ebw-entry__forgot-title mb-0">Forgot your Password?</h5>
                        <p className="ebw-entry__forgot-tag">Recover it now</p>
                    </div>
                </Col>

                <Col sm="12">
                    <InputText
                        value={props.email}
                        label="Email ID"
                        name="verify_number"
                        id="verify_number"
                    />
                </Col>

                <Col sm="12">
                    <OtpInputNumber
                        value={state.otp}
                        changeEvent={(e) => handleOtpChange(e)}
                        otpError={state.otpError}
                    />
                </Col>

                <Col md="5" lg="6" xl="4" className="mt-5 mx-auto">
                    <Buttons type="submit" text="Verify OTP" />
                </Col>
            </Row>
        </Form>
    );
}
export default ForgotPasswordOTP;