import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class DatePickerRange extends React.Component {
    constructor(props, context) {
        super(props, context);

        const today = moment();

        this.state = {
            isOpen: false,
            value: moment.range(today.clone().subtract(7, "days"), today.clone())
        };
    }
    componentDidMount() {
        // if (this.props.wholeData.length != 0) {
        //     this.setState({ value: moment.range(this.props.wholeData[0].time_stamp_created_at, this.props.wholeData[this.props.wholeData.length - 1].time_stamp_created_at) })
        // }
    }

    onSelect = (value, states) => {
        this.props.getStartEndDates(value, states);
        this.setState({ value, states });
    };

    onToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    renderSelectionValue = () => {
        return (
            <div className="ebw-button__dropdown--date-selected d-flex align-items-center justify-content-center">
                <div className="ebw-button__dropdown--date-title mr-2">Selected Date:</div>

                <div className="ebw-date__selected ebw-date__from">{this.state.value.start.format("YYYY-MM-DD")}</div>
                <strong className="px-1"> {" - "}</strong>
                <div className="ebw-date__selected ebw-date__to">{this.state.value.end.format("YYYY-MM-DD")}</div>

            </div>
        );
    };

    render() {
        return (
            <div>

                <div className="position-relative">
                    <input
                        className="ebw-button__dropdown--date-input"
                        type="button"
                        value="Date"
                        onClick={this.onToggle}
                    />
                </div>


                {!this.state.isOpen && (
                    <div className="ebw-button__dropdown--date-picker">
                        {this.renderSelectionValue()}

                        <DateRangePicker
                            value={this.state.value}
                            onSelect={this.onSelect}
                            singleDateRange={true}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default DatePickerRange;
