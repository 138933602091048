import * as type from '../actions/types';

const initialState = {
  completedRegsteps: true,
  registration: [],
  loading: false,
  error: "",
}

export default function registerOtp(state = initialState, action) {
  switch (action.type) {
    case type.POST_REGISTER_OTP_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.POST_REGISTER_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        registration: action.registerPersonal,
        completedRegsteps: true
      }
    case type.POST_REGISTER_OTP_FAILED:
      return {
        ...state,
        completedRegsteps: 0,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}