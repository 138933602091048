import * as type from '../actions/types';

const initialState = {
    selectedTypes: [],
    selectedSubTypes: [],
    loading: false,
    error: null,
}

export default function selectedTypes(state = initialState, action) {
    switch (action.type) {
        case type.STORE_SELECTED_TYPES_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.STORE_SELECTED_TYPES_SUCCESS: {

            let selectedSubTypes = state.selectedSubTypes ?? [];
            const index = selectedSubTypes.findIndex((sub_service_id) => sub_service_id === action.data);

            if (index === -1) {
                selectedSubTypes.push(action.data);
            } else
                selectedSubTypes.splice(index, 1);

            return {
                ...state,
                loading: false,
                selectedTypes: [],
                selectedSubTypes: selectedSubTypes,
            }
        }
        case type.STORE_SELECTED_TYPES_ALL_SUCCESS: {

            return {
                ...state,
                loading: false,
                selectedTypes: [],
                selectedSubTypes: action.data,
            }
        }
        case type.STORE_SELECTED_TYPES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}