import * as type from '../actions/types';

const initialState = {
    data: {},
    loading: false,
    error: null,
}

export default function loginOtpResend(state = initialState, action) {
    switch (action.type) {
        case type.POST_OTP_LOGIN_RESEND_OTP_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.POST_OTP_LOGIN_RESEND_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
            }
        case type.POST_OTP_LOGIN_RESEND_OTP_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}