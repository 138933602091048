import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { setRegOneAuth } from '../../utils/index';
import API from '../../API';


const apiUrl = API.baseURL + `/validate_user`;
async function postApi(data) {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: API.options.headers,
    //     body: data
    // };
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.post(apiUrl, data, API.options);
        return await response;
    } catch (error) {
        throw error;
    }
}

function* registerOtp(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            setRegOneAuth(response.data.data, response.headers);
            yield put({ type: 'POST_REGISTER_OTP_SUCCESS', registerPersonal: response.data });
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 3 });
            yield put({ type: 'STORE_SET_AUTH_SUCCESS', auth: response.data.data });
            // toastr.success('Success', 'You are successfully completed the second step ');
        } else {
            // response.errors.length != 0 && toastr.error('Pegister personal  failed', response.errors[0]);
        }
    } catch (e) {
        // toastr.error('Failed', 'Failed to verify the OTP ');
        yield put({ type: 'POST_REGISTER_OTP_FAILED', error: e.response.data.data.errors[0] });
    }
}

function* registerOtpSaga() {
    yield takeEvery('POST_REGISTER_OTP_REQUESTED', registerOtp);
}

export default registerOtpSaga;